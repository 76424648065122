const JobStateProgress = {
  PROFILING: 'Profiling',
  HUNTING: 'Hunting',
  PRESENTING: 'Presenting',
  SCOUT_INTERVIEWING: 'Scout Interviewing',
  CLIENT_INTERVIEWING: 'Client Interviewing',
  CLOSING: 'Dealing',
  ONBOARDING: 'Onboarding',
};

export const JOB_STATE_PROGRESS_LIST = Object.values(JobStateProgress);

export const JobStateModifiers = {
  [JobStateProgress.PROFILING]: 'c-step-profiling',
  [JobStateProgress.HUNTING]: 'c-step-hunting',
  [JobStateProgress.PRESENTING]: 'c-step-presenting',
  [JobStateProgress.SCOUT_INTERVIEWING]: 'c-step-scout-interviewing',
  [JobStateProgress.CLIENT_INTERVIEWING]: 'c-step-client-interviewing',
  [JobStateProgress.CLOSING]: '.c-step-dealing',
  [JobStateProgress.ONBOARDING]: 'c-step-onboarding',
};

export default JobStateProgress;
