import { JOB_SET_DATE_MODAL_TYPES } from '../constants/job';
import { apiJobUpdate } from '../api/job.legacy';
import { format } from 'date-fns';

export const useUpdateJobMeetings = ({
  meetingType,
  onUpdate,
  onError,
  job,
}) => {
  const isPresentationMeeting =
    meetingType === JOB_SET_DATE_MODAL_TYPES.PRESENTATION_MEETING;

  const { presentationMeetingScheduledAt, profilingMeetingScheduledAt } = job;

  const { initialDateValue, initialTimeZoneValue } = isPresentationMeeting
    ? getInitialMeetingDetails(presentationMeetingScheduledAt)
    : getInitialMeetingDetails(profilingMeetingScheduledAt);

  const onSubmit = async ({ date, timeZone }) => {
    const uploadedMeeting = {
      localDateTime: format(new Date(date), 'yyyy-MM-dd HH:mm:ss'),
      utcDateTime: null,
      timeZone,
    };

    const updatePayload = isPresentationMeeting
      ? { presentationMeetingScheduledAt: uploadedMeeting }
      : { profilingMeetingScheduledAt: uploadedMeeting };

    try {
      const updatedJob = await apiJobUpdate({
        jobId: job.uuid,
        payload: updatePayload,
      });

      onUpdate({
        ...job,
        ...updatedJob,
        id: job.id,
        deliveryManager: job.deliveryManager,
        salesperson: job.salesperson,
      });
    } catch (error) {
      onError(error);
    }
  };

  return { initialDateValue, initialTimeZoneValue, onSubmit };
};

const getInitialMeetingDetails = scheduledMeeting => {
  const meetingDate = scheduledMeeting
    ? new Date(scheduledMeeting.localDateTime)
    : new Date();

  const meetingTimeZone = scheduledMeeting
    ? scheduledMeeting.timeZone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    initialDateValue: format(meetingDate, 'yyyy-MM-dd HH:mm'),
    initialTimeZoneValue: meetingTimeZone,
  };
};
