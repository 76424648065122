import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '@reverse-hr/pattern-library';
import { OfferDetailsFormContext } from './OfferDetailsForm';
import { OfferDetailsFormField } from './OfferDetailsFormField';

export const OfferDetailsFormOfferValues = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.offerValues',
  });

  const { formData, onFormChange } = useContext(OfferDetailsFormContext);

  const salaryTypeOptions = SALARY_FRACTION_TYPE_LIST.map(salaryTypeKey => ({
    value: salaryTypeKey,
    label: t('salaryTypeValue', { context: salaryTypeKey }),
  }));

  const onSalaryTypeChange = ({ value }) => {
    const isUpdatedSalaryTypeAnnual = value === SALARY_FRACTION_TYPES.ANNUAL;

    onFormChange({ isSalaryTypeAnnual: isUpdatedSalaryTypeAnnual });
  };

  return (
    <div className="c-offer-details-form__box">
      <h3 className="c-offer-details-form__box-title">{t('title')}</h3>

      <FormField
        inputProps={{
          isSmall: true,
          value: formData.isSalaryTypeAnnual
            ? SALARY_FRACTION_TYPES.ANNUAL
            : SALARY_FRACTION_TYPES.MONTHLY,
        }}
        label={t('salaryTypeLabel')}
        options={salaryTypeOptions}
        required
        type="select"
        onChange={onSalaryTypeChange}
      />

      {formData.isSalaryTypeAnnual ? (
        <OfferDetailsFormField
          fieldKey="salaryValue"
          label={t('salaryAnnualValue')}
          placeholder={t('salaryAnnualValue', { context: 'placeholder' })}
          required
        />
      ) : (
        <div className="c-offer-details-form__fields-row">
          <OfferDetailsFormField
            fieldKey="salaryValue"
            label={t('salaryMonthlyValue')}
            placeholder={t('salaryMonthlyValue', { context: 'placeholder' })}
            required
          />

          <OfferDetailsFormField
            fieldKey="numberOfSalaryFractions"
            label={t('numberOfSalaryFractions')}
            placeholder={t('numberOfSalaryFractions', {
              context: 'placeholder',
            })}
            required
          />
        </div>
      )}

      <div className="c-offer-details-form__fields-row">
        <OfferDetailsFormField
          fieldKey="successFeePercentageOfCompensationPackage"
          label={t('successFeePercentageOfCompensationPackage')}
          placeholder={t('successFeePercentageOfCompensationPackage', {
            context: 'placeholder',
          })}
          required
        />

        <OfferDetailsFormField
          fieldKey="alreadyInvoicedRetainerFee"
          label={t('alreadyInvoicedRetainerFee')}
          placeholder={t('alreadyInvoicedRetainerFee', {
            context: 'placeholder',
          })}
        />
      </div>

      <div className="c-offer-details-form__fields-row">
        <OfferDetailsFormField
          fieldKey="alreadyInvoicedConfidenceFee"
          label={t('alreadyInvoicedConfidenceFee')}
          placeholder={t('alreadyInvoicedConfidenceFee', {
            context: 'placeholder',
          })}
        />

        <OfferDetailsFormField
          fieldKey="discountPercentage"
          label={t('discountPercentage')}
          placeholder={t('discountPercentage', {
            context: 'placeholder',
          })}
        />
      </div>
    </div>
  );
};

const SALARY_FRACTION_TYPES = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly',
};

const SALARY_FRACTION_TYPE_LIST = Object.values(SALARY_FRACTION_TYPES);
