import { hotjar } from 'react-hotjar';

const Hotjar = (() => {
  const initialize = () => hotjar.initialize('2038807', '6');
  return {
    initialize,
  };
})();

export default Hotjar;
