import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppModal } from '../AppModal';
import { Action, Alert } from '@reverse-hr/pattern-library';
import { ActionWithLoader } from '../ActionWithLoader';
import { apiClientInterviewDelete } from '../../api/client-interviews';
import { apiApplicationScoutInterviewDelete } from '../../api/applications';

export const MeetingListDeleteModal = ({
  applicationId,
  interviewId,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'job.meetingList.deleteModal',
  });

  const [deleteError, setDeleteError] = useState(null);

  const isModalVisible = !!applicationId;

  const onDeleteConfirmClick = async () => {
    try {
      interviewId
        ? await apiClientInterviewDelete({ applicationId, interviewId })
        : await apiApplicationScoutInterviewDelete({ applicationId });

      onDelete();
    } catch (error) {
      setDeleteError(error);
    }
  };

  const onErrorAlertClose = () => setDeleteError(null);

  return (
    <>
      <AppModal
        className="c-meeting-list-delete-modal c-modal--small c-modal--auto-height"
        isVisible={isModalVisible}
      >
        <h2 className="c-meeting-list-delete-modal__title">{t('title')}</h2>
        <p className="c-meeting-list-delete-modal__content">{t('content')}</p>

        {!!deleteError && (
          <Alert
            icon="icn-warning-outline-24"
            type="warning"
            onClose={onErrorAlertClose}
          >
            {t('error')}
          </Alert>
        )}

        <footer className="c-meeting-list-delete-modal__footer">
          <ActionWithLoader
            modifier="c-meeting-list-delete-modal__action"
            label={t('confirm')}
            type="outline"
            onClick={onDeleteConfirmClick}
          />

          <Action
            modifier="c-meeting-list-delete-modal__action"
            label={t('cancel')}
            onClick={onClose}
          />
        </footer>
      </AppModal>
    </>
  );
};
