import { TaskDetails } from '../TaskDetails';
import { Action } from '@reverse-hr/pattern-library';
import React, { useContext } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { TASK_MODAL_ALTERNATIVE_VIEWS } from '../../../constants/task';
import { useTranslation } from 'react-i18next';
import { JOB_SET_DATE_MODAL_TYPES } from '../../../constants/job';

export const TaskJobScheduleProfilingMeeting = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'task.detailsView.jobScheduleProfilingMeeting',
  });

  const { jobLegacy, setAlternativeView, task } = useContext(TaskModalContext);

  const onSetProfilingMeetingClick = () =>
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.SET_DATE, {
      modalType: JOB_SET_DATE_MODAL_TYPES.PROFILING_MEETING,
    });

  return (
    <TaskDetails
      title={t('title', {
        customerFullName: jobLegacy.customer.referent_name,
      })}
      isPauseButtonHidden
      prompts={task.prompts}
      suggestion={t('tip')}
    >
      <Action onClick={onSetProfilingMeetingClick} label={t('cta')} />
    </TaskDetails>
  );
};
