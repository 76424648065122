import { useTranslation } from 'react-i18next';
import { apiJobShareFetch } from '../../api/share';
import { useEffect, useState } from 'react';
import { apiJobFetch } from '../../api/job';
import type { TShareEmployerViewRecipient } from './ShareEmployerView';
import type { TShare } from '../../models/Share';
import type { TJobLegacy } from '../../models/Job';

export const useRecipients = (jobUuid: TJobLegacy['uuid']) => {
  const { t } = useTranslation();

  const [mainRecipient, setMainRecipient] =
    useState<TShareEmployerViewRecipient | null>(null);

  const [guestRecipients, setGuestRecipient] = useState<
    TShareEmployerViewRecipient[]
  >([]);

  const fetchMainRecipient = async () => {
    const fetchedJob = await apiJobFetch(jobUuid);

    if (!fetchedJob) {
      return;
    }

    const customerReferent = fetchedJob.client.referent;

    setMainRecipient({
      id: customerReferent.id,
      email: customerReferent.email,
      fullName: t('common.formatting.fullName', customerReferent),
    });
  };

  const fetchGuestsRecipient = async () => {
    const response = await apiJobShareFetch(jobUuid);

    if (!response) {
      return;
    }

    updateGuestRecipients(response.shares);
  };

  const updateGuestRecipients = (shares: TShare[]) =>
    setGuestRecipient(
      shares.map(share => ({
        email: share.email,
        fullName: t('common.formatting.fullName', share),
        id: share.id,
      })),
    );

  useEffect(() => {
    fetchMainRecipient();
    fetchGuestsRecipient();
  }, [jobUuid]);

  return {
    fetchGuestsRecipient,
    guestRecipients,
    mainRecipient,
    updateGuestRecipients,
  };
};
