import React, { forwardRef, useState } from 'react';
import { TaskModalSkeleton } from './TaskModalSkeleton';
import { useHistory } from 'react-router-dom';
import { TaskModalHeader } from './TaskModalHeader';
import { useAlternativeView } from './use-alternative-view';
import { useOpenedTask } from './use-opened-task';
import { TaskDefault } from '../TaskModalViews/TaskDefault';
import { TASK_TYPES } from '../../constants/task';
import { TaskApplicationHandleClientInterviewOutcome } from '../TaskModalViews/TaskApplicationHandleClientInterviewOutcome';
import { TaskApplicationHandleClientInterviewFeedback } from '../TaskModalViews/TaskApplicationHandleClientInterviewFeedback';
import { TaskApplicationHandleAdditionalClientInterviews } from '../TaskModalViews/TaskApplicationHandleAdditionalClientInterviews';
import { useFetchJob } from './use-fetch-job';
import { useFetchApplication } from './use-fetch-application';
import { useFetchTask } from './use-fetch-task';
import { TaskApplicationHelpNegotiateOffer } from '../TaskModalViews/TaskApplicationHelpNegotiateOffer';
import { TaskApplicationHandleAdditionalClientInterviewOutcome } from '../TaskModalViews/TaskApplicationHandleAdditionalClientInterviewOutcome';
import { TaskApplicationPresentOfferToCandidate } from '../TaskModalViews/TaskApplicationPresentOfferToCandidate';
import { TaskApplicationAskForClientFeedbackOnCurriculum } from '../TaskModalViews/TaskApplicationAskForClientFeedbackOnCurriculum';
import { TaskJobScheduleProfilingMeeting } from '../TaskModalViews/TaskJobScheduleProfilingMeeting';
import { TaskJobSchedulePresentationMeeting } from '../TaskModalViews/TaskJobSchedulePresentationMeeting';
import { TaskJobPrepareForProfilingMeeting } from '../TaskModalViews/TaskJobPrepareForProfilingMeeting';
import { TaskApplicationPrepareForScoutInterview } from '../TaskModalViews/TaskApplicationPrepareForScoutInterview';
import { TaskApplicationHandleClientDislike } from '../TaskModalViews/TaskApplicationHandleClientDislike';
import { TaskJobPrepareForPresentationMeeting } from '../TaskModalViews/TaskJobPrepareForPresentationMeeting';
import { TaskModalContext } from './TaskModalContext';
import { TaskJobPrepareJobPost } from '../TaskModalViews/TaskJobPrepareJobPost';
import { TaskJobHuntCandidates } from '../TaskModalViews/TaskJobHuntCandidates';
import { TaskJobFindScout } from '../TaskModalViews/TaskJobFindScout';
import { TaskJobSendProfilingMeetingRecapEmail } from '../TaskModalViews/TaskJobSendProfilingMeetingRecapEmail';

export const TaskModal = forwardRef(
  ({ onClose, onTaskUpdate, taskId }, _ref) => {
    const history = useHistory();
    const [alternativeTitle, setAlternativeTitle] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const onModalClose = () => {
      history.push({ search: '' });
      onClose();
    };

    const { isTaskLoading, setTask, task } = useFetchTask({
      onModalClose,
      taskId,
    });

    const { application, isApplicationLoading } = useFetchApplication({ task });
    const { isJobLoading, job, jobLegacy, setJob } = useFetchJob({ task });

    useOpenedTask(setTask, task);

    const {
      AlternativeViewComponent,
      alternativeViewName,
      alternativeViewProps,
      setAlternativeView,
    } = useAlternativeView();

    const TaskDetailsViewComponent =
      !!task && TASK_TYPE_TO_TASK_VIEW_MAP[task.type]
        ? TASK_TYPE_TO_TASK_VIEW_MAP[task.type]
        : TaskDefault;

    if (!task || isTaskLoading || isApplicationLoading || isJobLoading) {
      return <TaskModalSkeleton />;
    }

    return (
      <TaskModalContext.Provider
        value={{
          alertMessage,
          alternativeTitle,
          alternativeViewName,
          application,
          job,
          jobLegacy,
          onModalClose,
          onTaskUpdate,
          setAlertMessage,
          setAlternativeTitle,
          setAlternativeView,
          setJob,
          setTask,
          task,
        }}
      >
        <div className="c-task-modal" data-task-modal-type={task.type}>
          <TaskModalHeader />

          {AlternativeViewComponent ? (
            <AlternativeViewComponent {...alternativeViewProps} />
          ) : (
            <TaskDetailsViewComponent />
          )}
        </div>
      </TaskModalContext.Provider>
    );
  },
);

const TASK_TYPE_TO_TASK_VIEW_MAP = {
  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_INTERVIEW_FEEDBACK]:
    TaskApplicationHandleClientInterviewFeedback,
  [TASK_TYPES.APPLICATION_HANDLE_ADDITIONAL_CLIENT_INTERVIEWS]:
    TaskApplicationHandleAdditionalClientInterviews,
  [TASK_TYPES.APPLICATION_HELP_NEGOTIATE_OFFER]:
    TaskApplicationHelpNegotiateOffer,
  [TASK_TYPES.APPLICATION_PRESENT_OFFER_TO_CANDIDATE]:
    TaskApplicationPresentOfferToCandidate,
  [TASK_TYPES.APPLICATION_PREPARE_FOR_SCOUT_INTERVIEW]:
    TaskApplicationPrepareForScoutInterview,
  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_DISLIKE]:
    TaskApplicationHandleClientDislike,
  [TASK_TYPES.APPLICATION_ASK_FOR_CLIENT_FEEDBACK_ON_CURRICULUM]:
    TaskApplicationAskForClientFeedbackOnCurriculum,
  [TASK_TYPES.APPLICATION_HANDLE_ADDITIONAL_CLIENT_INTERVIEW_OUTCOME]:
    TaskApplicationHandleAdditionalClientInterviewOutcome,
  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_INTERVIEW_OUTCOME]:
    TaskApplicationHandleClientInterviewOutcome,
  [TASK_TYPES.JOB_FIND_SCOUT]: TaskJobFindScout,
  [TASK_TYPES.JOB_HUNT_CANDIDATES]: TaskJobHuntCandidates,
  [TASK_TYPES.JOB_SCHEDULE_PRESENTATION_MEETING]:
    TaskJobSchedulePresentationMeeting,
  [TASK_TYPES.JOB_SCHEDULE_PROFILING_MEETING]: TaskJobScheduleProfilingMeeting,
  [TASK_TYPES.JOB_PREPARE_FOR_PROFILING_MEETING]:
    TaskJobPrepareForProfilingMeeting,
  [TASK_TYPES.JOB_PREPARE_FOR_PRESENTATION_MEETING]:
    TaskJobPrepareForPresentationMeeting,
  [TASK_TYPES.JOB_PREPARE_JOB_POST]: TaskJobPrepareJobPost,
  [TASK_TYPES.JOB_SEND_PROFILING_MEETING_RECAP_EMAIL]:
    TaskJobSendProfilingMeetingRecapEmail,
};
