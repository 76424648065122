import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SplashLoader } from '../components/SplashLoader';

const SplashPage = ({ visible }) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);
  useEffect(() => {
    if (visible) {
      setHidden(false);
    } else {
      setTimeout(() => setHidden(true), 500);
    }
  }, [visible]);
  return (
    <>
      {!hidden && (
        <div
          data-test="splash-page"
          className={`o-layout o-layout--splash ${visible ? 'visible' : ''}`}
        >
          <SplashLoader />
        </div>
      )}
    </>
  );
};

export default SplashPage;
