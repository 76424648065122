export const TASK_TYPES = {
  APPLICATION_SET_FIRST_DAY_ON_THE_JOB: 'application_set_first_day_on_the_job',
  APPLICATION_HANDLE_CLIENT_INTERVIEW_FEEDBACK:
    'application_handle_client_interview_feedback',
  APPLICATION_HANDLE_ADDITIONAL_CLIENT_INTERVIEWS:
    'application_handle_additional_client_interviews',
  APPLICATION_HELP_NEGOTIATE_OFFER: 'application_help_negotiate_offer',
  APPLICATION_PRESENT_OFFER_TO_CANDIDATE:
    'application_present_offer_to_candidate',
  APPLICATION_PREPARE_FOR_SCOUT_INTERVIEW:
    'application_prepare_for_scout_interview',
  APPLICATION_SCHEDULE_CLIENT_INTERVIEW:
    'application_schedule_client_interview',
  APPLICATION_ASK_FOR_CLIENT_FEEDBACK_ON_CURRICULUM:
    'application_ask_for_client_feedback_on_curriculum',
  APPLICATION_HANDLE_ADDITIONAL_CLIENT_INTERVIEW_OUTCOME:
    'application_handle_additional_client_interview_outcome',
  APPLICATION_PREPARE_FOR_CLIENT_INTERVIEW:
    'application_prepare_for_client_interview',
  APPLICATION_HANDLE_CLIENT_INTERVIEW_OUTCOME:
    'application_handle_client_interview_outcome',
  APPLICATION_SCHEDULE_CLIENT_INTERVIEW_AFTER_SCOUT_REPORT:
    'application_schedule_client_interview_after_scout_report',
  APPLICATION_PREPARE_FOR_ADDITIONAL_CLIENT_INTERVIEW:
    'application_prepare_for_additional_client_interview',
  APPLICATION_NOTIFY_CLIENT_OF_OFFER_REFUSAL:
    'application_notify_client_of_offer_refusal',
  APPLICATION_CHECK_IN_DAY_BEFORE_NEW_JOB:
    'application_check_in_day_before_new_job',
  APPLICATION_CHECK_IN_AFTER_FIFTEEN_DAYS_ON_NEW_JOB:
    'application_check_in_after_fifteen_days_on_new_job',
  APPLICATION_CHECK_IN_AFTER_FIFTY_DAYS_ON_NEW_JOB:
    'application_check_in_after_fifty_days_on_new_job',

  APPLICATION_HANDLE_CLIENT_DISLIKE: 'application_handle_client_dislike',

  APPLICATION_ASK_FOR_SCOUT_REPORT: 'application_ask_for_scout_report',
  APPLICATION_APPROVE_SCOUT_REPORT: 'application_approve_scout_report',
  JOB_SEND_PROFILING_MEETING_RECAP_EMAIL:
    'job_send_profiling_meeting_recap_email',
  JOB_SCHEDULE_PRESENTATION_MEETING: 'job_schedule_presentation_meeting',
  JOB_FIND_SCOUT: 'job_find_scout',
  JOB_CONTACT_CLIENT_AFTER_PROFILING_MEETING:
    'job_contact_client_after_profiling_meeting',
  JOB_PREPARE_FOR_PRESENTATION_MEETING: 'job_prepare_for_presentation_meeting',
  JOB_HUNT_CANDIDATES: 'job_hunt_candidates',
  JOB_SCHEDULE_PROFILING_MEETING: 'job_schedule_profiling_meeting',
  JOB_PREPARE_JOB_POST: 'job_prepare_job_post',
  JOB_PREPARE_FOR_PROFILING_MEETING: 'job_prepare_for_profiling_meeting',
};

export const TASK_TYPE_LIST = Object.values(TASK_TYPES);

export const TASK_PHASES = {
  PROFILING: 'profiling',
  HUNTING: 'hunting',
  PRESENTING: 'presenting',
  SCOUT_INTERVIEWING: 'scoutInterviewing',
  CLIENT_INTERVIEWING: 'clientInterviewing',
  DEALING: 'dealing',
  ONBOARDING: 'onboarding',
};

export const TASK_TYPE_TO_PHASE_MAP = {
  [TASK_TYPES.JOB_SCHEDULE_PROFILING_MEETING]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_FIND_SCOUT]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_PREPARE_FOR_PROFILING_MEETING]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_SEND_PROFILING_MEETING_RECAP_EMAIL]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_SCHEDULE_PRESENTATION_MEETING]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_PREPARE_JOB_POST]: TASK_PHASES.PROFILING,
  [TASK_TYPES.JOB_CONTACT_CLIENT_AFTER_PROFILING_MEETING]: TASK_PHASES.HUNTING,
  [TASK_TYPES.JOB_HUNT_CANDIDATES]: TASK_PHASES.HUNTING,
  [TASK_TYPES.JOB_PREPARE_FOR_PRESENTATION_MEETING]: TASK_PHASES.HUNTING,
  [TASK_TYPES.APPLICATION_ASK_FOR_CLIENT_FEEDBACK_ON_CURRICULUM]:
    TASK_PHASES.PRESENTING,
  [TASK_TYPES.APPLICATION_PREPARE_FOR_SCOUT_INTERVIEW]: TASK_PHASES.PRESENTING,
  [TASK_TYPES.APPLICATION_SCHEDULE_CLIENT_INTERVIEW]: TASK_PHASES.PRESENTING,
  [TASK_TYPES.APPLICATION_ASK_FOR_SCOUT_REPORT]: TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_APPROVE_SCOUT_REPORT]: TASK_PHASES.SCOUT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_SCHEDULE_CLIENT_INTERVIEW_AFTER_SCOUT_REPORT]:
    TASK_PHASES.CLIENT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_PREPARE_FOR_CLIENT_INTERVIEW]:
    TASK_PHASES.CLIENT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_INTERVIEW_OUTCOME]:
    TASK_PHASES.CLIENT_INTERVIEWING,
  [TASK_TYPES.APPLICATION_SET_FIRST_DAY_ON_THE_JOB]: TASK_PHASES.DEALING,
  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_INTERVIEW_FEEDBACK]:
    TASK_PHASES.DEALING,
  [TASK_TYPES.APPLICATION_NOTIFY_CLIENT_OF_OFFER_REFUSAL]: TASK_PHASES.DEALING,
  [TASK_TYPES.APPLICATION_HANDLE_ADDITIONAL_CLIENT_INTERVIEWS]:
    TASK_PHASES.DEALING,
  [TASK_TYPES.APPLICATION_HELP_NEGOTIATE_OFFER]: TASK_PHASES.DEALING,
  [TASK_TYPES.APPLICATION_PRESENT_OFFER_TO_CANDIDATE]: TASK_PHASES.DEALING,
  [TASK_TYPES.APPLICATION_CHECK_IN_DAY_BEFORE_NEW_JOB]: TASK_PHASES.ONBOARDING,
  [TASK_TYPES.APPLICATION_CHECK_IN_AFTER_FIFTEEN_DAYS_ON_NEW_JOB]:
    TASK_PHASES.ONBOARDING,
  [TASK_TYPES.APPLICATION_CHECK_IN_AFTER_FIFTY_DAYS_ON_NEW_JOB]:
    TASK_PHASES.ONBOARDING,
};

export { TASK_MODAL_ALTERNATIVE_VIEWS } from '../definitions/task';

export const TASK_PROMPT_TYPES = {
  SIMPLE_TEXT: 'simple_text',
  EXTERNAL_LINK: 'external_link',
  PHONE: 'phone',
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
  PROFILING_MEETING_SCHEDULED_AT: 'profiling_meeting_scheduled_at',
  PRESENTATION_MEETING_SCHEDULED_AT: 'presentation_meeting_scheduled_at',
  CLIENT_INTERVIEW: 'client_interview',
  CUSTOM_CONTENT: 'custom_content',
};

export const TASK_TYPE_TO_KEY_MAP = {
  [TASK_TYPES.APPLICATION_SET_FIRST_DAY_ON_THE_JOB]:
    'applicationSetFirstDayOnTheJob',
  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_INTERVIEW_FEEDBACK]:
    'applicationHandleClientInterviewFeedback',
  [TASK_TYPES.APPLICATION_HANDLE_ADDITIONAL_CLIENT_INTERVIEWS]:
    'applicationHandleAdditionalClientInterviews',
  [TASK_TYPES.APPLICATION_HELP_NEGOTIATE_OFFER]:
    'applicationHelpNegotiateOffer',
  [TASK_TYPES.APPLICATION_PRESENT_OFFER_TO_CANDIDATE]:
    'applicationPresentOfferToCandidate',
  [TASK_TYPES.APPLICATION_PREPARE_FOR_SCOUT_INTERVIEW]:
    'applicationPrepareForScoutInterview',
  [TASK_TYPES.APPLICATION_SCHEDULE_CLIENT_INTERVIEW]:
    'applicationScheduleClientInterview',
  [TASK_TYPES.APPLICATION_ASK_FOR_CLIENT_FEEDBACK_ON_CURRICULUM]:
    'applicationAskForClientFeedbackOnCurriculum',
  [TASK_TYPES.APPLICATION_HANDLE_ADDITIONAL_CLIENT_INTERVIEW_OUTCOME]:
    'applicationHandleAdditionalClientInterviewOutcome',
  [TASK_TYPES.APPLICATION_PREPARE_FOR_CLIENT_INTERVIEW]:
    'applicationPrepareForClientInterview',
  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_INTERVIEW_OUTCOME]:
    'applicationHandleClientInterviewOutcome',
  [TASK_TYPES.APPLICATION_SCHEDULE_CLIENT_INTERVIEW_AFTER_SCOUT_REPORT]:
    'applicationScheduleClientInterviewAfterScoutReport',
  [TASK_TYPES.APPLICATION_NOTIFY_CLIENT_OF_OFFER_REFUSAL]:
    'applicationNotifyClientOfOfferRefusal',
  [TASK_TYPES.APPLICATION_CHECK_IN_DAY_BEFORE_NEW_JOB]:
    'applicationCheckInDayBeforeNewJob',
  [TASK_TYPES.APPLICATION_CHECK_IN_AFTER_FIFTEEN_DAYS_ON_NEW_JOB]:
    'applicationCheckInAfterFifteenDaysOnNewJob',
  [TASK_TYPES.APPLICATION_CHECK_IN_AFTER_FIFTY_DAYS_ON_NEW_JOB]:
    'applicationCheckInAfterFiftyDaysOnNewJob',

  [TASK_TYPES.APPLICATION_HANDLE_CLIENT_DISLIKE]:
    'applicationHandleClientDislike',

  [TASK_TYPES.APPLICATION_ASK_FOR_SCOUT_REPORT]: 'applicationAskForScoutReport',
  [TASK_TYPES.APPLICATION_APPROVE_SCOUT_REPORT]:
    'applicationApproveScoutReport',
  [TASK_TYPES.APPLICATION_PREPARE_FOR_ADDITIONAL_CLIENT_INTERVIEW]:
    'applicationPrepareForAdditionalClientInterview',
  [TASK_TYPES.JOB_SEND_PROFILING_MEETING_RECAP_EMAIL]:
    'jobSendProfilingMeetingRecapEmail',
  [TASK_TYPES.JOB_SCHEDULE_PRESENTATION_MEETING]:
    'jobSchedulePresentationMeeting',
  [TASK_TYPES.JOB_FIND_SCOUT]: 'jobFindScout',
  [TASK_TYPES.JOB_CONTACT_CLIENT_AFTER_PROFILING_MEETING]:
    'jobContactClientAfterProfilingMeeting',
  [TASK_TYPES.JOB_PREPARE_FOR_PRESENTATION_MEETING]:
    'jobPrepareForPresentationMeeting',
  [TASK_TYPES.JOB_HUNT_CANDIDATES]: 'jobHuntCandidates',
  [TASK_TYPES.JOB_SCHEDULE_PROFILING_MEETING]: 'jobScheduleProfilingMeeting',
  [TASK_TYPES.JOB_PREPARE_JOB_POST]: 'jobPrepareJobPost',
  [TASK_TYPES.JOB_PREPARE_FOR_PROFILING_MEETING]:
    'jobPrepareForProfilingMeeting',
};
