import type { FC } from 'react';
import React from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';

interface TJraSlidesGenerationModalRegenerateProps {
  onCancelClick: () => void;
  onRegenerateClick: () => void;
  presentationMeetingSlidesGeneratedAt: string;
}

export const JraSlidesGenerationModalRegenerate: FC<
  TJraSlidesGenerationModalRegenerateProps
> = ({
  onCancelClick,
  onRegenerateClick,
  presentationMeetingSlidesGeneratedAt,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'modals.jraSlidesGeneration.regenerate',
  });

  return (
    <div className="c-jra-slides-generation-modal-regenerate">
      <h2 className="c-jra-slides-generation-modal-regenerate__title">
        {t('title')}
      </h2>

      <p className="c-jra-slides-generation-modal-regenerate__subtitle">
        <Trans
          i18nKey="modals.jraSlidesGeneration.regenerate.content"
          values={{
            date: new Date(presentationMeetingSlidesGeneratedAt),
          }}
        />
      </p>

      <footer className="c-jra-slides-generation-modal__footer">
        <Action label={t('ctaCancel')} onClick={onCancelClick} type="outline" />

        <Action
          label={t('ctaRegenerate')}
          onClick={onRegenerateClick}
          type="primary"
        />
      </footer>
    </div>
  );
};
