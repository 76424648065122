import React from 'react';
import classNames from 'classnames';

export const AppModal = ({ className, children, isVisible }) => {
  const wrapperClassNames = classNames('c-modal', className);

  return isVisible ? (
    <div className={wrapperClassNames}>
      <div className="c-modal__overlay" tabIndex="-1">
        <div className="c-modal__container" aria-modal role="dialog">
          {children}
        </div>
      </div>
    </div>
  ) : null;
};
