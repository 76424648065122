import type { ObjectValues } from '../utilities/types/ObjectValues';
import type { TASK_PROMPT_TYPES } from '../constants/task';
import type { ReactElement } from 'react';

export const TASK_MODAL_ALTERNATIVE_VIEWS = {
  BINGO: 'bingo',
  CLIENT_INTERVIEW: 'clientInterview',
  CLOSING: 'closing',
  GENERATE_JRA_SLIDES: 'generateJraSlides',
  MISSED_CANDIDATE: 'missedCandidate',
  SEND_EMAIL: 'sendEmail',
  SET_DATE: 'setDate',
  SHARE_EMPLOYER_VIEW: 'shareEmployerView',
} as const;

export type TTaskModalAlternativeView = ObjectValues<
  typeof TASK_MODAL_ALTERNATIVE_VIEWS
>;

export interface TPrompterItem {
  text: string | ReactElement;
  type: TPrompterItemType;
}

type TPrompterItemType = ObjectValues<typeof TASK_PROMPT_TYPES>;
