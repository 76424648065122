import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeZonePicker } from '../DateTimeZonePicker';

export const ClientInterviewFormDateTime = ({
  scheduledAtUtc,
  scheduledAtTimeZone,
  onChange,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'clientInterviews.form.fields',
  });

  const onChangeDatepicker = newDateAndTimeZoneValues => {
    onChange({
      scheduledAtUtc: newDateAndTimeZoneValues.date,
      scheduledAtTimeZone: newDateAndTimeZoneValues.timeZone,
    });
  };

  return (
    <div className="c-client-interview-form__date-time">
      <DateTimeZonePicker
        datePickerProps={{
          label: { id: 'client-interview-date', text: t('date') },
          defaultValue: scheduledAtUtc,
          isRequired: true,
        }}
        timeZoneProps={{
          label: { id: 'client-interview-time-zone', text: t('timeZone') },
          defaultValue: scheduledAtTimeZone,
          isRequired: true,
        }}
        onChange={onChangeDatepicker}
      />
    </div>
  );
};
