import { useEffect, useState } from 'react';
import type { FormFieldEvent } from '@reverse-hr/pattern-library';
import * as yup from 'yup';
import { USER_LOCALE_LIST } from '../../definitions/user';
import { useTranslation } from 'react-i18next';
import type { TFormData } from './ShareEmployerView';

export const useFormValidation = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<TFormData>(DEFAULT_FORM_DATA);
  const [formErrors, setFormErrors] = useState<TFormData>(DEFAULT_FORM_DATA);
  const [isFormValid, setIsFormValid] = useState(false);

  const onFieldChange = async ({ name, value }: FormFieldEvent) =>
    setFormData({
      ...formData,
      [name]: value,
    });

  const validationSchema = yup.object({
    firstName: yup.string().required(' '),
    lastName: yup.string().required(' '),
    email: yup
      .string()
      .email(t('modals.shareEmployerView.form.emailError'))
      .required(' '),
    language: yup.string().oneOf(USER_LOCALE_LIST).required(' '),
  });

  useEffect(() => {
    const validateForm = async () => {
      try {
        await validationSchema.validate(formData, {
          abortEarly: false,
        });

        setIsFormValid(true);
        setFormErrors(DEFAULT_FORM_DATA);
      } catch (error: unknown) {
        if (!yup.ValidationError.isError(error)) {
          return;
        }

        setIsFormValid(false);
        setFormErrors(
          Object.keys(formData).reduce(
            (updatedFormErrors, formDataKey) => {
              const matchingError = error.inner.find(
                innerError =>
                  innerError.path && innerError.path === formDataKey,
              );

              if (!matchingError || !matchingError.message.trim()) {
                return {
                  ...updatedFormErrors,
                  [formDataKey]: '',
                };
              }

              return {
                ...updatedFormErrors,
                [formDataKey]: matchingError.message,
              };
            },
            { ...formErrors },
          ),
        );
      }
    };

    validateForm();
  }, [formData]);

  const resetFormData = () => setFormData(DEFAULT_FORM_DATA);

  return { formData, formErrors, isFormValid, onFieldChange, resetFormData };
};

const DEFAULT_FORM_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  language: '',
};
