import { thunk } from 'easy-peasy';
import { apiTaskPauseUpdate, apiTaskUnpauseUpdate } from '../../api/task';

export const pauseTask = thunk(async (actions, payload) => {
  try {
    actions.setIsLoading(true);
    const res = await apiTaskPauseUpdate(payload);
    actions.setTask(res);
    actions.setIsLoading(false);
    return { success: true, result: res };
  } catch (e) {
    actions.setIsError(e?.response?.data);
    actions.setIsLoading(false);
  }
});

export const unpauseTask = thunk(async (actions, payload) => {
  try {
    actions.setIsLoading(true);
    const res = await apiTaskUnpauseUpdate(payload);
    actions.setTask(res);
    actions.setIsLoading(false);
    return { success: true, result: res };
  } catch (e) {
    console.log(e);
    actions.setIsError(e?.response?.data);
    actions.setIsLoading(false);
  }
});
