import { action, thunk } from 'easy-peasy';
import { pauseTask, unpauseTask } from './pauseUnpauseTask';

export default {
  // state
  task: {},
  isLoading: true,
  isError: false,
  emailLoading: false,
  emailError: false,

  // actions
  setTask: action((state, payload) => {
    state.task = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setIsError: action((state, payload) => {
    state.isError = payload;
  }),
  setEmailLoading: action((state, payload) => {
    state.emailLoading = payload;
  }),
  setEmailError: action((state, payload) => {
    state.emailError = payload;
  }),

  // thunks
  fetchTask: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const {
        taskService: { getTask },
      } = injections;
      const taskId = payload;
      return new Promise(async (resolve, reject) => {
        try {
          const fetchedTask = await getTask(taskId);
          actions.setTask(fetchedTask);
          resolve(fetchedTask);
          getStoreActions().notes.setNotes(fetchedTask.notes);
        } catch (error) {
          console.warn(error);
          actions.setIsError(true);
          reject();
        } finally {
          actions.setIsLoading(false);
        }
      });
    },
  ),
  updateTask: thunk(async (actions, payload, { injections, getState }) => {
    const {
      taskService: { updateSingleTask },
    } = injections;
    const {
      task: { id },
    } = getState();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await updateSingleTask(id, payload);
        resolve(response);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }),
  sendTaskEmail: thunk(async (actions, payload, { injections }) => {
    const {
      taskService: { sendEmail },
    } = injections;
    return new Promise(async (resolve, reject) => {
      actions.setEmailError(false);
      actions.setEmailLoading(true);
      const response = await sendEmail(payload);
      if (response.success) {
        resolve();
      } else {
        reject(response.error);
        actions.setEmailError(true);
      }
      actions.setEmailLoading(false);
    });
  }),
  // Pause Unpause feature thunks
  pauseTask,
  unpauseTask,
};
