import { utilityRequestWithAuth } from '../utilities/session';
import type { TJob } from '../models/Job';

export const apiJobFetch = async (jobId: string) =>
  await utilityRequestWithAuth<TJob>({
    endpoint: `/api/v1/jarvis/jobs/${jobId}`,
    method: 'GET',
  });

export const apiJobPresentationMeetingSlidesPost = async (jobId: string) =>
  await utilityRequestWithAuth<TJob>({
    endpoint: `/api/v1/jarvis/jobs/${jobId}/presentation-meeting-slides`,
    method: 'POST',
  });
