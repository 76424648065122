import { apiApplicationClientInterviewsFetch } from '../api/client-interviews';
import { useEffect, useMemo, useState } from 'react';
import {
  UTILITY_INTERVIEWS_SORT_ORDERS,
  utilityGetClientInterviewsSortedByDate,
} from '../utilities/meetings';

export const useApplicationClientInterviews = applicationId => {
  const [clientInterviews, setClientInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const futureInterview = useMemo(() => {
    const allFutureInterviews = clientInterviews.filter(
      clientInterview => new Date(clientInterview.scheduledAtUtc) > new Date(),
    );

    if (allFutureInterviews.length === 0) {
      return null;
    }

    const [nextClientInterview] = utilityGetClientInterviewsSortedByDate({
      clientInterviews: allFutureInterviews,
      sortOrder: UTILITY_INTERVIEWS_SORT_ORDERS.ASC,
    });

    return { date: new Date(nextClientInterview.scheduledAtUtc) };
  }, [clientInterviews]);

  const pastInterviews = useMemo(() => {
    const allPastInterviews = clientInterviews.filter(
      clientInterview => new Date(clientInterview.scheduledAtUtc) < new Date(),
    );

    if (allPastInterviews.length === 0) {
      return null;
    }

    const [mostRecentClientInterview] = utilityGetClientInterviewsSortedByDate({
      clientInterviews: allPastInterviews,
    });

    return {
      numberOfInterviews: allPastInterviews.length,
      date: new Date(mostRecentClientInterview.scheduledAtUtc),
      lastInterviewId: mostRecentClientInterview.id,
    };
  }, [clientInterviews]);

  useEffect(() => {
    const fetchClientInterviews = async () => {
      if (!applicationId) {
        return;
      }

      try {
        const { results: fetchedClientInterviews } =
          await apiApplicationClientInterviewsFetch({
            applicationId,
          });

        setClientInterviews(fetchedClientInterviews ?? []);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientInterviews();
  }, []);

  return { futureInterview, isLoading, pastInterviews };
};
