import i18next from 'i18next';

export const hotHeader = () => ({
  icon: 'icn-hot-24px',
  title: i18next.t('tasks.hot'),
});
export const toDoHeader = () => ({
  icon: 'icn-to-do-24px',
  title: i18next.t('tasks.todo'),
});
export const pausedHeader = () => ({
  icon: 'icn-pause-24px',
  title: i18next.t('tasks.paused'),
});
export const postponedHeader = () => ({
  icon: 'icn-clock-24px',
  title: i18next.t('tasks.postponed'),
});

export const tasksHeading = () => ({
  icon: 'icn-task-24px',
  title: i18next.t('tasks.title'),
});

export const tasksEmpty = () => ({
  title: i18next.t('tasks.empty.title'),
  text: i18next.t('tasks.empty.text'),
});
export const tasksError = () => ({
  title: i18next.t('tasks.error.title'),
  text: i18next.t('tasks.error.text'),
});

export const starredNotesHeader = () => ({
  icon: 'icn-favorite-on-24px',
  title: i18next.t('starred'),
});

export const notStarredNotesHeader = () => ({
  icon: 'icn-favorite-hole-24px',
  title: i18next.t('not-starred'),
});

export const jobError = () => ({
  title: i18next.t('job.error.title'),
  text: i18next.t('job.error.text'),
});
export const jobsError = () => ({
  title: i18next.t('jobs.error.title'),
  text: i18next.t('jobs.error.text'),
});
export const emptyActivities = () => ({
  title: i18next.t('activities.empty.title'),
  text: i18next.t('activities.empty.text'),
});

export const error = () => ({
  title: i18next.t('error.general'),
  text: i18next.t('error.subtitle'),
});

export const allJobsFilters = () => ({
  order: {
    label: i18next.t('all-jobs.filters.order.title'),
    selected: 'stale',
    placeholder: i18next.t('all-jobs.filters.order.placeholder'),
    options: [
      {
        key: 'stale',
        value: i18next.t('all-jobs.filters.order.stale'),
        label: i18next.t('all-jobs.filters.order.stale'),
        type: 'order',
      },
      {
        key: 'publish_date',
        value: i18next.t('all-jobs.filters.order.publish_date'),
        label: i18next.t('all-jobs.filters.order.publish_date'),
        type: 'order',
      },
      {
        key: 'job_state_progress',
        value: i18next.t('all-jobs.filters.order.job_state_progress'),
        label: i18next.t('all-jobs.filters.order.job_state_progress'),
        type: 'order',
      },
    ],
  },
});
