import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@reverse-hr/pattern-library';

export const ClientInterviewFormEmails = ({
  recipientsType,
  recipients,
  onChange,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'clientInterviews.form.fields',
  });

  return recipients.length > 0 ? (
    <div className="c-client-interview-form__emails">
      <p className="c-client-interview-form__emails-title">
        {t('emailNotifications', { context: recipientsType })}
      </p>

      {recipients.map(recipient => (
        <Checkbox
          key={recipient.modelName}
          id={`client-interview-recipient-${recipientsType}-${recipient.modelName}`}
          modelName={recipient.modelName}
          modifier=" c-client-interview-form__emails-recipient"
          onChange={onChange}
          label={recipient.label}
          value={recipient.value}
        />
      ))}
    </div>
  ) : null;
};
