import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { TaskApplicationHandleClientDislikeFooter } from './TaskApplicationHandleClientDislikeFooter';
import { Trans, useTranslation } from 'react-i18next';
import { TaskDetails } from '../TaskDetails';
import { TASK_PROMPT_TYPES } from '../../../constants/task';

export const TaskApplicationHandleClientDislike = () => {
  const { t } = useTranslation();
  const { task, jobLegacy } = useContext(TaskModalContext);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const reasonPrompt = {
    type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
    text: <TaskApplicationHandleClientDislikePrompt />,
  };

  const prompts = [reasonPrompt, ...task.prompts];

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={prompts}
      suggestion={t(
        'task.detailsView.applicationHandleClientDislike.customSuggestion',
      )}
      title={null}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <TaskApplicationHandleClientDislikeFooter
        setIsErrorAlertVisible={setIsErrorAlertVisible}
        customerFullName={jobLegacy.customer.referent_name}
      />
    </TaskDetails>
  );
};

const TaskApplicationHandleClientDislikePrompt = () => {
  const { application, jobLegacy } = useContext(TaskModalContext);

  return (
    <p className="c-prompter__list-item-content--new">
      <Trans
        i18nKey="task.detailsView.applicationHandleClientDislike.prompt"
        values={{
          customerFullName: jobLegacy.customer.referent_name,
          notes: application.customerInterestNotes ?? '',
        }}
      />
    </p>
  );
};
