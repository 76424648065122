import React from 'react';
import ContentLoader from 'react-content-loader';

export const TaskClosingInterviewSkeleton = () => (
  <div
    className="c-task-closing-interview"
    data-testid="c-task-closing-interview-skeleton"
  >
    <ContentLoader
      height={24}
      width={326}
      backgroundColor="RGB(var(--grey-10))"
      foregroundColor="RGB(var(--grey-20))"
    >
      <rect x="0" y="4" rx="8" ry="8" height="16" width="256" />
      <rect x="272" y="0" rx="12" ry="12" height="24" width="54" />
    </ContentLoader>
  </div>
);
