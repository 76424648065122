import { useEffect, useState } from 'react';
import { apiClientInterviewPost } from '../../../api/client-interviews';
import { CLIENT_FEEDBACK_TYPES } from '../../../constants/applications';
import { useTranslation } from 'react-i18next';

export const useInterviewForm = (application, currentStep, setAlertError) => {
  const { t } = useTranslation();
  const [interviewFormData, setInterviewFormData] = useState(null);
  const [isInterviewFormValid, setIsInterviewFormValid] = useState(true);

  const onClientInterviewFormChange = ({ formData, isFormValid }) => {
    setInterviewFormData(formData);
    setIsInterviewFormValid(isFormValid);
  };

  const onInterviewFormSubmit = async () => {
    if (!interviewFormData || !isInterviewFormValid || !application.id) {
      return;
    }

    try {
      await apiClientInterviewPost({
        applicationId: application.id,
        formData: interviewFormData,
      });
    } catch (error) {
      setAlertError(t('errors.generic'));
    }
  };

  useEffect(() => {
    if (
      currentStep !== CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_ADDITIONAL_INTERVIEWS
    ) {
      setInterviewFormData(null);
      setIsInterviewFormValid(true);
    }
  }, [currentStep]);

  return {
    isInterviewFormValid,
    onClientInterviewFormChange,
    onInterviewFormSubmit,
  };
};
