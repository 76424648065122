import { apiClientInterviewFetch } from '../api/client-interviews';
import { INTERVIEW_TYPES } from '../constants/client-interviews';
import { getLocationAddress } from '../components/ClientInterviewEditModal/ClientInterviewEditModal';
import { useEffect, useState } from 'react';

export const useFetchClientInterviewFormData = ({
  applicationId,
  interviewId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formInitialData, setFormInitialData] = useState(null);

  const fetchInterview = async () => {
    if (!applicationId || !interviewId) {
      return;
    }

    setIsLoading(true);

    try {
      const clientInterviewResponse = await apiClientInterviewFetch({
        applicationId,
        interviewId,
      });

      const { location } = clientInterviewResponse;
      const locationAddress = await getLocationAddress(location);

      setFormInitialData({
        ...clientInterviewResponse,
        interviewType:
          location !== undefined
            ? INTERVIEW_TYPES.IN_PERSON
            : INTERVIEW_TYPES.VIDEO_CALL,
        location: locationAddress,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInterview();
  }, [applicationId, interviewId]);

  return { formInitialData, isLoading, setFormInitialData };
};
