export const utilityGetMeetingsSortedByDate = ({
  interviews,
  sortOrder = UTILITY_INTERVIEWS_SORT_ORDERS.DESC,
}) => {
  interviews.sort((a, b) => {
    const aDate = a.scheduledAt?.localDateTime
      ? new Date(a.scheduledAt?.localDateTime)
      : null;

    const bDate = b.scheduledAt?.localDateTime
      ? new Date(b.scheduledAt?.localDateTime)
      : null;

    return sortOrder === UTILITY_INTERVIEWS_SORT_ORDERS.DESC
      ? bDate - aDate
      : aDate - bDate;
  });

  return interviews;
};

export const utilityGetClientInterviewsSortedByDate = ({
  clientInterviews,
  sortOrder = UTILITY_INTERVIEWS_SORT_ORDERS.DESC,
}) => {
  clientInterviews.sort((a, b) =>
    sortOrder === UTILITY_INTERVIEWS_SORT_ORDERS.DESC
      ? new Date(b.scheduledAtUtc) - new Date(a.scheduledAtUtc)
      : new Date(a.scheduledAtUtc) - new Date(b.scheduledAtUtc),
  );

  return clientInterviews;
};

export const UTILITY_INTERVIEWS_SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
};
