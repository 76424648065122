import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ClientInterviewFormDateTime } from './ClientInterviewFormDateTime';
import { ClientInterviewFormInterviewType } from './ClientInterviewFormInterviewType';
import { FormField } from '@reverse-hr/pattern-library';
import { ClientInterviewFormEmails } from './ClientInterviewFormEmails';
import { INTERVIEW_TYPES } from '../../constants/client-interviews';
import { getValidation } from '../../helpers/utils';
import { USER_LOCALES } from '../../constants/user';

export const ClientInterviewForm = ({
  onChange,
  initialData,
  candidateFullName,
  jobCustomer,
  jobCustomerGuests,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'clientInterviews.form',
  });

  const [isInterviewTypeValid, setIsInterviewTypeValid] = useState(false);

  const [formData, setFormData] = useState({
    ...DEFAULT_INITIAL_STATE,
    ...initialData,
  });

  const {
    required: { validate: validateRequired },
  } = getValidation(['required']);

  const candidateRecipients = [
    {
      modelName: 'sendNotificationToCandidate',
      value: formData.sendNotificationToCandidate,
      label: candidateFullName,
    },
  ];

  const customerRecipients = !!jobCustomer
    ? [
        {
          modelName: 'sendNotificationToCustomer',
          value: formData.sendNotificationToCustomer,
          label: jobCustomer.referent_name,
        },
      ]
    : [];

  const customerGuestsRecipients = !!jobCustomerGuests
    ? jobCustomerGuests.map(customerGuest => ({
        modelName: customerGuest.uuid,
        label: customerGuest.full_name,
        value: formData.customerGuestsToNotify.some(
          customerGuestId => customerGuestId === customerGuest.uuid,
        ),
      }))
    : [];

  const onDateTimeChange = ({ scheduledAtUtc, scheduledAtTimeZone }) => {
    setFormData({
      ...formData,
      scheduledAtUtc,
      scheduledAtTimeZone,
    });
  };

  const onInterviewTypeChange = ({
    interviewType,
    location,
    videoCallUrl,
    isValid,
  }) => {
    setFormData({
      ...formData,
      interviewType,
      location: !!location
        ? {
            address: location,
            language: USER_LOCALES.EN,
          }
        : null,
      url: videoCallUrl,
    });

    setIsInterviewTypeValid(isValid);
  };

  const onInterviewerChange = ({ value }) => {
    setFormData({
      ...formData,
      interviewerDescription: value,
    });
  };

  const onDescriptionChange = ({ value }) => {
    setFormData({
      ...formData,
      interviewDescription: value,
    });
  };

  const onSendNotificationToCandidateChange = ({ value }) =>
    setFormData({
      ...formData,
      sendNotificationToCandidate: value,
    });

  const onSendNotificationToCustomerChange = ({ value }) =>
    setFormData({
      ...formData,
      sendNotificationToCustomer: value,
    });

  const onCustomerGuestsToNotifyChange = ({ name, value }) => {
    let updatedCustomerGuestsToNotify = [...formData.customerGuestsToNotify];

    if (value) {
      updatedCustomerGuestsToNotify = [...updatedCustomerGuestsToNotify, name];
    } else {
      updatedCustomerGuestsToNotify.splice(
        updatedCustomerGuestsToNotify.indexOf(name),
        1,
      );
    }

    setFormData({
      ...formData,
      customerGuestsToNotify: updatedCustomerGuestsToNotify,
    });
  };

  useEffect(() => {
    onChange({
      isFormValid:
        isInterviewTypeValid &&
        !!formData.interviewerDescription &&
        !!formData.interviewDescription,
      formData,
    });
  }, [isInterviewTypeValid, formData]);

  return (
    <div className="c-client-interview-form">
      <h2 className="c-client-interview-form__title">{t('title')}</h2>

      {!!formData.notes && (
        <div className="c-client-interview-form__notes">
          <p className="c-client-interview-form__notes-heading">
            {t('notesHeading')}
          </p>

          <p className="c-client-interview-form__notes-content">
            {formData.notes}
          </p>
        </div>
      )}

      <div className="c-client-interview-form__row">
        <ClientInterviewFormDateTime
          scheduledAtUtc={formData.scheduledAtUtc}
          scheduledAtTimeZone={formData.scheduledAtTimeZone}
          onChange={onDateTimeChange}
        />
      </div>

      <div className="c-client-interview-form__row">
        <ClientInterviewFormInterviewType
          initialInterviewType={formData.interviewType}
          initialLocation={formData.location}
          initialVideoCallUrl={formData.url}
          onChange={onInterviewTypeChange}
        />
      </div>

      <div className="c-client-interview-form__row">
        <FormField
          type="input"
          onChange={onInterviewerChange}
          inputProps={{
            value: formData.interviewerDescription || '',
          }}
          label={t('fields.interviewer.label')}
          placeholder={t('fields.interviewer.placeholder')}
          id="client-interview-interviewer"
          validateFn={validateRequired}
          required
        />
      </div>

      <div className="c-client-interview-form__row">
        <FormField
          type="textarea"
          onChange={onDescriptionChange}
          inputProps={{
            value: formData.interviewDescription || '',
          }}
          label={t('fields.description.label')}
          placeholder={t('fields.description.placeholder')}
          id="client-interview-description"
          validateFn={validateRequired}
          required
        />
      </div>

      <div className="c-client-interview-form__row c-client-interview-form__row--email-notifications">
        <ClientInterviewFormEmails
          onChange={onSendNotificationToCandidateChange}
          recipientsType="candidate"
          recipients={candidateRecipients}
        />

        <ClientInterviewFormEmails
          onChange={onSendNotificationToCustomerChange}
          recipientsType="client"
          recipients={customerRecipients}
        />

        <ClientInterviewFormEmails
          onChange={onCustomerGuestsToNotifyChange}
          recipientsType="guests"
          recipients={customerGuestsRecipients}
        />
      </div>
    </div>
  );
};

const DEFAULT_INITIAL_STATE = {
  scheduledAtUtc: format(new Date(), 'yyyy-MM-dd HH:mm'),
  scheduledAtTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  interviewType: INTERVIEW_TYPES.VIDEO_CALL,
  location: null,
  url: null,
  interviewerDescription: null,
  interviewDescription: null,
  sendNotificationToCandidate: false,
  sendNotificationToCustomer: false,
  customerGuestsToNotify: [],
};
