import { createContext } from 'react';
import type { TTaskLegacy } from '../../models/Task';
import type { TApplication } from '../../models/Application';
import type { TJob, TJobLegacy } from '../../models/Job';
import type { TTaskModalAlternativeView } from '../../definitions/task';

export interface TTaskModalContext {
  alertMessage: string | null;
  alternativeTitle: string | null;
  alternativeView: string | null;
  application: TApplication | null;
  job: TJob | null;
  jobLegacy: TJobLegacy | null;
  onModalClose: () => void;
  onTaskUpdate: (task: TTaskLegacy) => void;
  setAlertMessage: (message: string | null) => void;
  setAlternativeTitle: (title: string | null) => void;
  setAlternativeView: (
    alternativeView: TTaskModalAlternativeView | null,
  ) => void;
  setJob: (updatedJob: TJob) => void;
  setTask: (task: TTaskLegacy) => void;
  task: TTaskLegacy | null;
}

export const TaskModalContext = createContext<TTaskModalContext>({
  alertMessage: null,
  alternativeTitle: null,
  alternativeView: null,
  application: null,
  job: null,
  jobLegacy: null,
  onModalClose: () => {},
  onTaskUpdate: () => {},
  setAlertMessage: () => {},
  setAlternativeTitle: () => {},
  setAlternativeView: () => {},
  setJob: () => {},
  setTask: () => {},
  task: null,
});
