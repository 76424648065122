import React from 'react';
import ContentLoader from 'react-content-loader';

const JobSkeleton = () => (
  <ContentLoader
    backgroundColor="rgb(231, 231, 232)"
    foregroundColor="rgb(207, 207, 208)"
    viewBox="0 0 300 700"
    preserveAspectRatio="none"
  >
    <rect x="0" y="2" rx="0" ry="0" width="6" height="6" />

    <rect x="10" y="0" rx="0" ry="0" width="80" height="14" />
    <rect x="92" y="0" rx="0" ry="0" width="60" height="14" />

    <rect x="10" y="18" rx="0" ry="0" width="50" height="4" />

    <rect x="10" y="26" rx="0" ry="0" width="30" height="4" />
    <rect x="42" y="26" rx="0" ry="0" width="20" height="4" />
    <rect x="68" y="26" rx="0" ry="0" width="20" height="4" />
    <rect x="90" y="26" rx="0" ry="0" width="15" height="4" />
    <rect x="112" y="26" rx="0" ry="0" width="25" height="4" />
    <rect x="139" y="26" rx="0" ry="0" width="35" height="4" />
    <rect x="250" y="21" rx="0" ry="0" width="50" height="10" />

    {/**JOB NUMBERS */}
    <rect x="0" y="45" rx="0" ry="0" width="50" height="30" />
    <rect x="51" y="45" rx="0" ry="0" width="250" height="14" />
    <polygon points="51,60 86,60 91,67 86,75 51,75" />
    <polygon points="87,60 122,60 126,67 122,75 87,75 92,67" />
    <polygon points="123,60 158,60 161,67 158,75 123,75 127,67" />
    <polygon points="159,60 193,60 196,67 193,75 159,75 162,67" />
    <polygon points="194,60 229,60 233,67 229,75 194,75 197,67" />
    <polygon points="230,60 300,60 300,75 230,75 234,67" />

    <rect x="0" y="76" rx="0" ry="0" width="300" height="20" />

    <rect x="0" y="105" rx="0" ry="0" width="15" height="4" />
    <rect x="20" y="105" rx="0" ry="0" width="35" height="4" />
    <rect x="60" y="105" rx="0" ry="0" width="30" height="4" />

    <rect x="0" y="120" rx="0" ry="0" width="8" height="8" />
    <rect x="12" y="121" rx="0" ry="0" width="25" height="6" />

    <rect x="0" y="135" rx="0" ry="0" width="55" height="40" />
    <rect x="60" y="135" rx="0" ry="0" width="55" height="40" />
    <rect x="120" y="135" rx="0" ry="0" width="55" height="40" />
    <rect x="180" y="135" rx="0" ry="0" width="55" height="40" />
    <rect x="240" y="135" rx="0" ry="0" width="55" height="40" />
  </ContentLoader>
);

export default JobSkeleton;
