import { authFetch } from '../utilities/session.legacy';

export const apiTaskFetch = async taskId =>
  await authFetch({
    endpoint: `api/jarvis/tasks/${taskId}`,
  });

export const apiTaskUpdate = async ({ taskId, payload }) =>
  await authFetch({
    endpoint: `api/jarvis/tasks/${taskId}`,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const apiTaskPauseUpdate = async taskId =>
  await authFetch({
    endpoint: `api/jarvis/tasks/${taskId}/pause`,
    method: 'POST',
  });

export const apiTaskUnpauseUpdate = async taskId =>
  await authFetch({
    endpoint: `api/jarvis/tasks/${taskId}/unpause`,
    method: 'POST',
  });
