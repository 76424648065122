import React from 'react';
import { NavLink } from 'react-router-dom';

const NavSubItem = ({ title, company_name, path, has_updates, modifier }) => {
  return (
    <div
      aria-label={title}
      data-balloon-pos="down"
      data-balloon-length="medium"
    >
      <NavLink
        activeClassName="c-nav-item--active"
        data-testid="nav-subitem-link"
        className={`c-nav-item c-nav-item--sub ${modifier}`}
        to={path}
      >
        <p data-testid="nav-subitem-title" className="c-nav-item__caption">
          {title}
        </p>
        <p data-testid="nav-subitem-company" className="c-nav-item__subcaption">
          {company_name}
        </p>
        {!!Number(has_updates) && (
          <span
            data-testid="nav-subitem-notification"
            className="c-nav-item__notification"
          ></span>
        )}
      </NavLink>
    </div>
  );
};

NavSubItem.defaultProps = {
  title: '',
  path: '/',
  company_name: '',
  type: 'job',
  modifier: '',
};

export default NavSubItem;
