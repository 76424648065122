import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

const PrivateRoute = ({ children, ...rest }) => {
  const { user, userLoading, userError } = useStoreState(state => {
    const { user } = state;
    return {
      user: user.user,
      userLoading: user.isLoading,
      userError: user.isError,
    };
  });

  return userLoading ? (
    <div className="c-tasks">Loading user...</div>
  ) : userError ? (
    <div className="c-tasks">Error loading user</div>
  ) : (
    <Route
      {...rest}
      render={() => {
        if (!!user) {
          const childrenWithProps = React.Children.map(children, child => {
            return React.cloneElement(child, { user });
          });
          return childrenWithProps;
        }
        return (
          <Redirect
            to={{
              pathname: '/not-logged',
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
