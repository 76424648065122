import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import NavSubItem from './NavSubItem';
import { Icon } from '@reverse-hr/pattern-library';
import JobsSkeleton from './skeletons/Jobs';
import Notice from './Notice';
import { jobsError } from '../helpers/default-values';

import { CSSTransition, SwitchTransition } from 'react-transition-group';

const NavItem = ({
  label,
  type,
  path,
  has_updates,
  modifier,
  subitems,
  error,
  loading = true,
}) => {
  const renderSubItems = () => {
    return subitems.map((sub, i) => {
      return (
        <li key={`${sub.caption}-${i}`} className="c-navigation__list-item sub">
          <NavSubItem {...sub} />
        </li>
      );
    });
  };

  const nodeRef = useRef(null);
  return (
    <>
      <NavLink
        data-testid="nav-item-path"
        activeClassName="c-nav-item--active"
        exact
        className={`c-nav-item ${modifier}`}
        to={path}
      >
        <span className="c-nav-item__icon">
          <Icon name={`icn-${type}-24px`} />
        </span>
        <p data-testid="nav-item-label" className="c-nav-item__caption">
          {label}
        </p>
        {!subitems && Number(has_updates) ? (
          <span
            data-testid="nav-item-notification"
            className="c-nav-item__notification"
          ></span>
        ) : null}
      </NavLink>
      {!!subitems ? (
        <SwitchTransition mode="out-in">
          <CSSTransition
            nodeRef={nodeRef}
            key={loading}
            timeout={100}
            classNames="page"
          >
            {loading ? (
              <>
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
                <JobsSkeleton />
              </>
            ) : error ? (
              <div className="c-nav-item main">
                <Notice
                  type="error-partial"
                  title={jobsError().title}
                  text={jobsError().text}
                />
              </div>
            ) : (
              <ul
                data-testid="nav-item-subitems"
                className="c-navigation__sublist"
                ref={nodeRef}
              >
                {renderSubItems()}
              </ul>
            )}
          </CSSTransition>
        </SwitchTransition>
      ) : null}
    </>
  );
};

NavItem.defaultProps = {
  label: 'Tasks',
  path: '/',
  subitems: null,
};

export default NavItem;
