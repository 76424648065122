import React from 'react';

const EmailAbstract = ({
  modifier,
  email: { subject, body, date, message_id, thread_id },
  onEmailClick,
}) => {
  const handleEmailClick = () => {
    onEmailClick({ messageId: message_id, threadId: thread_id });
  };

  return (
    <div
      onClick={handleEmailClick}
      className="c-email-abstract"
      data-testid="email-abstract"
    >
      <p
        className="c-email-abstract__subject"
        data-testid="email-abstract-subject"
      >
        {subject}
      </p>
      <div className="c-email-abstract__body" data-testid="email-abstract-body">
        {!!body ? body.replace(/_____/g, ' ') : ''}
      </div>
    </div>
  );
};

EmailAbstract.defaultProps = {
  modifier: '',
  email: {
    subject: 'Subject',
    body: 'Body',
  },
  onEmailClick: () => {},
};

export default EmailAbstract;
