import React from 'react';
import { LoaderSpinner } from './LoaderSpinner';
import classNames from 'classnames';

export const Loader = ({ className = '', title = '', text = '' }) => {
  if (!title && !text) {
    return <LoaderSpinner className={className} isSmall />;
  }

  const wrapperClassNames = classNames('c-loader', className, {
    'c-loader--titled': !!title,
    'c-loader--tiny': !title,
  });

  return (
    <div className={wrapperClassNames}>
      <LoaderSpinner isSmall={!title} />
      {title && <p className="c-loader__title">{title}</p>}
      {text && <p className="c-loader__text">{text}</p>}
    </div>
  );
};
