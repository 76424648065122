import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import TaskNotes from '../../components/TaskNotes';
import {
  starredNotesHeader,
  notStarredNotesHeader,
} from '../../helpers/default-values';
import Sentry from '../../sentry';

export const JobTabNotes = ({ handleUpdateNote, handleRemoveNote }) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const setNotifications = useStoreActions(actions => actions.setNotifications);
  const createNote = useStoreActions(actions => actions.notes.createNote);

  const normalNotes = useStoreState(state => state.job.jobNormalNotes);
  const favoriteNotes = useStoreState(state => state.job.jobFavoriteNotes);
  const jobStateProgress = useStoreState(
    state => state.job.job.job_state_progress,
  );

  const handleCreateNote = useCallback(
    e => {
      return new Promise(async (resolve, reject) => {
        if (e.body && !e.body.trim()) {
          setNotifications({
            id: `create-note-${Date.parse(new Date())}`,
            visible: true,
            types: ['warning', 'raw'],
            message: t('note.notEmpty'),
          });
          reject();
          const noteError = new Error(
            'Note not created because some values are empty or missing',
          );
          const extra = {
            jobId: id,
            title: e.title,
            body: e.body,
          };
          Sentry.captureException(noteError, { extra });
        }
        try {
          const response = await createNote(e);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    [createNote, id, setNotifications, t],
  );

  return (
    <>
      <div className="c-filters"></div>
      {!!favoriteNotes.length && (
        <TaskNotes
          header={starredNotesHeader()}
          notes={favoriteNotes}
          job={{ id }}
          onUpdate={handleUpdateNote}
          onRemove={handleRemoveNote}
        />
      )}
      <TaskNotes
        canAddNote
        context="job"
        header={notStarredNotesHeader()}
        notes={normalNotes}
        job={{ id, job_state_progress: jobStateProgress }}
        onCreate={handleCreateNote}
        onUpdate={handleUpdateNote}
        onRemove={handleRemoveNote}
      />
    </>
  );
};
