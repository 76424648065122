import React, { useCallback } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { NotificationAlert } from './NotificationAlert';

const Notifications = () => {
  const notifications = useStoreState(state => state.notifications);
  const hideNotification = useStoreActions(actions => actions.hideNotification);
  const removeNotification = useStoreActions(
    actions => actions.removeNotification,
  );

  const onClearAlert = useCallback(
    id => {
      hideNotification({ id });
      setTimeout(() => {
        removeNotification({ id });
      }, 300);
    },
    [hideNotification, removeNotification],
  );

  return (
    <div className="c-notifications">
      {notifications.map(notification => {
        return (
          <NotificationAlert
            key={`notification-${notification.id}`}
            status={notification.types[0]}
            onClose={onClearAlert}
            closeDelay={3000}
            isVisible={notification.visible}
          >
            {notification.message}
          </NotificationAlert>
        );
      })}
    </div>
  );
};

export default Notifications;
