import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  isFuture,
} from 'date-fns';
import { useTranslation } from 'react-i18next';

export const useDateDifferenceL10n = date => {
  const { t } = useTranslation(null, {
    keyPrefix: 'common.formatting.date.dateDifference',
  });

  const now = new Date();
  const isFutureDate = isFuture(date);
  const context = isFutureDate ? 'future' : 'past';
  const minutes = Math.abs(differenceInMinutes(date, now));

  const dateDifferences = {
    hours: Math.abs(differenceInHours(date, now)),
    days: Math.abs(differenceInDays(date, now)),
    weeks: Math.abs(differenceInWeeks(date, now)),
    months: Math.abs(differenceInMonths(date, now)),
    years: Math.abs(differenceInYears(date, now)),
  };

  let outputLabel = t(`${context}.minutes`, { count: minutes });

  Object.keys(dateDifferences).forEach(differenceKey => {
    const differenceValue = dateDifferences[differenceKey];

    if (differenceValue > 0) {
      outputLabel = t(`${context}.${differenceKey}`, {
        count: differenceValue,
      });
    }
  });

  return outputLabel;
};
