import { APP_LOGIN_URL, REACT_APP_HOST } from '../constants/endpoints';
import jwtDecode from 'jwt-decode';
import { apiJwtPost, apiJwtRefreshPost } from '../api/user';

export const authFetch = async ({ endpoint, method, headers, body, query }) => {
  try {
    await authenticate();
  } catch (error) {
    clearTokens();
    return window.location.assign(APP_LOGIN_URL);
  }

  try {
    const token = localStorage.getItem('token');

    const queryParams = !!query
      ? `?${new URLSearchParams(query).toString()}`
      : '';

    const response = await fetch(
      `${REACT_APP_HOST}/${endpoint}${queryParams}`,
      {
        method: method || 'GET',
        body,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          ...headers,
        },
      },
    );

    if (response.status === 204) {
      return;
    }

    const responseBody = await response.json();

    if (!response.ok) {
      return Promise.reject(responseBody);
    }

    return responseBody;
  } catch (error) {
    return Promise.reject(error);
  }
};

const authenticate = async () => {
  const { token, refreshToken, isTokenExpired } = getJwtStatus();

  if (isTokenExpired) {
    try {
      const refreshedTokens = await apiJwtRefreshPost(refreshToken);

      return setJwtInLocalStorage(refreshedTokens);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  if (!!token && !!refreshToken) {
    return;
  }

  try {
    const fetchedToken = await apiJwtPost();

    setJwtInLocalStorage(fetchedToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getJwtStatus = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  if (!token && !refreshToken) {
    return {
      token: null,
      refreshToken: null,
    };
  }

  try {
    const decodedToken = jwtDecode(token);
    const now = new Date();
    const isTokenExpired = Math.round(now.getTime() / 1000) >= decodedToken.exp;

    return {
      token,
      refreshToken,
      isTokenExpired,
    };
  } catch (error) {
    clearTokens();

    return {
      token: null,
      refreshToken: null,
    };
  }
};

const setJwtInLocalStorage = ({ token, refreshToken }) => {
  if (token === undefined && refreshToken === undefined) {
    return clearTokens();
  }

  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

const clearTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};
