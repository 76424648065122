import React, { useContext } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { TaskModalContext } from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { TASK_MODAL_ALTERNATIVE_VIEWS } from '../../../constants/task';
import { apiApplicationCustomerInterestUpdate } from '../../../api/applications';

export const TaskApplicationHandleClientDislikeFooter = ({
  setIsErrorAlertVisible,
}) => {
  const { t } = useTranslation();

  const { setAlternativeView, task, jobLegacy, onModalClose } =
    useContext(TaskModalContext);

  const onConfirmFeedbackClick = () => {
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.MISSED_CANDIDATE);
  };

  const onChangeFeedbackClick = async () => {
    setIsErrorAlertVisible(false);

    if (!task.application) {
      return;
    }

    try {
      await apiApplicationCustomerInterestUpdate({
        applicationId: task.application.uuid,
        isCustomerInterested: true,
      });
      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return (
    <>
      <Action
        type="outline"
        label={t(
          'task.detailsView.applicationHandleClientDislike.changeFeedbackCta',
          { customerFullName: jobLegacy.customer.referent_name },
        )}
        onClick={onChangeFeedbackClick}
      />

      <Action
        label={t('task.detailsView.applicationHandleClientDislike.cta')}
        onClick={onConfirmFeedbackClick}
      />
    </>
  );
};
