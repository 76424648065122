import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import Email from './Email';
import { Loader } from './Loader';

const EmailLogModal = forwardRef(({ threadId, messageId }, ref) => {
  const [scrolled, setScrolled] = useState(false);
  const selectedRef = useRef(null);

  // ACTIONS
  const fetchJobEmails = useStoreActions(actions => actions.job.fetchJobEmails);
  const setModalOpened = useStoreActions(actions => actions.setModalOpened);
  const setModalId = useStoreActions(actions => actions.setModalId);
  const setJobEmails = useStoreActions(actions => actions.job.setJobEmails);
  const setJobEmailsLoading = useStoreActions(
    actions => actions.job.setJobEmailsLoading,
  );

  // STATE
  const jobEmails = useStoreState(state => state.job.jobEmails);
  const jobEmailsLoading = useStoreState(state => state.job.jobEmailsLoading);
  const jobEmailsError = useStoreState(state => state.job.jobEmailsError);

  useEffect(() => {
    fetchJobEmails({ threadId });
  }, [threadId, fetchJobEmails]);

  useEffect(() => {
    if (!jobEmailsLoading) {
      setTimeout(function () {
        if (!scrolled) {
          const selectedEmail = selectedRef.current;
          if (selectedEmail) {
            selectedEmail.scrollIntoView();
          }
          setTimeout(function () {
            setScrolled(true);
            clearTimeout(this);
          }, 1000);
        }
        clearTimeout(this);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobEmailsLoading]);
  useImperativeHandle(ref, () => ({
    cleanModal() {
      setModalOpened(false);
      setModalId(null);
      setJobEmails([]);
      setJobEmailsLoading(true);
    },
  }));

  return (
    <div className="o-thread o-thread--emails">
      {jobEmailsLoading ? (
        <div className="c-modal__loading">
          <Loader />
        </div>
      ) : jobEmailsError ? (
        <div>error</div>
      ) : (
        jobEmails.map((email, index) => {
          return (
            <div
              key={`${threadId}-${index}`}
              className="o-thread__item"
              ref={messageId === email.message_id ? selectedRef : null}
            >
              <Email
                selected={!scrolled ? messageId === email.message_id : true}
                modifier="c-email--log-modal"
                {...email}
              />
            </div>
          );
        })
      )}
    </div>
  );
});

export default EmailLogModal;
