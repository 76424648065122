import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { TaskDetails } from '../TaskDetails';
import { apiApplicationCustomerInterestUpdate } from '../../../api/applications';
import { ActionWithLoader } from '../../ActionWithLoader';

export const TaskApplicationAskForClientFeedbackOnCurriculum = () => {
  const { t } = useTranslation();
  const { onModalClose, task, jobLegacy } = useContext(TaskModalContext);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const onChangeFeedbackClick = async () => {
    setIsErrorAlertVisible(false);

    try {
      await apiApplicationCustomerInterestUpdate({
        applicationId: task.application.uuid,
        isCustomerInterested: true,
      });

      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={task.prompts}
      suggestion={t(
        'task.detailsView.applicationAskForClientFeedbackOnCurriculum.customSuggestion',
      )}
      title={null}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <ActionWithLoader
        onClick={onChangeFeedbackClick}
        label={t(
          'task.detailsView.applicationAskForClientFeedbackOnCurriculum.cta',
          {
            candidateFullName: task.application.candidate_full_name,
            customerFullName: jobLegacy.customer.referent_name,
          },
        )}
        modifier="c-action--change-feedback"
      />
    </TaskDetails>
  );
};
