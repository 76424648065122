import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@reverse-hr/pattern-library';

export const TaskClosingExpectedSuccessDate = ({
  expectedSuccessDate,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="c-task-closing-expected-success-date">
      <label
        className="c-task-closing-expected-success-date__label"
        htmlFor="c-task-closing-expected-success-date"
      >
        {t('modals.closing.steps.recruiting.datePickerLabel')}
      </label>

      <DateTimePicker
        id="c-task-closing-expected-success-date"
        className="c-task-closing-expected-success-date__input"
        onChange={onChange}
        hasTimePicker={false}
        value={expectedSuccessDate}
      />
    </div>
  );
};
