import { thunk, action } from 'easy-peasy';
import { formatDistanceStrict } from 'date-fns';

import Firebase from '../firebase';
import tasks from './tasks';
import task from './task/task';
import job from './job';
import navigation from './navigation';
import user from './user';
import notes from './notes';
import jobs from './jobs';

import GTM from '../gtm';
import Sentry from '../sentry';

export default {
  // globalState
  isTabFocused: true,
  isModalOpened: false,
  modalId: null,
  notifications: [],
  isSplashVisible: true,
  locationType: {
    type: '', // 'jobs' / 'tasks' / 'job_detail'
    id: '', // only for job detail
  },
  firebaseInitialized: false,
  firebaseNotificationBus: [],

  // actions
  setTabFocused: action((state, payload) => {
    state.isTabFocused = payload;
  }),
  setSplashVisible: action((state, payload) => {
    state.isSplashVisible = payload;
  }),
  setModalOpened: action((state, payload) => {
    state.isModalOpened = payload;
  }),
  setModalId: action((state, payload) => {
    state.modalId = payload;
  }),
  setNotifications: action((state, payload) => {
    state.notifications.push(payload);
  }),
  setLocationType: action((state, payload) => {
    state.locationType = payload;
  }),
  addToFirebaseNotificationBus: action((state, payload) => {
    state.firebaseNotificationBus.push(payload);
  }),
  setFirebaseNotificationBus: action((state, payload) => {
    state.firebaseNotificationBus = payload;
  }),
  setFirebaseInitialized: action((state, payload) => {
    state.firebaseInitialized = payload;
  }),
  hideNotification: action((state, payload) => {
    state.notifications = state.notifications.map(n => {
      if (n.id !== payload.id) {
        n.visible = false;
      }
      return n;
    });
  }),
  removeNotification: action((state, payload) => {
    state.notifications = state.notifications.filter(n => n.id !== payload.id);
  }),

  // models
  tasks,
  task,
  job,
  user,
  notes,
  jobs,
  navigation,
  updateFirebaseNotifications: thunk(
    async (actions, payload, { getState, dispatch }) => {
      const { locationType, firebaseNotificationBus } = getState();
      // Se diverse notifiche arrivano sulla stessa job (cron) chiamo l'API solo una volta
      const cleaned = Array.from(new Set([...firebaseNotificationBus]));
      if (cleaned.length) {
        console.log(`These jobs should be updated after 2s: ${cleaned}`);
        // In ogni caso la sidebar delle job deve aggiornarsi
        dispatch.navigation.fetchJobs();
        switch (locationType.type) {
          case 'jobs':
            // dispatch.jobs.fetchJobs()
            break;
          case 'tasks':
            dispatch.tasks.fetchTasks();
            break;
          case 'job_detail':
            if (cleaned.includes(locationType.id)) {
              dispatch.job.fetchJob({
                id: locationType.id,
                shouldNotify: true,
              });
            }
            break;
          default:
            break;
        }
        actions.setFirebaseNotificationBus([]);
      } else {
        // console.log('Nothing to update')
      }
    },
  ),
  initialize: thunk(async (actions, payload, { getState, dispatch }) => {
    localStorage.setItem('tab_focused_timing', new Date());

    document.addEventListener('visibilitychange', e => {
      e.preventDefault();
      if (document.visibilityState === 'visible') {
        actions.setTabFocused(true);
        localStorage.setItem('tab_focused_timing', new Date());
      } else {
        actions.setTabFocused(false);
        const init = localStorage.getItem('tab_focused_timing');
        const end = new Date();
        const timing = formatDistanceStrict(new Date(end), new Date(init), {
          includeSeconds: true,
        });
        GTM.trackEvent({
          category: 'general',
          action: 'time_on_jarvis',
          label: timing,
        });
      }
    });

    // window.addEventListener('blur', () => {
    //   actions.setTabFocused(false)
    // })
    // window.addEventListener('focus', () => {
    //   actions.setTabFocused(true)
    // })
    let user;
    try {
      user = await dispatch.user.fetchUser();
    } catch (error) {
      console.error(
        `There was an error during store initialization - ${error}`,
      );
    }

    setTimeout(() => actions.setSplashVisible(false), 2000);

    if (user && !user.amIImpersonatingSomeoneElse) {
      const swListener = new BroadcastChannel('firebase-listener');
      swListener.onmessage = function (event) {
        // event.data.payload.data.job_id
        console.debug(
          `Firebase SW triggered this event - job_id: ${event.data.payload.data.job_id}`,
        );
        if (event.data && event.data.payload && event.data.payload.data) {
          const { job_id } = event.data.payload.data;
          actions.addToFirebaseNotificationBus(job_id);
        }
      };

      // FIREBASE
      const { initialize } = Firebase;
      const { firebase, config } = await initialize();
      actions.setFirebaseInitialized(true);
      const messaging = firebase.messaging();
      try {
        const token = await messaging.getToken(config);
        console.debug('Firebase token', token);
        if (
          token &&
          user &&
          (!user.jarvis_token || token !== user.jarvis_token)
        ) {
          await dispatch.user.setUserFirebaseToken(token);
        }

        messaging.onMessage(payload => {
          const data = payload.data || payload;
          console.debug(
            `Firebase - onMessage - ${payload.data ? 'with data' : 'no data'} `,
            data,
          );
          // mapDispatch.jobs_list()
          if (payload.data) {
            const { job_id } = payload.data;
            actions.addToFirebaseNotificationBus(job_id);
          }
        });
      } catch (error) {
        const err = `Firebase token error - ${error}`;
        Sentry.captureException(err);
      }
    } else {
      console.debug(
        'Firebase is not initialized because you use an impersonated user',
      );
    }
  }),
};
