import { format, isValid } from 'date-fns';
import React, { useState } from 'react';
import { DateTimePicker, Label } from '@reverse-hr/pattern-library';
import Multiselect from '../Multiselect';
import { TIMEZONES } from '../../constants/timezones';
import { utilityGetOptionByValue } from '../../utilities/get-option-by-value';

export const DateTimeZonePicker = ({
  datePickerProps,
  timeZoneProps,
  onChange,
}) => {
  const initialDateValue = onFormatDateString(datePickerProps?.defaultValue);
  const [dateValue, setDateValue] = useState(initialDateValue);

  const timeZoneDefaultValue =
    timeZoneProps?.defaultValue ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const initialTimeZone = utilityGetOptionByValue(
    TIMEZONES_OPTIONS,
    timeZoneDefaultValue,
  );

  const [timeZone, setTimeZone] = useState(initialTimeZone);

  const isLabelVisible = !!datePickerProps && !!datePickerProps.label;

  const onChangeDateTimePicker = newDateValue => {
    const newDateValueFormatted = newDateValue
      ? onFormatDateString(newDateValue)
      : '';

    setDateValue(newDateValueFormatted);
    onChange({ date: newDateValueFormatted, timeZone: timeZone.value });
  };

  const onBlur = () => {
    const isInvalidDate = !isValid(new Date(dateValue));

    if (isInvalidDate) {
      const todayDateString = format(new Date(), 'yyyy-MM-dd HH:mm');

      setDateValue(todayDateString);
      onChange({ date: todayDateString, timeZone: timeZone.value });
    }
  };

  const onChangeTimeZone = newTimeZone => {
    setTimeZone(newTimeZone);
    onChange({ date: dateValue, timeZone: newTimeZone.value });
  };

  return (
    <div className="c-datepicker">
      <div className="c-form-field">
        {isLabelVisible && (
          <div className="c-form-field__label">
            <Label
              id={datePickerProps.label.id}
              label={datePickerProps.label.text}
              required={datePickerProps.isRequired}
            />
          </div>
        )}

        <div className="c-form-field__control">
          <DateTimePicker
            value={dateValue}
            onChange={onChangeDateTimePicker}
            isRequired={datePickerProps.isRequired}
            onBlur={onBlur}
          />
        </div>
      </div>

      {!!timeZoneProps && (
        <div className="c-form-field c-datepicker__field-time-zone">
          <div className="c-form-field__label">
            <Label
              id={timeZoneProps.label.id}
              label={timeZoneProps.label.text}
              required={timeZoneProps.isRequired}
            />
          </div>

          <div className="c-form-field__control">
            <Multiselect
              shouldClear={false}
              menuPlacement="bottom"
              options={TIMEZONES_OPTIONS}
              defaultValue={timeZone}
              onChange={onChangeTimeZone}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const onFormatDateString = dateString => {
  const date = new Date(dateString);
  const dateToFormat = isValid(date) ? date : new Date();

  return format(dateToFormat, 'yyyy-MM-dd HH:mm');
};

const TIMEZONES_OPTIONS = TIMEZONES.map(timezone => ({
  value: timezone.code,
  label: `(${timezone.utc}) ${timezone.code.replace('_', ' ')}`,
}));
