import React from 'react';
import Log from './Log';
import ActivitiesSkeleton from './skeletons/Activities';
import Notice from './Notice';

const Activities = ({ activities, loading, error, empty, onEmailClick }) => {
  return (
    <>
      {loading ? (
        <div className="c-log">
          <ActivitiesSkeleton />
        </div>
      ) : error ? (
        <div>error</div>
      ) : Object.keys(activities).length ? (
        // .sort((a, b) => Date.parse(new Date(b)) - Date.parse(new Date(a)))
        Object.keys(activities).map((date, index) => {
          return (
            <Log
              key={`${date}-${index}`}
              activities={activities[date]}
              modifier={index >= 1 ? 'c-log--top-bordered' : ''}
              date={date}
              onEmailClick={onEmailClick}
            />
          );
        })
      ) : (
        <Notice type="activities" title={empty.title} text={empty.text} />
      )}
    </>
  );
};

Activities.defaultProps = {
  loading: true,
  error: false,
  activities: {},
  onEmailClick: () => {},
};

export default Activities;
