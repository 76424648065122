import React from 'react';
import { Trans } from 'react-i18next';
import { MeetingListItem } from './MeetingListItem';

export const MeetingListDateGroup = ({
  meetings,
  dateKey,
  onEditClientInterviewClick,
  onEditScoutInterviewClick,
  onEditMeetingClick,
  onDeleteMeetingClick,
}) => (
  <div className="c-meeting-list__date-group">
    {!!dateKey && (
      <p className="c-meeting-list__date-heading">
        <Trans
          i18nKey="job.meetingList.dateHeading"
          values={{ date: new Date(dateKey) }}
          components={{ span: <span /> }}
        />
      </p>
    )}

    <ul className="c-meeting-list__list">
      {meetings.map(meeting => (
        <MeetingListItem
          key={meeting.key}
          meeting={meeting}
          onEditClientInterviewClick={onEditClientInterviewClick}
          onEditScoutInterviewClick={onEditScoutInterviewClick}
          onEditMeetingClick={onEditMeetingClick}
          onDeleteMeetingClick={onDeleteMeetingClick}
        />
      ))}
    </ul>
  </div>
);
