import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Action } from '@reverse-hr/pattern-library';
import { TaskModalContext } from '../../TaskModal';
import { TaskDetails } from '../TaskDetails';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_PROMPT_TYPES,
} from '../../../constants/task';
import { JOB_SET_DATE_MODAL_TYPES } from '../../../constants/job';

export const TaskApplicationPrepareForScoutInterview = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'task.detailsView.applicationPrepareForScoutInterview',
  });

  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const { jobLegacy, task, application, setAlternativeView } =
    useContext(TaskModalContext);

  const candidateFullName = task.application.candidate_full_name;
  const scheduledScoutInterview = application.scoutInterview;

  const isScoutInterviewWaitingAcceptance =
    application.scoutInterview && !application.scoutInterview.confirmedAt;

  const prompts = isScoutInterviewWaitingAcceptance
    ? [
        {
          type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
          text: (
            <Trans
              i18nKey="task.detailsView.applicationPrepareForScoutInterview.prompts.waitingForScoutInterviewAcceptance"
              values={{
                candidateFullName,
                date: new Date(
                  scheduledScoutInterview.scheduledAt.localDateTime,
                ),
                timeZone: scheduledScoutInterview.scheduledAt.timeZone,
              }}
            />
          ),
        },
        ...task.prompts,
      ]
    : task.prompts;

  const onSetScoutInterview = async () => {
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.SET_DATE, {
      modalType: JOB_SET_DATE_MODAL_TYPES.SCOUT_INTERVIEW,
    });
  };

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      title={t('title', {
        customerFullName: jobLegacy.customer.referent_name,
        candidateFullName,
      })}
      prompts={prompts}
      suggestion={t('suggestion')}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <Action onClick={onSetScoutInterview} label={t('cta')} />
    </TaskDetails>
  );
};
