import React from 'react';
import classNames from 'classnames';
import Suggestion from '../Suggestion';
import { PrompterItem } from './PrompterItem';
import { useTranslation } from 'react-i18next';

export const Prompter = ({ items, suggestion }) => {
  const { t } = useTranslation();

  const wrapperClassNames = classNames('c-prompter', {
    'c-prompter--suggestion': !!suggestion,
  });

  const hasItems = !!items && items.length > 0;

  return (
    <div className={wrapperClassNames}>
      {hasItems && (
        <div className="c-prompter__main-content">
          <p className="c-prompter__title">{t('task.prompt-title')}</p>

          <ul className="c-prompter__list">
            {items.map(({ type, text, ...item }, promptIdx) => (
              <PrompterItem
                key={promptIdx}
                content={text}
                item={item}
                promptType={type}
              />
            ))}
          </ul>
        </div>
      )}

      {!!suggestion && (
        <div className="c-prompter__suggestion">
          <Suggestion suggestion={suggestion} />
        </div>
      )}
    </div>
  );
};
