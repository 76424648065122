import React, { useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useLocation } from 'react-router-dom';
import { parseUrlParams } from '../helpers/utils';
import {
  hotHeader,
  pausedHeader,
  tasksEmpty,
  tasksError,
  tasksHeading,
  toDoHeader,
} from '../helpers/default-values';

import Tasks from '../components/Tasks';
import Heading from '../components/Heading';
import Modal from '../components/Modal';
import Feedback from '../components/Feedback';
import Notice from '../components/Notice';
import {
  baseTasksActionsSelector,
  baseTasksStoreSelector,
} from '../selectors/tasks/selectors';
import TaskListType from '../constants/tasks/taskListType';
import { TaskModal } from '../components/TaskModal';

const Homepage = () => {
  const location = useLocation();

  const [areTasksEmpty, setAreTasksEmpty] = useState(false);
  const [taskId, setTaskId] = useState(null);

  // ACTIONS
  const setModalOpened = useStoreActions(actions => actions.setModalOpened);
  const fetchJobs = useStoreActions(actions => actions.navigation.fetchJobs);
  const fetchTasks = useStoreActions(actions => actions.tasks.fetchTasks);
  const updateTask = useStoreActions(actions => actions.task.updateTask);
  const setTask = useStoreActions(actions => actions.task.setTask);

  // STATE
  const isModalOpened = useStoreState(state => state.isModalOpened);
  const { pausedTasks, hotTasks, bingoTask, declinedTask, toDoTasks } =
    useStoreState(baseTasksStoreSelector);
  const { updateTasksListWithUpdatedTaskItem } = useStoreActions(
    baseTasksActionsSelector,
  );

  const { subitems: jobList } = useStoreState(
    state => state.navigation.navigation.items.jobs,
  );

  const tasksIsError = useStoreState(state => state.tasks.isError);
  const tasksLoading = useStoreState(state => state.tasks.isLoading);

  const jobListTaskDictionary = Object.fromEntries(
    jobList.map(jobListItem => [
      jobListItem.id,
      { customerFullName: jobListItem.referent_name },
    ]),
  );

  // Comps functions

  const onTaskClickHandler = useCallback(
    async id => {
      setModalOpened(true);
      setTaskId(id);
    },
    [setModalOpened],
  );

  const onTaskModalClose = () => {
    setModalOpened(false);
    setTaskId(null);
  };

  useEffect(() => {
    const params = parseUrlParams(location.search);
    if (params.task) {
      onTaskClickHandler(params.task);
    }
    if (params.jarvisVersion) {
      const jarvisVersion = document.querySelector('#jarvis-version');
      if (jarvisVersion) {
        jarvisVersion.classList.add('visible');
      }
    }
  }, [location, onTaskClickHandler]);

  useEffect(() => {
    const fetchFreshData = async () => {
      await fetchTasks();
      await fetchJobs();
    };
    fetchFreshData();
  }, [fetchTasks, fetchJobs]);

  useEffect(() => {
    if (!tasksLoading && !tasksIsError) {
      if (!hotTasks.length && !bingoTask && !toDoTasks.length) {
        setAreTasksEmpty(true);
      } else if (areTasksEmpty) {
        setAreTasksEmpty(false);
      }
    }
  }, [
    hotTasks,
    toDoTasks,
    bingoTask,
    tasksLoading,
    tasksIsError,
    areTasksEmpty,
  ]);

  const updateFeedbackTask = (task, payload) => {
    setTask(task);
    updateTask(payload);
    setTask({});
  };

  return (
    <>
      <Heading icon={tasksHeading().icon}>{tasksHeading().title}</Heading>

      {!tasksIsError && (
        <div className="u-mb-xxlarge">
          {!!bingoTask && (
            <Feedback
              type="bingo"
              onUpdateTask={updateFeedbackTask}
              task={{
                ...bingoTask,
                titleData: jobListTaskDictionary[bingoTask.job.id],
              }}
            />
          )}
          {!!declinedTask && (
            <Feedback
              type="declined"
              onUpdateTask={updateFeedbackTask}
              task={{
                ...declinedTask,
                titleData: jobListTaskDictionary[declinedTask.job.id],
              }}
            />
          )}
        </div>
      )}

      {tasksIsError ? (
        <div className="c-tasks">
          <Notice
            type="error-partial"
            title={tasksError().title}
            text={tasksError().text}
          />
        </div>
      ) : (
        <>
          <Tasks
            className="page"
            modifier="c-tasks--hot"
            tasks={hotTasks}
            loading={tasksLoading}
            header={hotHeader()}
            type={TaskListType.HOT}
            onTaskClick={onTaskClickHandler}
            jobsDictionary={jobListTaskDictionary}
          />
          <Tasks
            className="page"
            modifier="c-tasks--to-do"
            tasks={toDoTasks}
            loading={tasksLoading}
            header={toDoHeader()}
            type={TaskListType.TODO}
            onTaskClick={onTaskClickHandler}
            jobsDictionary={jobListTaskDictionary}
          />
          <Tasks
            className="page"
            modifier="c-tasks--paused"
            tasks={pausedTasks}
            loading={tasksLoading}
            header={pausedHeader()}
            type={TaskListType.PAUSED}
            onTaskClick={onTaskClickHandler}
            updateTasks={updateTasksListWithUpdatedTaskItem}
            jobsDictionary={jobListTaskDictionary}
          />
        </>
      )}
      {areTasksEmpty && (
        <Notice
          type="tasks"
          title={tasksEmpty().title}
          text={tasksEmpty().text}
        />
      )}

      <Modal visible={isModalOpened} modifier="c-modal--task-modal">
        <TaskModal
          taskId={taskId}
          onClose={onTaskModalClose}
          onTaskUpdate={updateTasksListWithUpdatedTaskItem}
        />
      </Modal>
    </>
  );
};

export default Homepage;
