import React from 'react';
// import { useTranslation } from 'react-i18next'
import { Parser } from 'html-to-react';
import ActionTypes from '../constants/task/actionTypes';
import { Avatar } from '@reverse-hr/pattern-library';

const UserEvent = ({ modifier, type, data, action }) => {
  // const { t } = useTranslation()
  const htmlParser = new Parser();

  return (
    <div className={`c-user-event ${modifier}`}>
      <p
        data-testid="user-event-job-state-progress"
        className="c-user-event__phase"
      >
        {type !== 'email' ? (
          `${data[type].job_state_progress}`
        ) : (
          <span>
            <br />
          </span>
        )}
      </p>

      <Avatar
        avatar={data[type].manager.icon}
        modifier="c-user-event__author-img"
        size={24}
      />

      <p
        data-testid="user-event-manager-name"
        className="c-user-event__author-name"
      >
        {data[type].manager.first_name} {data[type].manager.last_name}
      </p>
      {type === 'task' ? (
        <>
          <p
            data-testid="user-event-task-title"
            className="c-user-event__taskname"
          >
            {htmlParser.parse(data[type].task_descriptive_title)}
          </p>
        </>
      ) : null}
    </div>
  );
};

UserEvent.defaultProps = {
  modifier: '',
  action: ActionTypes.COMPLETE,
};
export default UserEvent;
