import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import JobNumbers from './JobNumbers';
import JobSteps from './JobSteps';
import Horserace from './Horserace';
import { Icon } from '@reverse-hr/pattern-library';

const JobCompletion = ({
  uuid,
  publishDate,
  presentationMeetingDate,
  modifier,
  statistics,
  steps,
  horserace,
  onHorseraceClick,
  dealId,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    // if (!expanded && horserace && !Object.keys(horserace.horserace).length) {
    //   onHorseraceClick()
    // }
    setExpanded(!expanded);
  };

  return (
    <div className={`c-job-completion ${modifier}`}>
      {statistics ? (
        <div
          data-testid="job-completion-numbers"
          className="c-job-completion__numbers"
        >
          <JobNumbers
            uuid={uuid}
            publishDate={publishDate}
            presentationMeetingDate={presentationMeetingDate}
            statistics={statistics}
            dealId={dealId}
          />
        </div>
      ) : null}

      {steps ? (
        <div
          data-testid="job-completion-steps"
          className="c-job-completion__steps"
        >
          <JobSteps phases={steps} />
        </div>
      ) : null}
      {horserace && horserace.applications && horserace.applications.length ? (
        expanded ? (
          <>
            <div
              data-testid="job-completion-horserace"
              className="c-job-completion__horserace"
            >
              <Horserace horserace={horserace} />
            </div>
            <label
              data-testid="job-completion-horserace-btn-close"
              onClick={handleExpandClick}
              className="c-job-completion__toggle-label expand"
            >
              <span>
                {expanded
                  ? t('job.horserace.unexpand')
                  : t('job.horserace.expand')}
              </span>
              <Icon name={`icn-${expanded ? 'close' : 'expand'}-24px`} />
            </label>
          </>
        ) : (
          <label
            data-testid="job-completion-horserace-btn-open"
            onClick={handleExpandClick}
            className="c-job-completion__toggle-label expand"
          >
            <span>
              {expanded
                ? t('job.horserace.unexpand')
                : t('job.horserace.expand')}
            </span>
            <Icon name={`icn-${expanded ? 'close' : 'expand'}-24px`} />
          </label>
        )
      ) : null}
    </div>
  );
};

JobCompletion.defaultProps = {
  modifier: '',
  onHorseraceClick: () => {},
};

export default JobCompletion;
