import React from 'react';
import ContentLoader from 'react-content-loader';

const ActivitiesSkeleton = () => (
  <ContentLoader
    backgroundColor="rgb(231, 231, 232)"
    foregroundColor="rgb(207, 207, 208)"
    viewBox="0 0 300 600"
  >
    <rect x="2" y="0" rx="0" ry="0" width="35" height="15" />

    <rect x="70" y="20" rx="0" ry="0" width="20" height="3" />
    <rect x="70" y="25" rx="0" ry="0" width="13" height="13" />
    <rect x="30" y="27" rx="0" ry="0" width="10" height="8" />
    <rect x="52" y="27" rx="0" ry="0" width="10" height="8" />
    <rect x="86" y="27" rx="0" ry="0" width="130" height="8" />

    <rect x="30" y="43" rx="0" ry="0" width="300" height="1" />

    <rect x="70" y="50" rx="0" ry="0" width="13" height="13" />
    <rect x="30" y="52" rx="0" ry="0" width="10" height="8" />
    <rect x="52" y="52" rx="0" ry="0" width="10" height="8" />
    <rect x="86" y="52" rx="0" ry="0" width="20" height="8" />
    <rect x="70" y="65" rx="0" ry="0" width="250" height="40" />

    <rect x="0" y="110" rx="0" ry="0" width="300" height="1" />

    <rect x="2" y="115" rx="0" ry="0" width="12" height="15" />
    <rect x="17" y="115" rx="0" ry="0" width="30" height="15" />
    <rect x="50" y="115" rx="0" ry="0" width="20" height="15" />

    <rect x="70" y="135" rx="0" ry="0" width="20" height="3" />
    <rect x="70" y="140" rx="0" ry="0" width="13" height="13" />
    <rect x="30" y="142" rx="0" ry="0" width="10" height="8" />
    <rect x="52" y="142" rx="0" ry="0" width="10" height="8" />
    <rect x="86" y="142" rx="0" ry="0" width="130" height="8" />

    <rect x="30" y="158" rx="0" ry="0" width="300" height="1" />

    <rect x="70" y="165" rx="0" ry="0" width="13" height="13" />
    <rect x="30" y="167" rx="0" ry="0" width="10" height="8" />
    <rect x="52" y="167" rx="0" ry="0" width="10" height="8" />
    <rect x="86" y="167" rx="0" ry="0" width="20" height="8" />
    <rect x="70" y="180" rx="0" ry="0" width="250" height="40" />
  </ContentLoader>
);

export default ActivitiesSkeleton;
