import React, { useContext } from 'react';
import { FormField } from '@reverse-hr/pattern-library';
import { OfferDetailsFormContext } from './OfferDetailsForm';
import classNames from 'classnames';

export const OfferDetailsFormField = ({
  className,
  fieldKey,
  label,
  placeholder,
  required,
}) => {
  const { formData, formErrors, onFormChange } = useContext(
    OfferDetailsFormContext,
  );

  const wrapperClassName = classNames(className);
  const value = formData[fieldKey] ?? '';

  const error =
    formErrors && formErrors[fieldKey] ? formErrors[fieldKey].trim() : null;

  const onFieldChange = ({ name, value }) =>
    onFormChange({
      [name]: value,
    });

  return (
    <FormField
      modifier={wrapperClassName}
      inputProps={{
        size: 'small',
        value,
      }}
      id={fieldKey}
      label={label}
      placeholder={placeholder}
      required={required}
      type="input"
      onChange={onFieldChange}
      externalError={error}
    />
  );
};
