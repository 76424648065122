import React, { useContext } from 'react';
import ActionTypes from '../constants/task/actionTypes';
import { TaskModalContext } from '../components/TaskModal';
import { apiTaskUpdate } from '../api/task';
import { TASK_MODAL_ALTERNATIVE_VIEWS } from '../constants/task';
import GTM from '../gtm';

export const useTaskCompleter = (
  setIsErrorAlertVisible = _isAlertVisible => {},
) => {
  const { onModalClose, setAlternativeView, task } =
    useContext(TaskModalContext);

  const trackCtaClick = () => {
    GTM.trackEvent({
      category: 'task',
      action: 'cta_reverse',
      label: `${task.job_state_progress} - ${task.type}`,
    });
  };

  const onCompleterActionClick = async () => {
    setIsErrorAlertVisible(false);

    if (task.cta.type === ActionTypes.SCHEDULE_CLIENT_INTERVIEW) {
      return setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.CLIENT_INTERVIEW);
    }

    await completeTask();
  };

  const completeTask = async () => {
    try {
      await apiTaskUpdate({
        taskId: task.id,
        payload: { completed_at: true },
      });

      trackCtaClick();
      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return { completeTask, trackCtaClick, onCompleterActionClick };
};
