import TagManager from 'react-gtm-module';

const GTM = (() => {
  const gtmConfig = {
    gtmId: 'GTM-WQDRZG2',
  };
  const initialize = () => {
    TagManager.initialize(gtmConfig);
  };

  const trackEvent = ({
    category,
    action,
    label,
    event = 'jarvis_interaction',
  }) => {
    const evt = {
      dataLayer: {
        event,
        event_category: category,
        event_action: action,
        event_label: label,
      },
    };
    if (window.dataLayer) {
      TagManager.dataLayer(evt);
    }
  };
  if (process.env.NODE_ENV === 'production') {
    return {
      initialize,
      trackEvent,
    };
  }
  return {
    initialize: () => {},
    trackEvent: () => {},
  };
})();

// task chiuso: "phase + tasktypename"
// link esterni - link // action: cta_reverse, label: same
// riapertura del task: "phase + tasktypename" - + numero di volte cliccato -> localstorage
// tempo passato sul tab jarvis (2 min 45 sec)

export default GTM;
