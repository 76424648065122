export const JOB_TABS = {
  NOTES: 'notes',
  ACTIVITIES: 'activities',
  INTERVIEWS_AND_MEETINGS: 'interviews-and-meetings',
};

export const JOB_SET_DATE_MODAL_TYPES = {
  POSTPONE_TASK: 'postponeTask',
  PRESENTATION_MEETING: 'presentationMeeting',
  PROFILING_MEETING: 'profilingMeeting',
  SCOUT_INTERVIEW: 'scoutInterview',
};

export const MEETING_TYPES = {
  CLIENT_INTERVIEW: 'clientInterview',
  SCOUT_INTERVIEW: JOB_SET_DATE_MODAL_TYPES.SCOUT_INTERVIEW,
  PROFILING_MEETING: JOB_SET_DATE_MODAL_TYPES.PROFILING_MEETING,
  PRESENTATION_MEETING: JOB_SET_DATE_MODAL_TYPES.PRESENTATION_MEETING,
};

export const INTERVIEW_API_TYPE_TO_MEETING_TYPE_MAP = {
  client_interview: MEETING_TYPES.CLIENT_INTERVIEW,
  scout_interview: MEETING_TYPES.SCOUT_INTERVIEW,
};

export const SLOW_HUNT_CONDITIONS = Object.freeze({
  minPublishDays: 3,
  maxAddedProfiles: 75,
});

export const NOT_ENOUGH_APPROVED_PROFILES_CONDITIONS = Object.freeze({
  maxAddedProfiles: 100,
  maxPresentaionRemainingDays: 4,
  maxApprovedProfiles: 3,
});
