import React from 'react';
import Multiselect from './Multiselect';

const Filters = ({ modifier, filters, onFilterChange }) => {
  const handleFilterChange = (filter, triggeredAction) => {
    console.log('hello', triggeredAction);
    onFilterChange(filter);
  };

  return (
    <div className={`c-filters ${modifier}`}>
      {Object.keys(filters).map((group, index) => {
        const defaultValue = filters[group].options.filter(
          f => f.key === filters[group].selected,
        );
        return (
          <div key={`group-${index}`} className="c-filters__group">
            <p className="c-filters__item c-filters__label">
              {filters[group].label}
            </p>

            <div className="c-filters__item">
              <Multiselect
                placeholder={filters[group].placeholder}
                menuPlacement="bottom"
                options={filters[group].options}
                onChange={handleFilterChange}
                defaultValue={defaultValue}
                shouldClear={false}
                // clearable={filters[group].clearable}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

Filters.defaultProps = {
  modifier: '',
  filters: {},
  onFilterChange: () => {},
  /**
   * filters: {
   *  order: {
   *    label: 'Ordina per',
   *    options: [
   *      {
   *        key: 'pippo',
   *        value: 'Pippo'
   *      }
   *    ]
   *  }
   * }
   */
};

export default Filters;
