import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import ActionTypes from '../../../constants/task/actionTypes';
import { ActionWithLoader } from '../../ActionWithLoader';
import { TaskDetails } from '../TaskDetails';
import { useTaskCompleter } from '../../../hooks/use-task-completer';

export const TaskDefault = () => {
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const { task, onModalClose } = useContext(TaskModalContext);

  const { trackCtaClick, onCompleterActionClick } = useTaskCompleter(
    setIsErrorAlertVisible,
  );

  const onExternalLinkCtaClick = () => {
    trackCtaClick();
    onModalClose();
  };

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={task.prompts}
      title={null}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      {task.cta.type === ActionTypes.EXTERNAL_LINK ? (
        <LinkWrapper
          type="primary"
          onClick={onExternalLinkCtaClick}
          href={task.cta.url}
          target="_blank"
          rel="noopener noreferrer"
          label={task.cta.text}
        />
      ) : (
        <ActionWithLoader
          onClick={onCompleterActionClick}
          disabled={task.cta.type === ActionTypes.DISABLED}
          label={task.cta.text}
        />
      )}
    </TaskDetails>
  );
};
