import React from 'react';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  hotHeader,
  toDoHeader,
  starredNotesHeader,
  pausedHeader,
} from '../../helpers/default-values';
import TaskNotes from '../../components/TaskNotes';
import Tasks from '../../components/Tasks';
import TaskListType from '../../constants/tasks/taskListType';

export const JobTabTasks = ({
  handleUpdateNote,
  handleRemoveNote,
  onTaskClickHandler,
}) => {
  const { id } = useParams();

  const updateJobTasksListWithUpdatedTaskItem = useStoreActions(
    actions => actions.job.updateJobTasksListWithUpdatedTaskItem,
  );

  const favoriteNotes = useStoreState(state => state.job.jobFavoriteNotes);
  const hotTasks = useStoreState(state => state.job.jobHotTasks);
  const toDoTasks = useStoreState(state => state.job.jobToDoTasks);
  const jobPausedTasks = useStoreState(state => state.job.jobPausedTasks);
  const tasksLoading = useStoreState(state => state.job.jobTasksLoading);
  const tasksError = useStoreState(state => state.job.jobTasksError);
  const job = useStoreState(state => state.job.job);

  const jobTaskDictionary = {
    [job.id]: {
      customerFullName: job.customer.referent_name,
    },
  };

  return (
    <>
      {!!favoriteNotes.length && (
        <TaskNotes
          header={starredNotesHeader()}
          compact
          notes={favoriteNotes}
          job={{ id }}
          onUpdate={handleUpdateNote}
          onRemove={handleRemoveNote}
        />
      )}
      {!!hotTasks.length && (
        <Tasks
          className="page"
          modifier="c-tasks--hot"
          tasks={hotTasks}
          loading={tasksLoading}
          error={tasksError}
          type={TaskListType.HOT}
          header={hotHeader()}
          onTaskClick={onTaskClickHandler}
          jobsDictionary={jobTaskDictionary}
        />
      )}
      {!!toDoTasks.length && (
        <Tasks
          className="page"
          modifier="c-tasks--to-do"
          tasks={toDoTasks}
          loading={tasksLoading}
          error={tasksError}
          type={TaskListType.TODO}
          header={toDoHeader()}
          onTaskClick={onTaskClickHandler}
          jobsDictionary={jobTaskDictionary}
        />
      )}
      <Tasks
        className="page"
        modifier="c-tasks--paused"
        tasks={jobPausedTasks}
        loading={tasksLoading}
        error={tasksError}
        type={TaskListType.PAUSED}
        header={pausedHeader()}
        updateTasks={updateJobTasksListWithUpdatedTaskItem}
        jobsDictionary={jobTaskDictionary}
      />
    </>
  );
};
