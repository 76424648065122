import { differenceInDays } from 'date-fns';
import type { TJob } from '../models/Job';
import { REACT_APP_HOST } from '../constants/endpoints';
import {
  SLOW_HUNT_CONDITIONS,
  NOT_ENOUGH_APPROVED_PROFILES_CONDITIONS,
} from '../constants/job';

export const useJobStatistics = (
  jobUuid: string,
  publishDate: string | null,
  presentationMeetingDate: string | null,
  stats: TJob['statistics'] | null,
  dealId: string,
) => {
  if (!stats) {
    return {};
  }

  const passedDays = publishDate
    ? differenceInDays(new Date(), new Date(publishDate))
    : 0;

  const remainingDaysToPresentationMeeting = presentationMeetingDate
    ? differenceInDays(new Date(presentationMeetingDate), new Date())
    : Infinity;

  const huntTooSlow =
    passedDays >= SLOW_HUNT_CONDITIONS.minPublishDays &&
    stats.addedProfiles < SLOW_HUNT_CONDITIONS.maxAddedProfiles;

  const notEnoughApprovedProfiles =
    stats.addedProfiles <
      NOT_ENOUGH_APPROVED_PROFILES_CONDITIONS.maxAddedProfiles &&
    remainingDaysToPresentationMeeting <
      NOT_ENOUGH_APPROVED_PROFILES_CONDITIONS.maxPresentaionRemainingDays &&
    stats.approvedProfiles <
      NOT_ENOUGH_APPROVED_PROFILES_CONDITIONS.maxApprovedProfiles;

  return {
    days: {
      values: { days: { value: passedDays, url: null, alarm: huntTooSlow } },
    },
    'row-one': {
      hunted: {
        values: {
          hunted: {
            value: stats.addedProfiles,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}`,
            alarm: huntTooSlow || notEnoughApprovedProfiles,
          },
        },
      },
      applicants: {
        values: {
          unevaluated: {
            value: stats.unevaluatedApplicants,
            url: `${REACT_APP_HOST}/hound/jobs/${jobUuid}/applicants/?page=1`,
          },
          evaluated: {
            value: stats.evaluatedApplicants,
            url: `${REACT_APP_HOST}/hound/jobs/${jobUuid}/applicants/?page=1`,
          },
        },
      },
      beforeJobInterview: {
        values: {
          notInterested: {
            value: stats.notInterestedProfilesBeforeJobInterview,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=not_interested`,
          },
          agreed: {
            value: stats.profilesWhoAgreedToJobInterview,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=agreed_to_job_interview`,
          },
        },
      },
      interestedBut: {
        values: {
          brand: {
            value: stats.interestedProfilesAfterJobInterviewWithBrandConcerns,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=interested_with_concerns`,
          },
          role: {
            value: stats.interestedProfilesAfterJobInterviewWithRoleConcerns,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=interested_with_concerns`,
          },
          compensation: {
            value:
              stats.interestedProfilesAfterJobInterviewWithCompensationConcerns,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=interested_with_concerns`,
          },
          location: {
            value:
              stats.interestedProfilesAfterJobInterviewWithLocationConcerns,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=interested_with_concerns`,
          },
        },
      },
      interested: {
        values: {
          interested: {
            value: stats.interestedProfilesAfterJobInterviewWithoutConcerns,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=interested`,
          },
        },
      },
      approved: {
        values: {
          approved: {
            value: stats.approvedProfiles,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=feedback&states=approved`,
          },
        },
      },
      presented: {
        values: {
          presented: {
            value: stats.presentedProfiles,
            url: `${REACT_APP_HOST}/hound/profiles/potential_matches/?jobId=${jobUuid}&currentPage=1&tab=presented_to_employer`,
          },
        },
      },
    },
    'row-two': {
      feedbackCv: {
        values: {
          waiting: {
            value: stats.candidatesWaitingForLikeOrDislike,
            url: `${REACT_APP_HOST}/employer_view/${dealId}/interviewing`,
          },
          notInteresting: {
            value: stats.candidatesDislikedByClient,
            url: `${REACT_APP_HOST}/employer_view/${dealId}/interviewing`,
          },
          interesting: {
            value: stats.candidatesLikedByClient,
            url: `${REACT_APP_HOST}/employer_view/${dealId}/interviewing`,
          },
        },
      },
      scout: {
        values: {
          draft: {
            value: stats.candidatesWithDraftScoutReport,
            url: null,
          },
          published: {
            value: stats.candidatesWithApprovedScoutReport,
            url: null,
          },
        },
      },
      firstInterview: {
        values: {
          firstInterview: {
            value: stats.candidatesAtFirstInterview,
            url: null,
          },
        },
      },
      intoConsideration: {
        values: {
          intoConsideration: {
            value: stats.candidatesTakenIntoConsideration,
            url: null,
          },
        },
      },
      additionalInterviews: {
        values: {
          additionalInterviews: {
            value: stats.candidatesConsideredForAdditionalInterviews,
            url: null,
          },
        },
      },
      offers: {
        values: {
          incoming: {
            value: stats.candidatesConsideredForAnOffer,
            url: null,
          },
          made: {
            value: stats.candidatesWithPendingOffer,
            url: null,
          },
          declined: {
            value: stats.candidatesWithDeclinedOffer,
            url: null,
          },
        },
      },
      bingo: {
        values: {
          bingo: {
            value: stats.candidatesWithAcceptedOffer,
            url: null,
          },
        },
      },
    },
  };
};
