import React from 'react';
import { Avatar } from '@reverse-hr/pattern-library';

const User = ({ user, clickable }) => {
  return (
    <div
      className={`c-logged-user ${clickable ? 'c-logged-user--clickable' : ''}`}
    >
      <div className="c-logged-user__info">
        <p data-testid="user-first-name" className="c-logged-user__name">
          {user.type === 'team' ? user.name : user.first_name}
        </p>
      </div>

      {user.icon ? <Avatar avatar={user.icon} size={36} /> : null}
    </div>
  );
};

User.defaultProps = {
  clickable: false,
  user: {
    first_name: 'Silvia',
    last_name: 'Fiori',
    icon: 'https://r-everse-jarvis.tangible.is/images/recruiter-image.png',
  },
};

export default User;
