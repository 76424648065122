import * as Sentry from '@sentry/react';

const sentry = (() => {
  const initialize = () => {
    if (process.env.REACT_APP_SENTRY_RELEASE) {
      // production
      Sentry.init({
        dsn: 'https://78afda964f1440f3959d16b08c1f31ad@o60911.ingest.sentry.io/5427951',
        release: process.env.REACT_APP_SENTRY_RELEASE,
      });
    } else {
      Sentry.init({
        dsn: 'https://78afda964f1440f3959d16b08c1f31ad@o60911.ingest.sentry.io/5427951',
        beforeSend: event => {
          console.debug('ERROR SENTRY', event);
          return null;
        },
      });
    }
  };

  const captureException = (error, options = {}) => {
    Sentry.captureException(error, options);
  };

  const setUser = user => {
    Sentry.configureScope(scope => scope.setUser(user));
  };

  return {
    initialize,
    captureException,
    setUser,
  };
})();

export default sentry;
