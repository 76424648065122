import React from 'react';
import { Icon } from '@reverse-hr/pattern-library';
import UserEvent from './UserEvent';
import TaskNote from './TaskNote';
import EmailAbstract from './EmailAbstract';
import { formatDate } from '../helpers/utils';

const dateMap = {
  task: 'completed_at',
  note: 'created_at',
  email: 'date',
};
const iconMap = {
  task: 'confirm',
  note: 'post',
  note_favorite: 'favorite-on',
  email_sent: 'mail-send',
  email_received: 'mail-in',
};

const Activity = ({ modifier, type, data, onEmailClick }) => {
  const getModifierAndIcon = () => {
    let result = modifier;
    let icon = 'icn-confirm-24px';
    if (type !== 'task') {
      result += ` c-activity--${type}`;
      icon = `icn-${
        type === 'email'
          ? `${iconMap[`${type}_${data[type].direction}`]}`
          : type === 'note' && data[type].favorite
          ? iconMap[`${type}_favorite`]
          : `${iconMap[type]}`
      }-24px`;
    }
    return {
      _modifier: result,
      icon,
    };
  };
  const { _modifier, icon } = getModifierAndIcon();

  const renderActivityExtra = () => {
    if (type === 'note') {
      return <TaskNote readonly compact {...data} />;
    }
    if (type === 'email') {
      return <EmailAbstract onEmailClick={onEmailClick} {...data} />;
    }
    return null;
  };

  return (
    !!data && (
      <div
        data-testid="activity-container"
        className={`c-activity ${_modifier}`}
      >
        <p data-testid="activity-time" className="c-activity__time">
          {formatDate(new Date(data[type][dateMap[type]].date), 'HH:mm')}
        </p>
        <div data-testid="activity-icon" className="c-activity__icon">
          <Icon name={icon} />
        </div>
        <div className="c-activity__event">
          <UserEvent
            type={type}
            data={data}
            modifier="c-user-event--activity"
          />
          {type !== 'task' && (
            <div data-testid="activity-extra" className="c-activity__extra">
              {renderActivityExtra()}
            </div>
          )}
        </div>
      </div>
    )
  );
};

Activity.defaultProps = {
  type: 'task', // 'email', 'note'
  modifier: '',
  onEmailClick: () => {},
};

export default Activity;
