import React from 'react';

const Scores = ({ items, modifier }) => {
  return (
    <div className={`c-scores ${modifier}`}>
      {!!items &&
        items.map((item, i) => {
          return (
            <div key={i} className="c-scores__item">
              <p className="c-scores__label">{item.label}</p>
              <p className="c-scores__value">{item.value}</p>
            </div>
          );
        })}
    </div>
  );
};

Scores.defaultProps = {
  modifier: '',
  items: [
    // {
    //   label: 'Critical number Q2',
    //   value: '5/12'
    // },
    // {
    //   label: 'Success fee Q2',
    //   value: '25.000€'
    // },
    // {
    //   label: 'Carico Q2',
    //   value: '72%'
    // }
  ],
};

export default Scores;
