import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Loader } from './Loader';
import Multiselect from '../components/Multiselect';
import { Action } from '@reverse-hr/pattern-library';

const JobAssignationModal = forwardRef(
  ({ title, customer, deliveryManager, onAssign }, ref) => {
    const [recruiter, setRecruiter] = useState({
      value: deliveryManager.id,
      label: `${deliveryManager.first_name} ${deliveryManager.last_name}`,
    });
    const [mappedManagers, setMappedManagers] = useState([]);
    const { t } = useTranslation();

    // STATE
    const managers = useStoreState(state => state.job.jobManagers);
    const managersLoading = useStoreState(
      state => state.job.jobManagersLoading,
    );
    const managersError = useStoreState(state => state.job.jobManagersError);
    // ACTIONS
    const getManagers = useStoreActions(store => store.job.getManagers);

    useEffect(() => {
      getManagers();
    }, [getManagers]);

    useEffect(() => {
      if (managers) {
        setMappedManagers(
          managers.map(m => {
            return {
              value: m.id,
              label: `${m.first_name} ${m.last_name}`,
            };
          }),
        );
      }
    }, [managers]);

    const handleAssignation = () => {
      onAssign(recruiter);
    };
    const handleSelectChange = option => {
      setRecruiter(option);
    };

    useImperativeHandle(ref, () => ({
      cleanModal() {
        setRecruiter(deliveryManager);
      },
    }));
    return (
      <>
        <div className="c-modal__content">
          <div className="c-job-assignment">
            <p className="c-job-assignment__title">{title}</p>
            <p className="c-job-assignment__company">{customer.company_name}</p>

            <p className="c-job-assignment__select-label">
              {t('job.header.assignation.label')}
            </p>

            <div className="c-job-assignment__select">
              {managersLoading ? (
                <Loader />
              ) : managersError ? (
                <p>Sorry there was an error loading managers</p>
              ) : mappedManagers.length ? (
                <Multiselect
                  options={mappedManagers}
                  defaultValue={recruiter}
                  shouldClear={false}
                  onChange={handleSelectChange}
                  menuPlacement="bottom"
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
        <footer className="c-modal__footer">
          <div className="c-modal__footer-cta">
            <Action
              onClick={handleAssignation}
              label={t('job.header.assignation.cta')}
            />
          </div>
        </footer>
      </>
    );
  },
);

JobAssignationModal.defaultProps = {
  onAssign: () => {},
};

export default JobAssignationModal;
