import React from 'react';
import ContentLoader from 'react-content-loader';

export const TaskModalSkeleton = () => (
  <div className="c-task-modal-skeleton">
    <div className="c-task-modal-skeleton__header">
      <ContentLoader width="480" height="16">
        <rect x="0" y="0" rx="0" ry="0" width="480" height="16" />
      </ContentLoader>

      <ContentLoader width="364" height="24">
        <rect x="0" y="4" rx="0" ry="0" width="64" height="16" />
        <rect x="112" y="0" rx="0" ry="0" width="180" height="24" />
        <rect x="340" y="0" rx="0" ry="0" width="24" height="24" />
      </ContentLoader>
    </div>

    <ContentLoader width="100%" height="500">
      <rect x="0" y="24" rx="0" ry="0" width="100%" height="232" />
      <rect x="0" y="300" rx="0" ry="0" width="380" height="24" />
      <rect x="0" y="340" rx="0" ry="0" width="24%" height="160" />
      <rect x="24%" y="340" rx="0" ry="0" width="1.3%" height="0" />
      <rect x="25.3%" y="340" rx="0" ry="0" width="24%" height="160" />
      <rect x="49.3%" y="340" rx="0" ry="0" width="1.3%" height="0" />
      <rect x="50.6%" y="340" rx="0" ry="0" width="24%" height="160" />
      <rect x="74.6%" y="340" rx="0" ry="0" width="1.3%" height="0" />
      <rect x="76%" y="340" rx="0" ry="0" width="24%" height="160" />
    </ContentLoader>

    <div className="c-task-modal-skeleton__footer">
      <ContentLoader width="160" height="54">
        <rect x="0" y="0" rx="0" ry="0" width="160" height="54" />
      </ContentLoader>

      <ContentLoader width="336" height="54">
        <rect x="0" y="0" rx="0" ry="0" width="160" height="54" />
        <rect x="176" y="0" rx="0" ry="0" width="160" height="54" />
      </ContentLoader>
    </div>
  </div>
);
