import { useState } from 'react';
import { TASK_MODAL_ALTERNATIVE_VIEWS } from '../../constants/task';
import { TaskClientInterview } from '../TaskModalViews/TaskClientInterview';
import { TaskMissedCandidate } from '../TaskModalViews/TaskMissedCandidate';
import { TaskSendEmail } from '../TaskModalViews/TaskSendEmail';
import { TaskSetDate } from '../TaskModalViews/TaskSetDate';
import { TaskClosing } from '../TaskModalViews/TaskClosing';
import { TaskBingo } from '../TaskModalViews/TaskBingo';
import { TaskJraSlidesGeneration } from '../TaskModalViews/TaskJraSlidesGeneration';
import { TaskShareEmployerView } from '../TaskModalViews/TaskShareEmployerView';

export const useAlternativeView = () => {
  const [alternativeViewName, setAlternativeViewName] = useState(null);
  const [alternativeViewProps, setAlternativeViewProps] = useState(null);

  const AlternativeViewComponent = !!alternativeViewName
    ? MODAL_NAME_TO_COMPONENT_MAP[alternativeViewName]
    : null;

  const setAlternativeView = (
    selectedAlternativeViewName = null,
    selectedAlternativeViewProps = null,
  ) => {
    setAlternativeViewProps(selectedAlternativeViewProps);
    setAlternativeViewName(selectedAlternativeViewName);
  };

  return {
    AlternativeViewComponent,
    alternativeViewName,
    alternativeViewProps,
    setAlternativeView,
  };
};

const MODAL_NAME_TO_COMPONENT_MAP = {
  [TASK_MODAL_ALTERNATIVE_VIEWS.BINGO]: TaskBingo,
  [TASK_MODAL_ALTERNATIVE_VIEWS.CLIENT_INTERVIEW]: TaskClientInterview,
  [TASK_MODAL_ALTERNATIVE_VIEWS.CLOSING]: TaskClosing,
  [TASK_MODAL_ALTERNATIVE_VIEWS.GENERATE_JRA_SLIDES]: TaskJraSlidesGeneration,
  [TASK_MODAL_ALTERNATIVE_VIEWS.MISSED_CANDIDATE]: TaskMissedCandidate,
  [TASK_MODAL_ALTERNATIVE_VIEWS.SEND_EMAIL]: TaskSendEmail,
  [TASK_MODAL_ALTERNATIVE_VIEWS.SET_DATE]: TaskSetDate,
  [TASK_MODAL_ALTERNATIVE_VIEWS.SHARE_EMPLOYER_VIEW]: TaskShareEmployerView,
};
