import React from 'react';
import { AppModal } from '../AppModal';
import { SetMeetingDateModal } from '../SetMeetingDateModal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  baseJobActionsSelector,
  selectorJobState,
} from '../../selectors/job/selectors';
import { useHandleError } from '../../helpers/hooks/use-handle-error';
import { Alert } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import { useUpdateJobMeetings } from '../../hooks/use-update-job-meetings';
import { apiApplicationScoutInterviewUpdate } from '../../api/applications';
import { format } from 'date-fns';
import { MEETING_TYPES } from '../../constants/job';

export const MeetingListEditMeetingModal = ({
  editMeetingFormType,
  editScoutInterviewFormData,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const { job } = useStoreState(selectorJobState);
  const { setJob } = useStoreActions(baseJobActionsSelector);

  const { handledError, setHandledError, handleError } = useHandleError({
    genericError: t('modals.setMeetingDate.error'),
  });

  const isModalVisible = !!editMeetingFormType || !!editScoutInterviewFormData;
  const modalType = editMeetingFormType ?? MEETING_TYPES.SCOUT_INTERVIEW;
  const isScoutInterviewModal = modalType === MEETING_TYPES.SCOUT_INTERVIEW;

  const isScoutInterviewWaitingAcceptance =
    isScoutInterviewModal &&
    !!editScoutInterviewFormData &&
    !editScoutInterviewFormData.confirmedAt;

  const onJobUpdate = updatedJob => {
    setJob(updatedJob);
    onClose();
  };

  const onModalSubmit = async modalFormData => {
    if (editScoutInterviewFormData) {
      return await onScoutInterviewSubmit(modalFormData);
    }

    await onMeetingSubmit(modalFormData);
  };

  const onScoutInterviewSubmit = async ({ date, timeZone }) => {
    try {
      await apiApplicationScoutInterviewUpdate({
        applicationId: editScoutInterviewFormData.applicationId,
        localDateTime: format(new Date(date), 'yyyy-MM-dd HH:mm:ss'),
        timeZone,
      });

      onUpdate();
    } catch (error) {
      handleError(error);
    }
  };

  const {
    initialDateValue: initialMeetingDateValue,
    initialTimeZoneValue: initialMeetingTimeZoneValue,
    onSubmit: onMeetingSubmit,
  } = useUpdateJobMeetings({
    job,
    meetingType: editMeetingFormType,
    onUpdate: onJobUpdate,
    onError: handleError,
  });

  const initialDateValue = !!editScoutInterviewFormData
    ? editScoutInterviewFormData.scheduledAt.localDateTime
    : initialMeetingDateValue;

  const initialTimeZoneValue = !!editScoutInterviewFormData
    ? editScoutInterviewFormData.scheduledAt.timeZone
    : initialMeetingTimeZoneValue;

  const isEditing = !!editScoutInterviewFormData;

  return (
    <AppModal
      isVisible={isModalVisible}
      className="c-modal--set-date c-modal--auto-height c-meeting-list-edit-meeting-modal"
    >
      {!!handledError && (
        <Alert
          modifier="c-alert--fixed c-meeting-list-edit-meeting-modal__alert"
          closable
          icon="icn-warning-outline-24"
          type="negative"
          onClose={() => setHandledError(null)}
        >
          {handledError}
        </Alert>
      )}

      <SetMeetingDateModal
        isEditing={isEditing}
        isTimeZoneActive
        initialDateValue={initialDateValue}
        initialTimeZoneValue={initialTimeZoneValue}
        modalType={modalType}
        onClose={onClose}
        onSubmit={onModalSubmit}
      >
        {isScoutInterviewWaitingAcceptance ? (
          <Alert
            type="warning"
            icon="icn-error-outline-24px"
            closable={false}
            modifier="c-alert--fluid"
          >
            <Trans
              i18nKey="modals.setMeetingDate.alert.waitingForScoutInterviewAcceptanceAlert"
              values={{
                candidateFullName: editScoutInterviewFormData.candidateFullName,
                date: new Date(
                  editScoutInterviewFormData.scheduledAt.localDateTime,
                ),
                timeZone: editScoutInterviewFormData.scheduledAt.timeZone,
              }}
            />
          </Alert>
        ) : null}
      </SetMeetingDateModal>
    </AppModal>
  );
};
