import { utilityRequestWithAuth } from '../utilities/session';
import type { TShare, TShareRequest } from '../models/Share';

export const apiJobShareFetch = async (jobId: string) =>
  await utilityRequestWithAuth<TApiJobShareFetchResponse>({
    endpoint: `/api/v1/jarvis/jobs/${jobId}/shares`,
  });

export const apiJobSharePost = async ({
  jobId,
  shareDetails,
}: TApiJobSharePostPayload) =>
  await utilityRequestWithAuth<TApiJobShareFetchResponse>({
    endpoint: `/api/v1/jarvis/jobs/${jobId}/shares`,
    body: shareDetails,
    method: 'POST',
  });

export const apiJobShareDelete = async ({
  jobId,
  shareId,
}: TApiJobShareDeletePayload) =>
  await utilityRequestWithAuth<void>({
    endpoint: `/api/v1/jarvis/jobs/${jobId}/shares/${shareId}`,
    method: 'DELETE',
  });

interface TApiJobShareFetchResponse {
  shares: TShare[];
}

export interface TApiJobSharePostPayload {
  jobId: string;
  shareDetails: TShareRequest;
}

export interface TApiJobShareDeletePayload {
  jobId: string;
  shareId: string;
}

export type TApiJobShareAutologinTokenFetchPayload = TApiJobShareDeletePayload;
