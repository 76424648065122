import type { FC } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TaskDetails } from '../TaskDetails';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import { TaskModalContext } from '../../TaskModal';
import { TASK_PROMPT_TYPES } from '../../../constants/task';
import { useTaskCompleter } from '../../../hooks/use-task-completer';

export const TaskJobFindScout: FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'task.detailsView.jobFindScout',
  });

  const { jobLegacy, onModalClose } = useContext(TaskModalContext);
  const { trackCtaClick } = useTaskCompleter();

  const scoutSettingsUrl = jobLegacy
    ? `/dashboard/recruitment/jobs/${jobLegacy.uuid}/edit-scout`
    : '';

  const prompts = ['findScout', 'guidelines'].map(promptI18nKey => ({
    type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
    text: (
      <Trans
        components={{ a: <a /> }}
        i18nKey={`task.detailsView.jobFindScout.prompts.${promptI18nKey}`}
      />
    ),
  }));

  const onCtaClick = () => {
    trackCtaClick();
    onModalClose();
  };

  return (
    <TaskDetails
      isPauseButtonHidden
      prompts={prompts}
      suggestion={t('suggestion')}
      title={t('title')}
    >
      <LinkWrapper
        external
        label={t('cta')}
        onClick={onCtaClick}
        target="_blank"
        type="primary"
        url={scoutSettingsUrl}
      />
    </TaskDetails>
  );
};
