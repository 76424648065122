import React from 'react';
import ContentLoader from 'react-content-loader';

const AllJobsSkeleton = () => (
  <ContentLoader
    backgroundColor="rgb(231, 231, 232)"
    foregroundColor="rgb(207, 207, 208)"
    viewBox="0 0 300 600"
  >
    <rect x="0" y="4" rx="0" ry="0" width="10" height="10" />

    <rect x="20" y="0" rx="0" ry="0" width="50" height="18" />

    <rect x="0" y="25" rx="0" ry="0" width="50" height="6" />
    <rect x="60" y="25" rx="0" ry="0" width="30" height="6" />
    <rect x="110" y="25" rx="0" ry="0" width="25" height="6" />

    <rect x="0" y="35" rx="0" ry="0" width="30" height="15" />
    <rect x="60" y="35" rx="0" ry="0" width="40" height="15" />
    <rect x="110" y="35" rx="0" ry="0" width="20" height="15" />

    <rect x="0" y="55" rx="0" ry="0" width="300" height="1" />

    <rect x="0" y="62" rx="0" ry="0" width="20" height="6" />
    <rect x="25" y="60" rx="0" ry="0" width="30" height="10" />
    <rect x="60" y="60" rx="0" ry="0" width="30" height="10" />

    <rect x="245" y="62" rx="0" ry="0" width="20" height="6" />
    <rect x="270" y="60" rx="0" ry="0" width="30" height="10" />

    <rect x="0" y="85" rx="0" ry="0" width="25" height="6" />
    <rect x="0" y="95" rx="0" ry="0" width="40" height="4" />
    <rect x="210" y="93" rx="0" ry="0" width="50" height="6" />
    <rect x="265" y="93" rx="0" ry="0" width="40" height="6" />

    <rect x="20" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="50" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="80" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="110" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="140" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="170" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="200" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="230" y="110" rx="0" ry="0" width="20" height="4" />
    <rect x="260" y="110" rx="0" ry="0" width="20" height="4" />

    <rect x="25" y="118" rx="0" ry="0" width="10" height="6" />
    <rect x="55" y="118" rx="0" ry="0" width="10" height="6" />
    <rect x="85" y="118" rx="0" ry="0" width="10" height="6" />
    <rect x="108" y="118" rx="0" ry="0" width="25" height="6" />
    <rect x="145" y="118" rx="0" ry="0" width="10" height="6" />
    <rect x="175" y="118" rx="0" ry="0" width="10" height="6" />
    <rect x="198" y="118" rx="0" ry="0" width="25" height="6" />
    <rect x="235" y="118" rx="0" ry="0" width="10" height="6" />
    <rect x="265" y="118" rx="0" ry="0" width="10" height="6" />

    <rect x="0" y="130" rx="0" ry="0" width="49" height="20" />
    <rect x="50" y="130" rx="0" ry="0" width="49" height="20" />
    <rect x="100" y="130" rx="0" ry="0" width="49" height="20" />
    <rect x="150" y="130" rx="0" ry="0" width="49" height="20" />
    <rect x="200" y="130" rx="0" ry="0" width="49" height="20" />
    <rect x="250" y="130" rx="0" ry="0" width="50" height="20" />

    <rect x="280" y="152" rx="0" ry="0" width="20" height="6" />

    <rect x="0" y="170" rx="0" ry="0" width="25" height="6" />
    <rect x="0" y="180" rx="0" ry="0" width="40" height="4" />
    <rect x="210" y="178" rx="0" ry="0" width="50" height="6" />
    <rect x="265" y="178" rx="0" ry="0" width="40" height="6" />

    <rect x="20" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="50" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="80" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="110" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="140" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="170" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="200" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="230" y="195" rx="0" ry="0" width="20" height="4" />
    <rect x="260" y="195" rx="0" ry="0" width="20" height="4" />

    <rect x="25" y="203" rx="0" ry="0" width="10" height="6" />
    <rect x="55" y="203" rx="0" ry="0" width="10" height="6" />
    <rect x="85" y="203" rx="0" ry="0" width="10" height="6" />
    <rect x="108" y="203" rx="0" ry="0" width="25" height="6" />
    <rect x="145" y="203" rx="0" ry="0" width="10" height="6" />
    <rect x="175" y="203" rx="0" ry="0" width="10" height="6" />
    <rect x="198" y="203" rx="0" ry="0" width="25" height="6" />
    <rect x="235" y="203" rx="0" ry="0" width="10" height="6" />
    <rect x="265" y="203" rx="0" ry="0" width="10" height="6" />

    <rect x="0" y="215" rx="0" ry="0" width="49" height="20" />
    <rect x="50" y="215" rx="0" ry="0" width="49" height="20" />
    <rect x="100" y="215" rx="0" ry="0" width="49" height="20" />
    <rect x="150" y="215" rx="0" ry="0" width="49" height="20" />
    <rect x="200" y="215" rx="0" ry="0" width="49" height="20" />
    <rect x="250" y="215" rx="0" ry="0" width="50" height="20" />

    <rect x="280" y="237" rx="0" ry="0" width="20" height="6" />
  </ContentLoader>
);

export default AllJobsSkeleton;
