import React from 'react';
import { Icon } from '@reverse-hr/pattern-library';

const Heading = ({ modifier, icon, classes, tag, children }) => {
  const CustomTag = `${tag}`;
  return (
    <div className={`c-heading c-heading--${tag} ${modifier}`}>
      {!!icon && (
        <span className="c-heading__icon">
          <Icon name={icon} />
        </span>
      )}
      <CustomTag className={`c-heading__title ${classes}`}>
        {children}
      </CustomTag>
    </div>
  );
};

Heading.defaultProps = {
  tag: 'h1',
  modifier: '',
  icon: null,
  classes: '',
};

export default Heading;
