import type { FC } from 'react';
import React, { useContext, useState } from 'react';
import { TaskDetails } from '../TaskDetails';
import { useTaskCompleter } from '../../../hooks/use-task-completer';
import { Action } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import { TaskModalContext } from '../../TaskModal';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_PROMPT_TYPES,
} from '../../../constants/task';

export const TaskJobSendProfilingMeetingRecapEmail: FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'task.detailsView.jobSendProfilingMeetingRecapEmail',
  });

  const { t: tCommon } = useTranslation();

  const { job, task } = useContext(TaskModalContext);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const { completeTask } = useTaskCompleter(setIsErrorAlertVisible);

  const customerFullName = job
    ? tCommon('common.formatting.fullName', job.client.referent)
    : '';

  const prompts = task
    ? [
        {
          type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
          text: (
            <TaskJobSendProfilingMeetingRecapEmailPromptShareEmployerView
              customerFullName={customerFullName}
            />
          ),
        },
        ...task.prompts,
        {
          type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
          text: t('prompts.customizeEmail'),
        },
        {
          type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
          text: t('prompts.shareMiroBoard'),
        },
      ]
    : [];

  return task ? (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={prompts}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
      suggestion={t('suggestion')}
      title={t('title', { customerFullName })}
    >
      <Action onClick={completeTask} label={t('cta')} />
    </TaskDetails>
  ) : null;
};

interface TTaskJobSendProfilingMeetingRecapEmailPromptShareEmployerViewProps {
  customerFullName: string;
}

const TaskJobSendProfilingMeetingRecapEmailPromptShareEmployerView: FC<
  TTaskJobSendProfilingMeetingRecapEmailPromptShareEmployerViewProps
> = ({ customerFullName }) => {
  const { setAlternativeView } = useContext(TaskModalContext);

  const onOpenShareEmployerViewLinkClick = () =>
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.SHARE_EMPLOYER_VIEW);

  return (
    <Trans
      i18nKey="task.detailsView.jobSendProfilingMeetingRecapEmail.prompts.shareEmployerViewLink"
      values={{ customerFullName }}
    >
      0
      <a
        className="u-underline"
        tabIndex={0}
        role="button"
        onClick={onOpenShareEmployerViewLinkClick}
      >
        1
      </a>
    </Trans>
  );
};
