import { Icon } from '@reverse-hr/pattern-library';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { NOTIFICATION_ALERT_STATES } from '../../constants/notifications';

export const NotificationAlert = ({
  children,
  className,
  closeDelay = 4000,
  isVisible,
  onClose,
  status,
}) => {
  const wrapperClassNames = classNames('c-notification-alert', className, {
    'c-notification-alert--visible': isVisible,
    'c-notification-alert--positive':
      status === NOTIFICATION_ALERT_STATES.POSITIVE,
    'c-notification-alert--warning':
      status === NOTIFICATION_ALERT_STATES.WARNING,
    'c-notification-alert--negative':
      status === NOTIFICATION_ALERT_STATES.NEGATIVE,
  });

  useEffect(() => {
    if (isVisible) {
      setTimeout(onClose, closeDelay);
    }
  }, [isVisible]);

  return (
    <div className={wrapperClassNames}>
      <Icon name={STATUS_TO_ICON_MAP[status]} />
      {children}
    </div>
  );
};

const STATUS_TO_ICON_MAP = {
  [NOTIFICATION_ALERT_STATES.POSITIVE]: 'icn-confirm-24px',
  [NOTIFICATION_ALERT_STATES.WARNING]: 'icn-alert-24px',
  [NOTIFICATION_ALERT_STATES.NEGATIVE]: 'icn-off-outline-close-24px',
};
