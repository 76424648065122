import React from 'react';
import { Trans } from 'react-i18next';

export const SplashLoader = () => (
  <div className="c-splash-loader">
    <div className="c-splash-loader__column">
      <p className="c-splash-loader__text">
        <Trans i18nKey="loaderTitle" />
      </p>

      <div className="c-splash-loader__circle c-splash-loader__circle--left">
        <div className="c-splash-loader__semi-arc c-splash-loader__semi-arc--quarter">
          <div className="c-splash-loader__semi-arc c-splash-loader__semi-arc--dashed"></div>
        </div>
      </div>
    </div>

    <div className="c-splash-loader__column">
      <div className="c-splash-loader__circle c-splash-loader__circle--center">
        <div className="c-splash-loader__semi-arc">
          <div className="c-splash-loader__semi-arc">
            <div className="c-splash-loader__semi-arc">
              <div className="c-splash-loader__semi-arc c-splash-loader__semi-arc--quarter">
                <div className="c-splash-loader__semi-arc">
                  <div className="c-splash-loader__semi-arc c-splash-loader__semi-arc--dashed"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-splash-loader__finder"></div>
        <div className="c-splash-loader__finder"></div>
        <div className="c-splash-loader__finder"></div>
      </div>
    </div>

    <div className="c-splash-loader__column">
      <div className="c-splash-loader__circle c-splash-loader__circle--right">
        <div className="c-splash-loader__semi-arc c-splash-loader__semi-arc--double"></div>
      </div>
    </div>
  </div>
);
