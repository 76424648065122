import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';

import RouterSwitch from './components/RouterSwitch';
import SpriteSVG from './components/SpriteSVG';
import { SpriteSVG as PatternLibrarySpriteSVG } from '@reverse-hr/pattern-library';
import Notifications from './components/Notifications';
import { ROUTER_BASENAME } from './constants/app';

const App = () => {
  const initialize = useStoreActions(actions => actions.initialize);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <>
      <BrowserRouter basename={ROUTER_BASENAME}>
        <div className="container">
          <RouterSwitch />
        </div>
      </BrowserRouter>
      <SpriteSVG />
      <PatternLibrarySpriteSVG />
      <Notifications />
    </>
  );
};

export default App;
