import React from 'react';
import ContentLoader from 'react-content-loader';

export const MeetingListSkeleton = () => (
  <div className="c-meeting-list" data-testid="c-meeting-list-skeleton">
    {new Array(2).fill('').map((_, groupIdx) => (
      <div className="u-mb-space-32" key={groupIdx}>
        <ContentLoader
          height={36}
          width={94}
          backgroundColor="RGB(var(--grey-10))"
          foregroundColor="RGB(var(--grey-20))"
        >
          <rect x="0" y="0" rx="16" ry="16" height="36" width="94" />
        </ContentLoader>

        {new Array(4).fill('').map((_, itemIdx) => (
          <div key={itemIdx}>
            <div className="u-flex u-flex-item-center u-py-space-24">
              <div className="u-ml-space-64">
                <ContentLoader
                  height={32}
                  width={60}
                  backgroundColor="RGB(var(--grey-10))"
                  foregroundColor="RGB(var(--grey-20))"
                >
                  <rect x="0" y="0" rx="6" ry="6" height="12" width="60" />
                  <rect x="0" y="20" rx="6" ry="6" height="12" width="60" />
                </ContentLoader>
              </div>

              <div className="u-mx-space-48">
                <ContentLoader
                  height={24}
                  width={24}
                  backgroundColor="RGB(var(--grey-10))"
                  foregroundColor="RGB(var(--grey-20))"
                >
                  <rect x="0" y="0" height="24" width="24" />
                </ContentLoader>
              </div>

              <ContentLoader
                height={12}
                width={60}
                backgroundColor="RGB(var(--grey-10))"
                foregroundColor="RGB(var(--grey-20))"
              >
                <rect x="0" y="0" rx="6" ry="6" height="12" width="60" />
              </ContentLoader>

              <ContentLoader
                height={32}
                width={75}
                style={{ marginLeft: 'auto' }}
                backgroundColor="RGB(var(--grey-10))"
                foregroundColor="RGB(var(--grey-20))"
              >
                <rect x="0" y="0" height="32" width="75" />
              </ContentLoader>
            </div>

            <ContentLoader
              height={1}
              width="100%"
              backgroundColor="RGB(var(--grey-10))"
              foregroundColor="RGB(var(--grey-20))"
            >
              <rect x="0" y="0" height="1" width="100%" />
            </ContentLoader>
          </div>
        ))}
      </div>
    ))}
  </div>
);
