import React from 'react';
import { useTranslation } from 'react-i18next';
import { Parser } from 'html-to-react';
import { formatDate } from '../helpers/utils';

const Email = ({ subject, body, from, to, cc, date, modifier, selected }) => {
  const { t } = useTranslation();
  const htmlParser = new Parser();

  return (
    <div
      data-testid="email-container"
      className={`c-email ${modifier} ${
        !selected ? 'c-email--inactive' : 'c-email--selected'
      }`}
    >
      <div className="c-email__header">
        <div className="c-email__who">
          <div data-testid="email-from" className="c-email__sender">
            <span className="c-email__who-label">
              {t('activity.email.from')}
            </span>
            {from.map((f, i) => {
              const isLast = i === from.length - 1;
              return (
                <span key={`${f.email}-${i}`}>
                  <span className="c-email__who-value">
                    {f.name ? f.name : `${f.email}${!isLast ? ', ' : ''}`}
                  </span>
                  <span className="c-email__who-value light">
                    {f.name ? ` <${f.email}>${!isLast ? ', ' : ''}` : null}
                  </span>
                </span>
              );
            })}
          </div>
          {!!to && (
            <div data-testid="email-to" className="c-email__recipient">
              <span className="c-email__who-label">
                {t('activity.email.to')}
              </span>
              {to.map((t, i) => {
                const isLast = i === to.length - 1;
                return (
                  <span key={`${t.email}-${i}`}>
                    <span className="c-email__who-value">
                      {t.name ? t.name : `${t.email}${!isLast ? ', ' : ''}`}
                    </span>
                    <span className="c-email__who-value light">
                      {t.name ? ` <${t.email}>${!isLast ? ', ' : ''}` : null}
                    </span>
                  </span>
                );
              })}
              <span className="c-email__who-value">
                {to.name ? to.name : to.email}
              </span>
            </div>
          )}
          {!!cc && (
            <div data-testid="email-cc" className="c-email__recipient">
              <span className="c-email__who-label">
                {t('activity.email.cc')}
              </span>
              {cc.map((c, i) => {
                const isLast = i === cc.length - 1;
                return (
                  <span key={`${c.email}-${i}`}>
                    <span className="c-email__who-value">
                      {c.name ? c.name : `${c.email}${!isLast ? ', ' : ''}`}
                    </span>
                    <span className="c-email__who-value light">
                      {c.name ? ` <${c.email}>${!isLast ? ', ' : ''}` : null}
                    </span>
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <div data-testid="email-date" className="c-email__when">
          <p className="c-email__when-date">
            {formatDate(new Date(date.date), 'd MMMM Y')}
          </p>
          <p className="c-email__when-time">
            {formatDate(new Date(date.date), 'HH:mm')}
          </p>
        </div>
      </div>

      <div className="c-email__body">
        <p data-testid="email-subject" className="c-email__subject">
          {subject}
        </p>
        <div data-testid="email-body" className="c-email__body-text">
          {htmlParser.parse(body)}
        </div>
      </div>
    </div>
  );
};

Email.defaultProps = {
  modifier: '',
  body: '',
  subject: '',
  date: {
    date: new Date(),
  },
  from: [
    {
      email: '',
    },
  ],
};

export default Email;
