import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from '@reverse-hr/pattern-library';
import type {
  TShareEmployerViewRecipient,
  TShareEmployerViewRecipientToRemove,
} from './ShareEmployerView';
import classNames from 'classnames';

interface TShareEmployerViewRecipientProps {
  email: TShareEmployerViewRecipient['email'];
  fullName: TShareEmployerViewRecipient['fullName'];
  onRemoveRecipientClick?: (
    recipient: TShareEmployerViewRecipientToRemove,
  ) => void;
  shareId: TShareEmployerViewRecipient['id'];
}

export const ShareEmployerViewRecipient: FC<
  TShareEmployerViewRecipientProps
> = ({ email, fullName, onRemoveRecipientClick, shareId }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'modals.shareEmployerView.manageAccesses',
  });

  const isMainRecipient = !onRemoveRecipientClick;

  const onRemoveClick = () => {
    if (!onRemoveRecipientClick) {
      return;
    }

    onRemoveRecipientClick({ fullName, shareId });
  };

  return (
    <li className="c-share-employer-view-recipient">
      <ShareEmployerViewRecipientField
        className="c-share-employer-view-recipient__field--email"
        label={t('emailLabel', { context: isMainRecipient ? 'client' : null })}
      >
        <p className="c-share-employer-view-recipient__value">{email}</p>
      </ShareEmployerViewRecipientField>

      <ShareEmployerViewRecipientField
        className="c-share-employer-view-recipient__field--name"
        label={t('fullNameLabel')}
      >
        <p className="c-share-employer-view-recipient__value">{fullName}</p>
      </ShareEmployerViewRecipientField>

      {isMainRecipient ? (
        <Action
          modifier="c-share-employer-view-recipient__remove"
          label={t('removeRecipientCta', { context: 'disabled' })}
          disabled
          size="small"
          type="simple-text"
        />
      ) : (
        <Action
          modifier="c-share-employer-view-recipient__remove"
          label={t('removeRecipientCta')}
          icon="icn-trash-full-24px"
          size="small"
          type="simple-text"
          iconPosition="right"
          onClick={onRemoveClick}
        />
      )}
    </li>
  );
};

interface TShareEmployerViewRecipientFieldProps {
  children: ReactNode;
  className?: string;
  label: string;
}

const ShareEmployerViewRecipientField: FC<
  TShareEmployerViewRecipientFieldProps
> = ({ children, className, label }) => {
  const wrapperClassNames = classNames(
    'c-share-employer-view-recipient__field',
    className,
  );

  return (
    <div className={wrapperClassNames}>
      <p className="c-share-employer-view-recipient__label">{label}</p>
      {children}
    </div>
  );
};
