import React from 'react';
import Multiselect from '../Multiselect';
import { useTranslation } from 'react-i18next';

export const JobHeaderActionMenu = ({
  closeJobUrl,
  employerViewJobUrl,
  houndJobUrl,
  miroUrl,
  isJobFrozen,
  isProfilingMeetingDefined,
  isPresentationMeetingDefined,
  onAssignJobClick,
  onFreezeJobClick,
  onSetProfilingMeetingDateClick,
  onSetPresentationMeetingDateClick,
  reverseJobUrl,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'job.header.actionMenu',
  });

  const options = [
    {
      label: t('actions'),
      options: [
        {
          label: t('setProfilingMeetingDate', {
            context: isProfilingMeetingDefined ? 'edit' : null,
          }),
          onClick: onSetProfilingMeetingDateClick,
        },
        {
          label: t('setPresentationMeetingDate', {
            context: isPresentationMeetingDefined ? 'edit' : null,
          }),
          onClick: onSetPresentationMeetingDateClick,
        },
        {
          label: t('freezeJob', {
            context: isJobFrozen ? 'off' : 'on',
          }),
          onClick: onFreezeJobClick,
        },
        {
          label: t('assignJob'),
          onClick: onAssignJobClick,
        },
        {
          label: t('link', {
            url: closeJobUrl,
            target: '_reverse',
            content: t('closeJob'),
          }),
        },
      ],
    },
    {
      label: t('links'),
      options: [
        {
          label: t('link', {
            url: houndJobUrl,
            target: '_hound',
            content: t('openOnHound'),
          }),
        },
        {
          label: t('link', {
            url: employerViewJobUrl,
            target: '_blank',
            content: t('openOnEmployerView'),
          }),
        },
        {
          label: t('link', {
            url: reverseJobUrl,
            target: '_reverse',
            content: t('openOnReverse'),
          }),
        },
        ...(!!miroUrl
          ? [
              {
                label: t('link', {
                  url: miroUrl,
                  target: '_reverse',
                  content: t('openOnMiro'),
                }),
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <div className="c-job-header__select">
      <Multiselect
        options={options}
        menuPlacement="bottom"
        placeholder={t('placeholder')}
      />
    </div>
  );
};
