export const utilityGetJobCurrentPhaseClassName = phases => {
  return phases.reduce((currentPhaseClassName, phase) => {
    const phaseClassName = JOB_PHASES_CLASS_NAMES[phase.name];
    const isPhaseToDo = phase.current_step === 0;
    const isPhaseInProgress = !isPhaseToDo && phase.current_step < phase.steps;
    const isPhaseCompleted = phase.current_step === phase.steps;

    if (isPhaseCompleted || isPhaseInProgress) {
      return phaseClassName;
    }

    return currentPhaseClassName;
  }, null);
};

const JOB_PHASES = {
  PROFILING: 'Profiling',
  HUNTING: 'Hunting',
  PRESENTING: 'Presenting',
  SCOUT_INTERVIEWING: 'Scout Interviewing',
  CLIENT_INTERVIEWING: 'Client Interviewing',
  DEALING: 'Dealing',
  ONBOARDING: 'Onboarding',
};

const JOB_PHASES_CLASS_NAMES = {
  [JOB_PHASES.PROFILING]: 'profiling',
  [JOB_PHASES.HUNTING]: 'hunting',
  [JOB_PHASES.PRESENTING]: 'presenting',
  [JOB_PHASES.SCOUT_INTERVIEWING]: 'scout-interviewing',
  [JOB_PHASES.CLIENT_INTERVIEWING]: 'client-interviewing',
  [JOB_PHASES.DEALING]: 'dealing',
  [JOB_PHASES.ONBOARDING]: 'onboarding',
};
