import clientInterviewsData from './i18n/client-interviews.json';
import commonData from './i18n/common.json';
import errorsData from './i18n/errors.json';
import jobData from './i18n/job.json';
import modalsData from './i18n/modals.json';
import offerDetailsData from './i18n/offer-details.json';
import taskData from './i18n/task.json';

const { clientInterviews } = clientInterviewsData;
const { common } = commonData;
const { errors } = errorsData;
const { job } = jobData;
const { modals } = modalsData;
const { offerDetails } = offerDetailsData;
const { task } = taskData;

const translations = {
  en: {
    translation: {
      suggestion: 'Tip',
      error: {
        general: 'There is a problem',
        subtitle: 'Try to reload the page or contact the IT team',
      },
      jobs: {
        error: {
          title: 'There is a problem',
          text: 'Jobs have not been loaded correctly',
        },
      },
      send: 'Send',
      sending: 'Sending...',
      loaderTitle: 'Jarvis is <strong>loading</strong>',
      loading: 'Loading...',
      close: 'Close',
      starred: 'Starred',
      'not-starred': 'Not Starred',
      feedback: {
        bingo: {
          title: '<strong>Congratulations {{ name }}!</strong><br>',
          subtitle: 'Thanks to you {{ name }} has been chosen',
        },
        declined: {
          title: '<strong>Limits are made to be surpassed</strong><br>',
          subtitle: "Move forward {{ name }}, let's do the next activity",
        },
      },
      tabs: {
        tasks: 'Tasks',
        notes: 'All notes for the job',
        activities: 'Activities',
        interviews: 'Interviews and Meetings',
      },
      task: {
        'prompt-title': 'I suggest you:',
        postponed_at: {
          label: 'Show me this task again on: ',
          invalid_date: 'You cannot postpone this task in the past!',
        },
        reactivate: 'Reactivate task',
        paused_at: 'Paused at ',
        ...task,
      },
      'all-jobs': {
        title: 'All jobs',
        'job-header': {
          success_fee: 'SUCCESS FEE <strong>{{ success_fee }}</strong>',
          code: "Published on <strong>{{ date }}</strong> - <a target='_reverse' href='https://app.r-everse.com/recruitment/job/{{jobId}}'>{{ code }}</a>",
          frozen: {
            label: 'Frozen job',
            cta: 'Reactivate job',
          },
          closed: 'Closed',
          managed_by: 'Managed by <strong>{{ name }}</strong>',
          stale_label:
            'Job has been stalling for <strong>{{ stale }} days</strong>',
        },
        filters: {
          order: {
            title: 'Order by',
            publish_date: 'Publish date',
            job_state_progress: 'Closer to Bingo',
            stale: 'Job is stalling',
            placeholder: 'Filter by...',
          },
        },
        stats: {
          count: '# of jobs',
          bingo_30: 'Bingo 30',
          bingo_60: 'Bingo 60',
          bingo_90: 'Bingo 90',
          jarvis_usage: '% Jarvis',
        },
      },
      job: {
        header: {
          ...job.header,
          code: "Published on {{ date }} - <a target='_reverse' href='{{ reverseJobUrl }}'>{{ code }}</a>",
          external_links:
            "See job on: <a href='{{ houndListUrl }}' target='_hound'>Hound</a>, <a href='{{ pipedriveUrl }}' target='_pipedrive'>Pipedrive</a>",
          assignation: {
            label: 'Assign this job to:',
            cta: 'Assign to a headhunter',
          },
          customer_link: {
            label: 'Get the Employer View link to share with the client',
            label_copied:
              'The link <strong>was just copied</strong> to your clipboard',
            cta: 'Copy link',
            copied: 'Close',
            link_copied: 'link copied',
          },
        },
        meetingList: job.meetingList,
        horserace: {
          expand: 'Expand',
          unexpand: 'Close',
          'job-opening-date': 'Job publish date',
          'starting-day': 'Day <strong>0</strong>',
        },
        error: {
          title: 'There is a problem',
          text: 'This job does not exist or was not loaded correctly',
        },
        numbers: {
          days: 'Days',
          hunted: 'Hunted',
          applicants: 'Applicants',
          applicants_part: {
            evaluated: 'Evaluated',
            unevaluated: 'Unevaluated',
          },
          beforeJobInterview: 'Before job int.',
          beforeJobInterview_part: {
            notInterested: 'Not interested',
            agreed: 'Agreed to Job Int',
          },
          interestedBut: 'Interested but',
          interestedBut_part: {
            brand: 'Brand',
            role: 'Role',
            compensation: 'Salary',
            location: 'Location',
          },
          interested: 'Interested',
          approved: 'Approved',
          presented: 'Presented',
          feedbackCv: 'Feedback CV',
          feedbackCv_part: {
            waiting: 'Waiting for feedback',
            notInteresting: 'Not interesting',
            interesting: 'Interesting',
          },
          scout: 'Scout',
          scout_part: {
            draft: 'Report in draft',
            published: 'Report Published',
          },
          firstInterview: 'First interview',
          intoConsideration: 'Into consideration',
          additionalInterviews: 'Additional interv.',
          offers: 'Offers',
          offers_part: {
            incoming: 'Incoming',
            made: 'Made',
            declined: 'Declined',
          },
          bingo: 'Bingo',
        },
      },
      activity: {
        today: 'Today',
        yesterday: 'Yesterday',
        days: '{{ days }} days ago',
        email: {
          to: 'To: ',
          from: 'From: ',
          cc: 'Cc: ',
        },
      },
      note: {
        label: 'Add a personal note',
        placeholder: 'Write a note...',
        added: 'Note added to favorites',
        'lasts-from-job': 'Latest notes for job {{ jobTitle }}',
        readmore: 'Read more',
        readless: 'Read less',
        save: 'Save note',
        update: 'Update note',
        remove: 'Remove note',
        title: 'Note title',
        'title-note': 'Write a title to find the note in the future',
        'visible-to-customer': 'Visible to customer',
        empty: {
          title: 'Scripta manent!',
          text: 'Write a note when you have info worth being tracked.',
        },
        notEmpty: 'Body of the note cannot be empty',
        generalError: 'There was en error saving the note, please try again',
      },
      validation: {
        email: 'One or more email addresses are not valid',
        required: 'This field is required',
        notEmpty: 'This field cannot be empty',
        url: 'The provided URL is not valid',
      },
      notifications: {
        'completed-task': 'Task <strong>completed</strong>',
        'completed-tasks': '{{ number }} tasks <strong>completed</strong>',
        'new-task': 'You have a <strong>new task</strong>',
        'new-tasks': 'You have <strong>{{ number }} new tasks</strong>',
        'postponed-task':
          'The task was <strong>postponed to {{ date }}</strong>',
      },
      splash: {
        title: 'Hello!',
        text: "I'm loading your tasks...",
      },
      tasks: {
        hot: 'Hot',
        todo: 'To do',
        paused: 'Paused',
        title: 'Tasks',
        empty: {
          title: 'There are no tasks here',
          text: "If it's an old job then close it on Reverse, otherwise they'll appear very soon ;-)",
        },
        error: {
          title: 'There was a problem',
          text: 'Tasks could not be loaded correctly',
        },
      },
      activities: {
        empty: {
          title: 'There are no activities for this job',
          text: "From the moment you'll start working on it you'll see everything you did",
        },
      },
      clientInterviews,
      common,
      errors,
      modals,
      offerDetails,
    },
  },
};

export default translations;
