import React from 'react';
import Notice from './Notice';
import { error } from '../helpers/default-values';
import type { FallbackRender } from '@sentry/react';

const Error: FallbackRender = () => {
  const { title, text } = error();
  return (
    <div className="c-notice--error-general--wrapper">
      <Notice type="error-general" title={title} text={text} />
    </div>
  );
};

export default Error;
