import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferDetailsFormBenefitsItem } from './OfferDetailsFormBenefitsItem';
import { OfferDetailsFormContext } from './OfferDetailsForm';
import { OfferDetailsFormField } from './OfferDetailsFormField';

export const OfferDetailsFormBenefits = () => {
  const { t } = useTranslation();
  const { formData, job } = useContext(OfferDetailsFormContext);

  const genericBonusPercentage = !!formData.bonusAmount
    ? job.bonusValuePercentage
    : job.bonusValuePercentageGeneric;

  return (
    <div className="c-offer-details-form__section c-offer-details-form-benefits">
      <h3 className="c-offer-details-form__box-title">
        {t('offerDetails.benefits.title')}
      </h3>

      <OfferDetailsFormBenefitsItem
        details={{
          label: t('offerDetails.benefits.carValue'),
          value: t('common.formatting.currency', {
            amount: job.carValue,
            currency: job.currencySymbol,
          }),
        }}
        fieldKey="includesCompanyCar"
        id="offer-details-car"
        toggleSupportText={t('offerDetails.benefits.carLabel')}
      />

      <OfferDetailsFormBenefitsItem
        details={{
          label: t('offerDetails.benefits.healthInsuranceValue'),
          value: t('common.formatting.percentage', {
            value: job.healthInsuranceValuePercentage,
          }),
        }}
        id="offer-details-health-insurance"
        fieldKey="includesHealthInsurance"
        toggleSupportText={t('offerDetails.benefits.healthInsuranceLabel')}
      />

      <OfferDetailsFormBenefitsItem
        details={{
          label: t('offerDetails.benefits.includesStocks'),
          value: t('common.formatting.percentage', {
            value: job.stocksValuePercentage,
          }),
        }}
        id="offer-details-stocks"
        fieldKey="includesStocks"
        toggleSupportText={t('offerDetails.benefits.includesStocksLabel')}
      />

      <OfferDetailsFormBenefitsItem
        details={{
          label: t('offerDetails.benefits.accommodationValuePercentage'),
          value: t('common.formatting.percentage', {
            value: job.accommodationValuePercentage,
          }),
        }}
        id="offer-details-accommodation"
        fieldKey="isAccommodationActive"
        toggleSupportText={t('offerDetails.benefits.accommodationLabel')}
      >
        <OfferDetailsFormField
          fieldKey="accommodationMonths"
          label={t('offerDetails.benefits.accommodationValueMonths')}
          required
        />
      </OfferDetailsFormBenefitsItem>

      <div className="c-offer-details-form-benefits__generic-bonus">
        <OfferDetailsFormBenefitsItem
          id="offer-details-generic-bonus-enabled"
          fieldKey="isGeneralBonusActive"
          toggleSupportText={t('offerDetails.benefits.genericBonusLabel')}
        >
          <OfferDetailsFormField
            fieldKey="bonusAmount"
            label={t('offerDetails.benefits.genericBonusValue')}
          />

          <div className="c-offer-details-form-benefits-item__details">
            <p className="c-offer-details-form-benefits-item__label">
              {t('offerDetails.benefits.genericBonusPercentage')}
            </p>

            <p className="c-offer-details-form-benefits-item__value">
              {t('common.formatting.percentage', {
                value: genericBonusPercentage,
              })}
            </p>
          </div>
        </OfferDetailsFormBenefitsItem>

        {formData.isGeneralBonusActive && (
          <div className="c-offer-details-form-benefits__field">
            <OfferDetailsFormField
              className="c-offer-details-form-benefits__bonus-description"
              fieldKey="bonusDescription"
              label={t('offerDetails.benefits.genericBonusDescription')}
              required
            />
          </div>
        )}
      </div>
    </div>
  );
};
