import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormField } from '@reverse-hr/pattern-library';
import { useDebounce } from '../../helpers/hooks/use-debounce';
import { useClickOutside } from '../../helpers/hooks/use-click-outside';
import { USER_LOCALES } from '../../constants/user';

export const AddressField = ({
  id,
  initialValue,
  isRequired,
  label,
  placeholder,
  onChange = () => {},
  validateFn = () => true,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);
  const [searchTerm, setSearchTerm] = useState();
  const [addressPredictions, setAddressPredictions] = useState(null);
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const wrapperRef = useRef(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const debouncedSelectedPrediction = useDebounce(selectedPrediction, 100);

  const onInputBlur = useCallback(() => {
    const updatedInputValue = selectedPrediction
      ? selectedPrediction.description
      : initialValue;

    setInputValue(' ');
    setInputValue(updatedInputValue);
    setSearchTerm(null);
    setAddressPredictions(null);
  }, [selectedPrediction]);

  useClickOutside(wrapperRef.current, onInputBlur);

  const isPredictionsListVisible =
    !!addressPredictions && addressPredictions.length > 0;

  const onSearchTermChange = ({ value }) => setSearchTerm(value);

  const onPredictionClick = prediction => {
    onChange(prediction);
    setSelectedPrediction(prediction);
    setInputValue(prediction.description);
  };

  const updateAddressPredictions = async () => {
    try {
      const fetchedAddressPredictions = await fetchAddressPredictions(
        debouncedSearchTerm,
      );

      setAddressPredictions(fetchedAddressPredictions);
    } catch (error) {
      onInputBlur();
      setIsDisabled(true);
    }
  };

  useEffect(() => updateAddressPredictions(), [debouncedSearchTerm]);

  useEffect(() => {
    setAddressPredictions(null);
    setSearchTerm(null);
  }, [debouncedSelectedPrediction]);

  return (
    <div className="c-address-field" ref={wrapperRef}>
      <FormField
        type="input"
        onChange={onSearchTermChange}
        label={label}
        placeholder={placeholder}
        id={id}
        inputProps={{
          value: inputValue || '',
          readonly: isDisabled,
        }}
        validateFn={validateFn}
        required={isRequired}
        disabled={isDisabled}
      />

      {isPredictionsListVisible && (
        <ul className="c-address-field__predictions-list">
          {addressPredictions.map(prediction => (
            <li
              key={prediction.place_id}
              className="c-address-field__predictions-item"
              role="button"
              onClick={() => onPredictionClick(prediction)}
            >
              {prediction.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const fetchAddressPredictions = async input => {
  if (!input) {
    return;
  }

  try {
    const { google } = window;

    if (!google) {
      throw 'Google Maps not initialized';
    }

    const autocompleteService = new google.maps.places.AutocompleteService();
    const sessionToken = new google.maps.places.AutocompleteSessionToken();

    const { predictions } = await autocompleteService.getPlacePredictions({
      language: USER_LOCALES.EN,
      input,
      sessionToken,
    });

    return predictions;
  } catch (error) {
    throw new Error(error);
  }
};
