import React from 'react';
import ContentLoader from 'react-content-loader';

export const ClientInterviewFormSkeleton = () => (
  <div
    className="c-client-interview-form c-client-interview-form-skeleton"
    data-testid="c-client-interview-form-skeleton"
  >
    <div className="u-mb-space-56 c-client-interview-form-skeleton__title">
      <ContentLoader
        height="36"
        width="260"
        backgroundColor="RGB(var(--grey-10))"
        foregroundColor="RGB(var(--grey-20))"
      >
        <rect x="0" y="0" rx="16" ry="16" height="36" width="94" />
        <rect x="110" y="0" rx="16" ry="16" height="36" width="150" />
      </ContentLoader>
    </div>

    <div className="u-my-space-24">
      <ContentLoader
        height="72"
        width="665"
        backgroundColor="RGB(var(--grey-10))"
        foregroundColor="RGB(var(--grey-20))"
      >
        <rect x="0" y="20" height="52" width="229" />
        <rect x="253" y="20" height="52" width="420" />
      </ContentLoader>
    </div>

    <ContentLoader
      height="236"
      width="100%"
      backgroundColor="RGB(var(--grey-10))"
      foregroundColor="RGB(var(--grey-20))"
    >
      <rect x="0" y="0" height="236" width="100%" />
    </ContentLoader>
  </div>
);
