import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './translations';
import { format as dateFnsFormat } from 'date-fns';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en'],

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.services.formatter.add('datetime', (value, _, options) =>
  dateFnsFormat(value, options.format),
);

export default i18n;
