import React from 'react';
import ContentLoader from 'react-content-loader';

const JobsSkeleton = () => (
  <div className="c-nav-item c-nav-item--sub">
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ContentLoader
        backgroundColor="rgb(231, 231, 232)"
        foregroundColor="rgb(207, 207, 208)"
        height={34}
        viewBox="0 0 34 20"
        preserveAspectRatio={'none'}
      >
        <rect x="0" y="0" rx="0" ry="0" width="10" height="6" />
        <rect x="12" y="0" rx="0" ry="0" width="15" height="6" />
        <rect x="0" y="10" rx="0" ry="0" width="5" height="6" />
        <rect x="7" y="10" rx="0" ry="0" width="8" height="6" />
        <rect x="17" y="10" rx="0" ry="0" width="14" height="6" />
      </ContentLoader>
      <ContentLoader
        height={32}
        viewBox="0 0 28 14"
        backgroundColor="rgb(243, 243, 243)"
        foregroundColor="rgb(231, 231, 232)"
        preserveAspectRatio={'none'}
      >
        <rect x="0" y="0" rx="0" ry="0" width="26" height="5" />
      </ContentLoader>
    </div>
  </div>
);

export default JobsSkeleton;
