import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_BASENAME } from '../constants/app';
// import './style.scss'

const Logo = ({ modifier }) => {
  return (
    <div className={`c-logo ${modifier}`}>
      <Link className={'c-logo__anchor'} to={'/'}>
        <img
          src={`${ROUTER_BASENAME}/assets/images/jarvis-logo.svg`}
          alt="Jarvis"
        />
      </Link>
    </div>
  );
};

Logo.defaultProps = {
  modifier: '',
};

export default Logo;
