import { useMemo } from 'react';
import JobStateProgress, {
  JobStateModifiers,
} from '../../constants/task/jobStateProgress';
import { TASK_TYPES } from '../../constants/task';

/**
 * Step modifier factory
 * @param progress, {String}
 * @param type, {String}
 * @return {*}
 */
const stepModifierFactory = (progress, type) => {
  if (
    [
      TASK_TYPES.APPLICATION_SET_FIRST_DAY_ON_THE_JOB,
      TASK_TYPES.APPLICATION_NOTIFY_CLIENT_OF_OFFER_REFUSAL,
    ].includes(type)
  )
    return '';

  switch (progress) {
    case JobStateProgress.PROFILING:
      return JobStateModifiers[JobStateProgress.PROFILING];
    case JobStateProgress.HUNTING:
      return JobStateModifiers[JobStateProgress.HUNTING];
    case JobStateProgress.PRESENTING:
      return JobStateModifiers[JobStateProgress.PRESENTING];
    case JobStateProgress.SCOUT_INTERVIEWING:
      return JobStateModifiers[JobStateProgress.SCOUT_INTERVIEWING];
    case JobStateProgress.CLIENT_INTERVIEWING:
      return JobStateModifiers[JobStateProgress.CLIENT_INTERVIEWING];
    case JobStateProgress.DEALING:
      return JobStateModifiers[JobStateProgress.DEALING];
    case JobStateProgress.ONBOARDING:
      return JobStateModifiers[JobStateProgress.ONBOARDING];
    default:
      return '';
  }
};

/**
 * Utility that return correct step className (called modifier)
 * based on job_state_progress value of task
 *
 * @param progress, {String}, job_state_progress value returned from server
 * @param type, {String}, type value returned from server
 * @return {String} -> Memoized string
 */
const useStepModifier = (progress, type) => {
  return useMemo(() => stepModifierFactory(progress, type), [progress, type]);
};

export default useStepModifier;
