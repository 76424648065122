import { useEffect, useMemo, useState } from 'react';
import { apiJobInterviewsFetch } from '../../api/job.legacy';
import { selectorJobState } from '../../selectors/job/selectors';
import { useStoreState } from 'easy-peasy';
import { utilityGetMeetingsSortedByDate } from '../../utilities/meetings';
import { format, isFuture } from 'date-fns';
import {
  INTERVIEW_API_TYPE_TO_MEETING_TYPE_MAP,
  MEETING_TYPES,
} from '../../constants/job';

export const useMeetingList = () => {
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { job } = useStoreState(selectorJobState);

  const profilingMeeting = {
    scheduledAt: job.profilingMeetingScheduledAt,
    type: MEETING_TYPES.PROFILING_MEETING,
    key: MEETING_TYPES.PROFILING_MEETING,
  };

  const presentationMeeting = {
    scheduledAt: job.presentationMeetingScheduledAt,
    type: MEETING_TYPES.PRESENTATION_MEETING,
    key: MEETING_TYPES.PRESENTATION_MEETING,
  };

  const { futureMeetings, pastMeetings } = useMemo(
    () =>
      getMeetingsGroupedByMonth([
        ...interviews,
        profilingMeeting,
        presentationMeeting,
      ]),
    [interviews, profilingMeeting, presentationMeeting],
  );

  const isFutureMeetingsSectionVisible = futureMeetings.length > 0;
  const isPastMeetingsSectionVisible = Object.keys(pastMeetings).length > 0;

  const isListEmpty =
    !isFutureMeetingsSectionVisible &&
    !isPastMeetingsSectionVisible &&
    !job.profilingMeetingScheduledAt &&
    !job.presentationMeetingScheduledAt;

  const updateMeetingList = async () => {
    setIsLoading(true);

    const response = await apiJobInterviewsFetch(job.uuid);

    setInterviews(
      response.results.map(fetchedInterview => {
        const meetingType =
          INTERVIEW_API_TYPE_TO_MEETING_TYPE_MAP[fetchedInterview.type];

        const meetingKey =
          fetchedInterview.clientInterviewId ??
          `${meetingType}${fetchedInterview.applicationId}`;

        return {
          ...fetchedInterview,
          key: meetingKey,
          type: meetingType,
        };
      }),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading || !job.uuid) {
      return;
    }

    updateMeetingList();
  }, [job.uuid]);

  return {
    futureMeetings,
    pastMeetings,
    isLoading,
    isFutureMeetingsSectionVisible,
    isPastMeetingsSectionVisible,
    isListEmpty,
    updateMeetingList,
  };
};

const getMeetingsGroupedByMonth = meetings => {
  const sortedMeetings = utilityGetMeetingsSortedByDate({
    interviews: meetings,
  });

  return sortedMeetings.reduce(
    (outputMeetings, meeting) => {
      if (!meeting.scheduledAt) {
        return outputMeetings;
      }

      const meetingDate = new Date(meeting.scheduledAt.localDateTime);

      if (isFuture(meetingDate)) {
        return {
          ...outputMeetings,
          futureMeetings: [...outputMeetings.futureMeetings, meeting],
        };
      }

      const meetingDateKey = format(meetingDate, 'yyyy-MM');

      const dateGroupMeetings =
        outputMeetings.pastMeetings[meetingDateKey] || [];

      return {
        ...outputMeetings,
        pastMeetings: {
          ...outputMeetings.pastMeetings,
          [meetingDateKey]: [...dateGroupMeetings, meeting],
        },
      };
    },
    {
      futureMeetings: [],
      pastMeetings: {},
    },
  );
};
