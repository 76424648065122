import { authFetch } from '../utilities/session.legacy';

export const apiJobFetch = async jobId =>
  await authFetch({
    endpoint: `api/jarvis/jobs/${jobId}`,
    method: 'GET',
  });

export const apiV1JobFetch = async jobId =>
  await authFetch({
    endpoint: `api/v1/jarvis/jobs/${jobId}`,
    method: 'GET',
  });

export const apiJobUpdate = async ({ jobId, payload }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/jobs/${jobId}`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });

export const apiJobClientInterviewsFetch = async jobId =>
  await authFetch({
    endpoint: `api/v1/jarvis/jobs/${jobId}/client-interviews`,
    method: 'GET',
    query: {
      itemsPerPage: 9999,
    },
  });

export const apiJobInterviewsFetch = async jobId =>
  await authFetch({
    endpoint: `api/v1/jarvis/jobs/${jobId}/interviews`,
    method: 'GET',
    query: {
      itemsPerPage: 9999,
    },
  });

export const apiJobApplicationsFetch = async ({ jobId, itemsPerPage = 9999 }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/jobs/${jobId}/applications`,
    method: 'GET',
    query: {
      itemsPerPage,
    },
  });

export const apiJobNotePost = async (jobId, payload) =>
  await authFetch({
    endpoint: `api/jarvis/jobs/${jobId}/notes`,
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const apiJobNoteUpdate = async (jobId, noteId, payload) =>
  await authFetch({
    endpoint: `api/jarvis/jobs/${jobId}/notes/${noteId}`,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const apiJobNoteDelete = async (jobId, noteId) =>
  await authFetch({
    endpoint: `api/jarvis/jobs/${jobId}/notes/${noteId}`,
    method: 'DELETE',
  });

export const apiJobSendApplicationsToClientPost = async jobId =>
  await authFetch({
    endpoint: `api/v1/jarvis/jobs/${jobId}/send-applications-to-client`,
    method: 'POST',
  });
