import type { FC } from 'react';
import React, { useContext, useState } from 'react';
import { JraSlidesGeneration } from '../JraSlidesGeneration';
import { Loader } from '../Loader';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import { CurrentJobContext } from '../Providers/CurrentJobProvider';
import type { TJob } from '../../models/Job';
import { useTranslation } from 'react-i18next';

export const JraSlidesGenerationModalGeneration: FC = () => {
  const { t } = useTranslation();
  const [isSlidesLinkDisabled, setIsSlidesLinkDisabled] = useState(true);
  const { currentJob, setCurrentJob } = useContext(CurrentJobContext);

  const onSlidesGenerated = (updatedJob: TJob) => {
    setCurrentJob(updatedJob);
    setIsSlidesLinkDisabled(false);
  };

  return currentJob ? (
    <>
      <JraSlidesGeneration
        jobId={currentJob.id}
        onSlidesGenerated={onSlidesGenerated}
        presentationMeetingScheduledAt={
          currentJob.presentationMeetingScheduledAt
        }
      />

      <footer className="c-jra-slides-generation-modal__footer">
        {isSlidesLinkDisabled ? (
          <div className="c-jra-slides-generation-modal__loader">
            <Loader />
          </div>
        ) : (
          <LinkWrapper
            label={t('modals.jraSlidesGeneration.cta')}
            type="primary"
            external
            target="_blank"
            url={currentJob.presentationMeetingSlidesUrl ?? ''}
          />
        )}
      </footer>
    </>
  ) : null;
};
