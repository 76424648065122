// STATE
// Select all task state
export const baseTaskStateSelector = state => state.task;

// Select current task
export const taskSelector = state => state.task.task;

// Select error
export const taskErrorSelector = state => state.task.isError;

// Select loader
export const taskLoadingSelector = state => state.task.isLoading;

// ACTIONS
// Select all task actions
export const baseTaskActionsSelector = actions => actions.task;
