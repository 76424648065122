import { action, thunk, computed } from 'easy-peasy';

export default {
  // state
  notes: [],
  isLoading: true,
  isError: false,

  // actions
  setNotes: action((state, payload) => {
    state.notes = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setIsError: action((state, payload) => {
    state.isError = payload;
  }),

  // thunks
  createNote: thunk(
    async (
      actions,
      payload,
      { injections, getState, getStoreState, getStoreActions },
    ) => {
      const {
        notesService: { createNote },
      } = injections;
      const { title, body, job_id, job_state_progress, context } = payload;
      const { notes } = getState();
      return new Promise(async (resolve, reject) => {
        try {
          const createdNote = await createNote(job_id, {
            title,
            body,
            job_state_progress,
          });
          resolve(createdNote);
          if (context === 'job') {
            const {
              job: { jobNotes },
            } = getStoreState();
            getStoreActions().job.setJobNotes([createdNote, ...jobNotes]);
          } else {
            actions.setNotes([createdNote, ...notes]);
          }
        } catch (error) {
          console.warn(error);
          reject(error);
        }
      });
    },
  ),
  updateNote: thunk(async (actions, payload, { injections, getState }) => {
    const {
      notesService: { updateNote },
    } = injections;
    const { job_id } = payload;
    const { notes } = getState();
    const jobId = job_id;
    const noteId = payload.note_id;

    delete payload.note_id;
    delete payload.job_id;
    return new Promise(async (resolve, reject) => {
      try {
        const updatedNote = await updateNote(jobId, noteId, payload);
        resolve(updatedNote);
        if (notes && notes.length) {
          const updatedNotes = notes.map(note => {
            if (note.id === updatedNote.id) {
              return updatedNote;
            }
            return note;
          });
          actions.setNotes(updatedNotes);
        }
      } catch (error) {
        console.warn(error);
        reject(error);
      }
    });
  }),
  deleteNote: thunk(async (actions, payload, { injections, getState }) => {
    const {
      notesService: { deleteNote },
    } = injections;
    const { job_id } = payload;
    const { notes } = getState();
    try {
      await deleteNote(job_id, payload.note_id);
      const updatedNotes = notes.filter(note => note.id !== payload.note_id);
      actions.setNotes(updatedNotes);
    } catch (error) {
      console.error(error);
    }
  }),

  // computed
  favoriteNotes: computed(({ notes }) =>
    !!notes ? notes.filter(n => !!n.favorite) : [],
  ),
  normalNotes: computed(({ notes }) =>
    !!notes ? notes.filter(n => !n.favorite) : [],
  ),
};
