import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { TaskDetails } from '../TaskDetails';
import { TASK_MODAL_ALTERNATIVE_VIEWS } from '../../../constants/task';
import { useTranslation } from 'react-i18next';
import { Action } from '@reverse-hr/pattern-library';

export const TaskApplicationHandleClientInterviewOutcome = () => {
  const { t } = useTranslation();
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const { setAlternativeView, task } = useContext(TaskModalContext);

  const onNextStepCtaClick = () => {
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.CLOSING);
  };

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={task.prompts}
      title={null}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <Action
        onClick={onNextStepCtaClick}
        label={t(
          'task.detailsView.applicationHandleClientInterviewOutcome.cta',
        )}
        modifier="c-action--set-next-step"
      />
    </TaskDetails>
  );
};
