import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { Flipper, Flipped } from 'react-flip-toolkit';

import Heading from '../components/Heading';
import JobSummary from '../components/JobSummary';
import Scores from '../components/Scores';
import Filters from '../components/Filters';
import AllJobsSkeleton from '../components/skeletons/AllJobs';

import { allJobsFilters } from '../helpers/default-values';
import { getJobPhases, getHorserace } from '../helpers/job-utils';

const Jobs = ({ user }) => {
  const { t } = useTranslation();
  const filters = allJobsFilters();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [mappedJobs, setMappedJobs] = useState(null);

  // State
  const jobs = useStoreState(state => state.jobs.jobs);
  const orderedJobs = useStoreState(state => state.jobs.orderedJobs);
  const jobsOrder = useStoreState(state => state.jobs.jobsOrder);
  const stats = useStoreState(state => state.jobs.stats);
  const loading = useStoreState(state => state.jobs.isLoading);
  const error = useStoreState(state => state.jobs.isError);

  // Actions
  const getAllJobs = useStoreActions(actions => actions.jobs.fetchJobs);
  const updateJob = useStoreActions(actions => actions.job.updateJob);
  const setJobsOrder = useStoreActions(actions => actions.jobs.setJobsOrder);

  useEffect(() => {
    getAllJobs();
  }, [getAllJobs]);

  useEffect(() => {
    if (jobs && jobs.length && jobs[0].job_state_progress_index) {
      setFiltersVisible(true);
      setJobsOrder('stale');
    }
  }, [jobs, setJobsOrder]);

  useEffect(() => {
    if (orderedJobs && orderedJobs.length) {
      const mappedJobs = orderedJobs.map(job => {
        if (job.phases && job.phases.length) {
          const { phases } = getJobPhases(job.phases);
          job._phases = phases;
          if (job.applications && job.applications.length) {
            const { horserace } = getHorserace(job.applications, job.phases);
            job._applications = horserace;
          }
        }
        return job;
      });
      setMappedJobs(mappedJobs);
    }
  }, [orderedJobs]);

  const defrostJob = id => {
    updateJob({ id, frozen: false });
  };

  const handleFilterChange = filter => {
    setJobsOrder(filter.key);
  };

  return (
    <>
      {loading ? (
        <div className="c-tasks">
          <AllJobsSkeleton />
        </div>
      ) : error ? (
        <div className="c-tasks">Ops! There was an error!</div>
      ) : (
        <>
          {!stats ? (
            <div className="o-wrapper o-wrapper--vert-base-mid">
              <Heading
                tag="h1"
                icon="icn-job-24px"
                modifier="c-heading--spaceless u-mb-small"
              >
                {t('all-jobs.title')}
              </Heading>
            </div>
          ) : null}

          <div className="o-wrapper o-wrapper--jobs">
            {stats ? (
              <>
                <Scores modifier="u-mb-mid-large" items={stats} />
                <hr className="u-hr-sec20 u-mb-medium" />
              </>
            ) : null}

            {filtersVisible ? (
              <Filters
                modifier="c-filters--right-alignment"
                filters={filters}
                selected={jobsOrder}
                onFilterChange={handleFilterChange}
              />
            ) : null}

            {mappedJobs && !!mappedJobs.length ? (
              <Flipper flipKey={jobsOrder}>
                {mappedJobs.map((job, i) => (
                  <Flipped
                    key={`flipped-${job.id}`}
                    flipId={`flipped-${job.id}`}
                  >
                    <div>
                      <JobSummary
                        steps={job._phases}
                        horserace={{
                          applications: job._applications,
                          job: { publish_date: job.publish_date },
                        }}
                        numbers={job.numbers}
                        frozen={job.frozen}
                        // manager={job.manager ? job.manager : null}
                        stale={
                          jobsOrder === 'stale' ? job.days_of_stalling : null
                        }
                        onDefrost={() => defrostJob(job.id)}
                        infos={{
                          code: job.code,
                          position: job.title,
                          customer: job.customer.company_name,
                          date: job.publish_date,
                          fee: job.success_fee,
                          jobId: job.id,
                        }}
                      />
                    </div>
                  </Flipped>
                ))}
              </Flipper>
            ) : (
              <div></div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Jobs;
