import React, { useEffect, useState } from 'react';
import {
  FormField,
  RadioButton,
  SwitchToggle,
} from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { getValidation } from '../../helpers/utils';
import {
  INTERVIEW_TYPE_LIST,
  INTERVIEW_TYPES,
} from '../../constants/client-interviews';
import { AddressField } from '../AddressField';

export const ClientInterviewFormInterviewType = ({
  onChange,
  initialInterviewType,
  initialLocation = null,
  initialVideoCallUrl = null,
}) => {
  const [interviewType, setInterviewType] = useState(initialInterviewType);
  const [location, setLocation] = useState(initialLocation);
  const [videoCallUrl, setVideoCallUrl] = useState(initialVideoCallUrl);
  const [isVideoCallUrlInputVisible, setIsVideoCallUrlInputVisible] =
    useState(true);

  const {
    required: { validate: validateRequired },
    url: { validate: validateUrl },
  } = getValidation(['required', 'url']);

  const { t } = useTranslation(null, {
    keyPrefix: 'clientInterviews.form.fields',
  });

  const validateVideoCallUrl = value =>
    validateRequired(value) && validateUrl(value);

  const onInterviewTypeChange = radioInterviewType => {
    setInterviewType(radioInterviewType);

    if (radioInterviewType === INTERVIEW_TYPES.VIDEO_CALL) {
      return setLocation(null);
    }

    return setVideoCallUrl(null);
  };

  const toggleIsVideoCallUrlInputVisible = ({ target }) => {
    if (!target.checked) {
      setVideoCallUrl(null);
    }

    setIsVideoCallUrlInputVisible(target.checked);
  };

  const onVideCallUrlChange = ({ value }) => setVideoCallUrl(value);
  const onLocationChange = ({ description }) => setLocation(description);

  useEffect(() => {
    const isLocationValid =
      !!location || interviewType !== INTERVIEW_TYPES.IN_PERSON;

    const isVideCallUrl =
      !!videoCallUrl ||
      !isVideoCallUrlInputVisible ||
      interviewType !== INTERVIEW_TYPES.VIDEO_CALL;

    onChange({
      interviewType,
      location,
      videoCallUrl,
      isValid: isLocationValid && isVideCallUrl,
    });
  }, [interviewType, location, videoCallUrl, isVideoCallUrlInputVisible]);

  return (
    <div className="c-client-interview-form__meeting-type">
      <div className="c-client-interview-form__meeting-type-radio-group">
        {INTERVIEW_TYPE_LIST.map(radioInterviewType => (
          <RadioButton
            key={radioInterviewType}
            label={t(`interviewType.${radioInterviewType}`)}
            name="client-interview-meeting-type"
            id={`client-interview-meeting-type-${radioInterviewType}`}
            value={radioInterviewType}
            onChange={() => onInterviewTypeChange(radioInterviewType)}
            checked={radioInterviewType === interviewType}
          />
        ))}
      </div>

      {interviewType === INTERVIEW_TYPES.VIDEO_CALL ? (
        <>
          <SwitchToggle
            className="c-client-interview-form__url-toggle"
            id="client-interview-video-call-url-toggle"
            onChange={toggleIsVideoCallUrlInputVisible}
            supportText={t('videoCallUrl.toggle')}
            checked={isVideoCallUrlInputVisible}
          />

          {isVideoCallUrlInputVisible && (
            <FormField
              type="input"
              onChange={onVideCallUrlChange}
              label={t('videoCallUrl.label')}
              placeholder={t('videoCallUrl.placeholder')}
              id="client-interview-video-call-url"
              inputProps={{ value: videoCallUrl || '' }}
              validateFn={validateVideoCallUrl}
              required
            />
          )}
        </>
      ) : (
        <AddressField
          onChange={onLocationChange}
          label={t('location.label')}
          placeholder={t('location.placeholder')}
          id="client-interview-location"
          validateFn={validateRequired}
          isRequired
          initialValue={initialLocation}
        />
      )}
    </div>
  );
};
