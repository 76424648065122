import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const useHandleError = ({ i18nKeyPrefix, genericError }) => {
  const { t } = useTranslation(null, { keyPrefix: i18nKeyPrefix });
  const [handledError, setHandledError] = useState(null);

  const handleError = error => {
    const { errors, detail } = error;

    if (!!errors) {
      return setHandledError(t(errors[0].message, genericError));
    }

    if (!!detail) {
      return setHandledError(detail);
    }

    setHandledError(genericError);
  };

  return {
    handledError,
    setHandledError,
    handleError,
  };
};
