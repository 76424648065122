import { useEffect, useState } from 'react';
import {
  apiClientFeedbackAdditionalInterviewsUpdate,
  apiClientFeedbackIntoConsiderationUpdate,
  apiClientFeedbackOfferUpdate,
} from '../../../api/applications';
import { CLIENT_FEEDBACK_TYPES } from '../../../constants/applications';
import { useTranslation } from 'react-i18next';

export const useApplication = (application, setAlertError) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(null);

  const onClientFeedbackSubmit = async () => {
    try {
      const updateEndpoint =
        CLIENT_FEEDBACK_TYPE_TO_UPDATE_ENDPOINT_MAP[currentStep];

      if (!updateEndpoint) {
        return;
      }

      await updateEndpoint({ applicationId: application.id });
    } catch (error) {
      setAlertError(t('errors.generic'));
    }
  };

  useEffect(() => {
    setCurrentStep(getCurrentStep(application.clientFeedbacks));
  }, []);

  return {
    application,
    currentStep,
    onClientFeedbackSubmit,
    setCurrentStep,
  };
};

const getCurrentStep = clientFeedbacks => {
  const [lastClientFeedback] = clientFeedbacks.sort(
    (a, b) => new Date(b.leftAt) - new Date(a.leftAt),
  );

  return !!lastClientFeedback ? lastClientFeedback.type : null;
};

const CLIENT_FEEDBACK_TYPE_TO_UPDATE_ENDPOINT_MAP = {
  [CLIENT_FEEDBACK_TYPES.TAKEN_INTO_CONSIDERATION]:
    apiClientFeedbackIntoConsiderationUpdate,
  [CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_ADDITIONAL_INTERVIEWS]:
    apiClientFeedbackAdditionalInterviewsUpdate,
  [CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_AN_OFFER]: apiClientFeedbackOfferUpdate,
};
