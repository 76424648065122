import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { ShareEmployerViewRecipient } from './ShareEmployerViewRecipient';
import type {
  TShareEmployerViewRecipient,
  TShareEmployerViewRecipientToRemove,
} from './ShareEmployerView';

interface TShareEmployerViewRecipientsListProps {
  guestRecipients: TShareEmployerViewRecipient[];
  mainRecipient: TShareEmployerViewRecipient | null;
  onRemoveRecipientClick: (
    recipient: TShareEmployerViewRecipientToRemove,
  ) => void;
}

export const ShareEmployerViewRecipientsList: FC<
  TShareEmployerViewRecipientsListProps
> = ({ guestRecipients, mainRecipient, onRemoveRecipientClick }) => {
  const { t } = useTranslation();

  return (
    <section className="c-share-employer-view__section">
      <h3 className="c-share-employer-view__section-title">
        {t('modals.shareEmployerView.manageAccesses.title')}
      </h3>

      <ul className="c-share-employer-view__recipients-list">
        {mainRecipient && (
          <ShareEmployerViewRecipient
            email={mainRecipient.email}
            fullName={mainRecipient.fullName}
            shareId={mainRecipient.id}
          />
        )}

        {guestRecipients.map(guestRecipient => (
          <ShareEmployerViewRecipient
            email={guestRecipient.email}
            fullName={guestRecipient.fullName}
            key={guestRecipient.id}
            onRemoveRecipientClick={onRemoveRecipientClick}
            shareId={guestRecipient.id}
          />
        ))}
      </ul>
    </section>
  );
};
