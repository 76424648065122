import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import Sentry from '../sentry';
import { APP_LOGIN_URL } from '../constants/endpoints';

export default {
  // state
  user: {},
  isLoading: true,
  isError: false,

  // actions
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setIsError: action((state, payload) => {
    state.isError = payload;
  }),

  // thunks
  fetchUser: thunk(async (actions, payload, { injections }) => {
    const { userService } = injections;
    try {
      const user = await userService.getUser();
      if (user.id) {
        actions.setUser(user);
        window.jarvis_locale = user.locale;
        if (user.locale !== 'en') {
          i18next.changeLanguage(user.locale, (err, t) => {
            if (err) {
              console.warn('Something went wrong loading the locale', err);
            }
          });
        }
        Sentry.setUser(user);
        return user;
      } else {
        actions.setIsError(true);
        window.location.assign(APP_LOGIN_URL);
      }
    } catch (error) {
      actions.setIsError(true);
      window.location.assign(APP_LOGIN_URL);
    } finally {
      actions.setIsLoading(false);
    }
  }),
  setUserFirebaseToken: thunk(
    async (actions, payload, { getState, injections }) => {
      const { user: oldUser } = getState();
      const { userService } = injections;
      if (payload !== oldUser.jarvis_token) {
        try {
          const user = await userService.updateUser({ jarvis_token: payload });
          actions.setUser(user);
        } catch (error) {
          const err = new Error(`Firebase - User non updated - ${error}`);
          Sentry.captureException(err);
        }
      } else {
        console.debug('Firebase - Jarvis token non updated');
        Sentry.captureException('Firebase - Jarvis token non updated');
      }
    },
  ),
};
