import { Icon } from '@reverse-hr/pattern-library';
import React, { FC, HTMLAttributeAnchorTarget, ReactNode } from 'react';
import classNames from 'classnames';

interface TExternalLinkProps {
  children?: ReactNode;
  className?: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
}

export const ExternalLink: FC<TExternalLinkProps> = ({
  children,
  className,
  href,
  target = '_self',
}) => {
  const wrapperClassNames = classNames(className);

  return (
    <a className={wrapperClassNames} href={href} target={target}>
      {children}
      <Icon name="icn-external-link-24px" />
    </a>
  );
};
