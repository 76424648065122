import React, { FC, ReactNode, useEffect, useState } from 'react';
import type { TJob } from '../../../models/Job';
import { useStoreState } from 'easy-peasy';
import { apiJobFetch } from '../../../api/job';
import { selectorJobState } from '../../../selectors/job/selectors';

interface TCurrentJobContext {
  currentJob: TJob | null;
  isCurrentJobLoading: boolean;
  setCurrentJob: (updatedJob: TJob) => void;
}

export const CurrentJobContext = React.createContext<TCurrentJobContext>({
  currentJob: null,
  isCurrentJobLoading: false,
  setCurrentJob: () => {},
});

interface TCurrentJobProviderProps {
  children: ReactNode;
}

export const CurrentJobProvider: FC<TCurrentJobProviderProps> = ({
  children,
}) => {
  const [currentJob, setCurrentJob] = useState<TJob | null>(null);
  const [isCurrentJobLoading, setIsCurrentJobLoading] = useState(true);
  const { job } = useStoreState(selectorJobState);

  useEffect(() => {
    const fetchCurrentJob = async () => {
      if (!job.uuid || typeof job.uuid !== 'string') {
        return;
      }

      setIsCurrentJobLoading(true);

      try {
        const response = await apiJobFetch(job.uuid);

        if (!response) {
          return;
        }

        setCurrentJob(response);
      } catch (error) {
        setCurrentJob(null);
      } finally {
        setIsCurrentJobLoading(false);
      }
    };

    fetchCurrentJob();
  }, [job.uuid]);

  return (
    <CurrentJobContext.Provider
      value={{
        currentJob,
        isCurrentJobLoading,
        setCurrentJob,
      }}
    >
      {children}
    </CurrentJobContext.Provider>
  );
};
