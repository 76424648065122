import React from 'react';
import Navigation from './Navigation';
import Logo from './Logo';

const Sidebar = ({ navigation, loading, error, modifier }) => {
  return (
    <div className={`c-sidebar ${modifier}`}>
      <div className="c-sidebar__logo">
        <Logo />
      </div>

      <div className="c-sidebar__navigation">
        <Navigation {...navigation} loading={loading} error={error} />
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  modifier: '',
};

export default Sidebar;
