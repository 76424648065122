import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, Label } from '@reverse-hr/pattern-library';
import { OfferDetailsFormDocument } from './OfferDetailsFormDocument';
import { OfferDetailsFormContext } from './OfferDetailsForm';

export const OfferDetailsFormDateAndDocuments = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.dateAndDocuments',
  });

  const { formData, formErrors, onFormChange } = useContext(
    OfferDetailsFormContext,
  );

  const onDateChange = selectedDate => onFormChange({ madeOn: selectedDate });

  const onCommitmentLetterChange = value =>
    onFormChange({ commitmentLetter: value });

  const onResignationLetterChange = value =>
    onFormChange({ resignationLetter: value });

  return (
    <div className="c-offer-details-form__box">
      <h3 className="c-offer-details-form__box-title">{t('title')}</h3>

      <div className="c-offer-details-form__field">
        <Label
          modifier="c-offer-details-form__label"
          id="offer-details-offer-date"
          label={t('madeOnLabel')}
          required
        />

        <DateTimePicker
          id="offer-details-offer-date"
          className="c-offer-details-form__input"
          onChange={onDateChange}
          value={formData.madeOn}
          hasTimePicker={false}
          isSmallSize
          isRequired
          hasError={!!formErrors && !!formErrors.madeOn}
        />
      </div>

      <OfferDetailsFormDocument
        id="offer-details-commitment-letter"
        letter={formData.commitmentLetter}
        label={t('commitmentLetterLabel')}
        onChange={onCommitmentLetterChange}
      />

      <OfferDetailsFormDocument
        id="offer-details-resignation-letter"
        letter={formData.resignationLetter}
        label={t('resignationLetterLabel')}
        onChange={onResignationLetterChange}
      />
    </div>
  );
};
