import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { apiJobPresentationMeetingSlidesPost } from '../../api/job';
import type { TMeeting } from '../../models/Meeting';
import { differenceInDays } from 'date-fns';
import { Alert } from '@reverse-hr/pattern-library';
import type { TJob } from '../../models/Job';

interface TJraSlidesGenerationProps {
  jobId: string;
  onSlidesGenerated: (response: TJob) => void;
  presentationMeetingScheduledAt: TMeeting | null;
}

export const JraSlidesGeneration: FC<TJraSlidesGenerationProps> = ({
  jobId,
  onSlidesGenerated,
  presentationMeetingScheduledAt,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'modals.jraSlidesGeneration',
  });

  const [isGenerating, setIsGenerating] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const daysToPresentationMeeting = presentationMeetingScheduledAt
    ? differenceInDays(
        new Date(presentationMeetingScheduledAt.localDateTime),
        new Date(),
      )
    : 0;

  const isMissingPresentationMeetingAlertVisible =
    isGenerating && !presentationMeetingScheduledAt;

  const isUpcomingAlertVisible =
    isGenerating &&
    daysToPresentationMeeting > UPCOMING_PRESENTATION_MEETING_DAYS;

  const isAlertVisible =
    hasErrors ||
    isMissingPresentationMeetingAlertVisible ||
    isUpcomingAlertVisible ||
    !isGenerating;

  const alertMessageContext = getAlertMessageContext(
    hasErrors,
    isMissingPresentationMeetingAlertVisible,
    isUpcomingAlertVisible,
  );

  const alertIcon = hasErrors
    ? 'icn-warning-outline-24px'
    : 'icn-info-circle-outline-24px';

  const alertType = hasErrors ? 'warning' : 'default';

  const generateSlides = async () => {
    setHasErrors(false);

    try {
      const response = await apiJobPresentationMeetingSlidesPost(jobId);

      if (!response) {
        throw new Error();
      }

      onSlidesGenerated(response);
      setIsGenerating(false);
    } catch (error) {
      setHasErrors(true);
    }
  };

  useEffect(() => {
    generateSlides();
  }, []);

  return (
    <div className="c-jra-slides-generation">
      <div className="c-jra-slides-generation__content">
        <h2 className="c-jra-slides-generation__title">
          {t('title', { context: isGenerating ? 'loading' : null })}
        </h2>

        <p className="c-jra-slides-generation__subtitle">
          <Trans i18nKey="modals.jraSlidesGeneration.content" />
        </p>

        {isAlertVisible && (
          <Alert
            modifier="c-jra-slides-generation__alert"
            icon={alertIcon}
            type={alertType}
            closable={false}
          >
            <Trans
              i18nKey="modals.jraSlidesGeneration.alert"
              tOptions={{ context: alertMessageContext }}
              values={{
                days: daysToPresentationMeeting,
              }}
            />
          </Alert>
        )}
      </div>
    </div>
  );
};

const UPCOMING_PRESENTATION_MEETING_DAYS = 2;

const getAlertMessageContext = (
  hasErrors: boolean,
  isMissingPresentationMeetingAlertVisible: boolean,
  isUpcomingAlertVisible: boolean,
): string | null => {
  if (hasErrors) {
    return 'error';
  }

  if (isMissingPresentationMeetingAlertVisible) {
    return 'noPresentationMeeting';
  }

  return isUpcomingAlertVisible ? 'presentationMeetingUpcoming' : null;
};
