import React from 'react';
import classNames from 'classnames';
import { AppModal } from '../AppModal';
import { JOB_SET_DATE_MODAL_TYPES } from '../../constants/job';
import { useHandleError } from '../../helpers/hooks/use-handle-error';
import { useTranslation } from 'react-i18next';
import { Alert } from '@reverse-hr/pattern-library';
import { SetMeetingDateModal } from '../SetMeetingDateModal';
import { useUpdateJobMeetings } from '../../hooks/use-update-job-meetings';
import { useStoreActions } from 'easy-peasy';
import { baseJobActionsSelector } from '../../selectors/job/selectors';
import { SendSuitableCandidatesModal } from '../SendSuitableCandidatesModal';
import { apiJobSendApplicationsToClientPost } from '../../api/job.legacy';
import { ShareEmployerViewModal } from '../ShareEmployerViewModal';

export const JobHeaderModal = ({
  isShareEmployerViewModalOpen,
  isSendSuitableCandidatesModalOpen,
  isPresentationMeetingModalOpen,
  isProfilingMeetingModalOpen,
  job,
  suitableCandidates,
  onShareEmployerViewClose,
  onCloseMeetingDateModalClick,
  onCloseSendSuitableCandidatesModalClick,
  onSuitableCandidatesSent,
}) => {
  const { t } = useTranslation();
  const { setJob } = useStoreActions(baseJobActionsSelector);

  const { handledError, setHandledError, handleError } = useHandleError({
    genericError: t('modals.setMeetingDate.error'),
  });

  const isSetMeetingDateModalVisible =
    isPresentationMeetingModalOpen || isProfilingMeetingModalOpen;

  const modalClassName = classNames('c-modal--auto-height', {
    'c-modal--share-employer-view': isShareEmployerViewModalOpen,
    'c-modal--set-date': isSetMeetingDateModalVisible,
    'c-modal--job-send-candidates': isSendSuitableCandidatesModalOpen,
  });

  const isModalOpen =
    isShareEmployerViewModalOpen ||
    isSendSuitableCandidatesModalOpen ||
    isSetMeetingDateModalVisible;

  const meetingDateModalType = isPresentationMeetingModalOpen
    ? JOB_SET_DATE_MODAL_TYPES.PRESENTATION_MEETING
    : JOB_SET_DATE_MODAL_TYPES.PROFILING_MEETING;

  const onJobUpdate = updatedJob => {
    setJob(updatedJob);
    onCloseMeetingDateModalClick();
  };

  const {
    initialDateValue,
    initialTimeZoneValue,
    onSubmit: onMeetingDateModalSubmit,
  } = useUpdateJobMeetings({
    job,
    meetingType: meetingDateModalType,
    onUpdate: onJobUpdate,
    onError: handleError,
  });

  const onSendSuitableCandidates = async () => {
    try {
      await apiJobSendApplicationsToClientPost(job.uuid);
      onCloseSendSuitableCandidatesModalClick();
      onSuitableCandidatesSent();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <AppModal isVisible={isModalOpen} className={modalClassName}>
      <div className="c-modal__wrapper">
        {isShareEmployerViewModalOpen && (
          <ShareEmployerViewModal onClose={onShareEmployerViewClose} />
        )}

        {isSetMeetingDateModalVisible && (
          <SetMeetingDateModal
            isTimeZoneActive
            initialDateValue={initialDateValue}
            initialTimeZoneValue={initialTimeZoneValue}
            modalType={meetingDateModalType}
            onClose={onCloseMeetingDateModalClick}
            onSubmit={onMeetingDateModalSubmit}
          />
        )}

        {isSendSuitableCandidatesModalOpen && (
          <SendSuitableCandidatesModal
            job={job}
            suitableCandidates={suitableCandidates}
            onClose={onCloseSendSuitableCandidatesModalClick}
            onSubmit={onSendSuitableCandidates}
          />
        )}

        {!!handledError && (
          <Alert
            modifier="c-alert--fixed"
            closable
            icon="icn-warning-outline-24"
            type="negative"
            onClose={() => setHandledError(null)}
          >
            {handledError}
          </Alert>
        )}
      </div>
    </AppModal>
  );
};
