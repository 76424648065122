import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Parser } from 'html-to-react';

const Readmore = ({ modifier, additionalText, body }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [isMultiline, setIsMultiline] = useState(false);
  const abstractRef = useRef(null);

  const htmlParser = new Parser();

  useEffect(() => {
    // depends of the number of lines in css
    if (abstractRef.current.offsetHeight > 24) {
      setIsMultiline(true);
    } else {
      setIsMultiline(false);
    }
  }, [body, abstractRef]);

  return (
    <div
      data-testid="readmore"
      className={`c-readmore ${modifier} ${visible ? 'visible' : ''}`}
    >
      <div className="c-readmore__body">
        <div
          data-testid="readmore-abstract"
          ref={abstractRef}
          className={`c-readmore__abstract ${isMultiline ? 'multiline' : ''}`}
        >
          {htmlParser.parse(body)}
        </div>

        <div
          data-testid="readmore-additional-text"
          className="c-readmore__other-text"
        >
          {htmlParser.parse(additionalText)}
        </div>

        {isMultiline && (
          <label
            onClick={() => setVisible(!visible)}
            className="c-readmore__readtoggle"
            data-testid="readmore-label"
            data-readmore-text={t('note.readmore')}
            data-readless-text={t('note.readless')}
          ></label>
        )}
      </div>
    </div>
  );
};

Readmore.defaultProps = {
  modifier: 'c-readmore--note',
  body: '',
  additionalText: '',
};

export default Readmore;
