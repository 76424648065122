import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';

import PrivateRoute from '../pages/PrivateRoute';
import Homepage from '../pages/Homepage';
import Job from '../pages/Job';
import Jobs from '../pages/Jobs';
import NotLogged from '../pages/NotLogged';

import MainLayout from '../layouts/MainLayout';

const RouterSwitch = () => {
  const location = useLocation();
  const setLocationType = useStoreActions(actions => actions.setLocationType);

  useEffect(() => {
    let type;
    // /jarvis -> tasks
    // /jarvis/jobs -> jobs
    // /jarvis/jobs/123 -> job_detail

    // Non cambia niente perché l'url è cambiata a mano
    // /jarvis/?task=1224 -> tasks -
    // /jarvis/jobs/123?task=123 -> job_detail

    const { pathname } = location;
    const pathnameTokenized = pathname.split('/');
    const cleanedPathname = pathnameTokenized.filter(path => !!path);
    let id = '';
    if (cleanedPathname.length === 1 && cleanedPathname[0] === 'jobs') {
      type = 'jobs';
    } else if (!cleanedPathname.length) {
      type = 'tasks';
    } else if (
      cleanedPathname.length === 2 &&
      typeof Number(cleanedPathname[1]) === 'number'
    ) {
      type = 'job_detail';
      id = cleanedPathname[1];
    }

    if (type && id) {
      setLocationType({ type, id });
    }
  }, [location, setLocationType]);

  return (
    <Switch>
      <Route path={['/jobs', '/jobs/:id', '/internal/debug']}>
        <MainLayout
          modifier={location.pathname.includes('jobs') ? 'u-bg-medium' : ''}
        >
          <Switch>
            <Route path="/not-logged" component={NotLogged} />
            <PrivateRoute path="/jobs/:id">
              <Job />
            </PrivateRoute>
            <PrivateRoute path="/jobs">
              <Jobs />
            </PrivateRoute>
          </Switch>
        </MainLayout>
      </Route>
      <Route path="/" exact>
        <MainLayout>
          <Switch>
            <PrivateRoute path="/">
              <Homepage />
            </PrivateRoute>
          </Switch>
        </MainLayout>
      </Route>
    </Switch>
  );
};

export default RouterSwitch;
