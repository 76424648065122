import type { TTaskLegacyPromptTemplate } from '../../../models/Task';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { USER_LOCALES } from '../../../definitions/user';

export const useSelectedTemplates = (
  templates: TTaskLegacyPromptTemplate[] = [],
) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null,
  );

  const { job } = useContext(TaskModalContext);

  const isTemplateListValid = templates.length > 0;

  const sortedTemplates = useMemo(
    () =>
      [...templates].sort((a, b) => {
        if (a.language === USER_LOCALES.IT) {
          return -1;
        }

        if (b.language === USER_LOCALES.IT) {
          return 1;
        }

        return 0;
      }),
    [JSON.stringify(templates)],
  );

  const selectedTemplate = useMemo(
    () =>
      templates.find(template => template.template_id === selectedTemplateId),
    [selectedTemplateId],
  );

  const selectInitialTemplate = () => {
    if (!templates || !job || !isTemplateListValid) {
      return;
    }

    const jobLanguageTemplate = templates.find(
      template => template.language === job.language,
    );

    if (!jobLanguageTemplate) {
      return;
    }

    setSelectedTemplateId(jobLanguageTemplate.template_id);
  };

  useEffect(() => {
    selectInitialTemplate();
  }, [JSON.stringify(templates)]);

  return {
    selectedTemplate,
    selectedTemplateId,
    setSelectedTemplateId,
    sortedTemplates,
    isTemplateListValid,
  };
};
