import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from './Navigation';
import User from './User';
import { Icon } from '@reverse-hr/pattern-library';

const Header = ({ user, navigation, loading, error }) => (
  <header className="c-header">
    <div className="c-header__logo">
      <div className="c-logo">
        <Link to="/" className="c-logo__anchor">
          Jarvis
        </Link>
      </div>
    </div>

    <div className="c-header__user">
      <User user={user} />
    </div>

    <label className="c-header__nav-open" htmlFor="navigation-toggle">
      <Icon name="icn-menu-24px" />
    </label>

    <input
      type="checkbox"
      id="navigation-toggle"
      className="u-visuallyhidden u-toggle"
    />

    <div className="c-header__overlay u-toggleReceiver">
      <div className="c-header__overlay-topbar">
        <label className="c-header__nav-close" htmlFor="navigation-toggle">
          <Icon name="icn-close-24px" />
        </label>
      </div>
      <Navigation {...navigation} loading={loading} error={error} />
    </div>
  </header>
);

export default Header;
