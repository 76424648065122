import { TaskDetails } from '../TaskDetails';
import React, { useContext, useState } from 'react';
import { TASK_PROMPT_TYPES } from '../../../constants/task';
import { Trans, useTranslation } from 'react-i18next';
import { JOB_TABS } from '../../../constants/job';
import { Link } from 'react-router-dom';
import { TaskModalContext } from '../../TaskModal';
import { ExternalLink } from '../../ExternalLink';
import { useDateDifferenceL10n } from '../../../hooks/use-date-difference-l10n';
import { isFuture } from 'date-fns';
import { Action } from '@reverse-hr/pattern-library';
import { useTaskCompleter } from '../../../hooks/use-task-completer';

export const TaskJobPrepareForProfilingMeeting = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'task.detailsView.jobPrepareForProfilingMeeting',
  });

  const { jobLegacy } = useContext(TaskModalContext);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const { completeTask } = useTaskCompleter(setIsErrorAlertVisible);

  const profilingMeetingDate = new Date(
    jobLegacy.profilingMeetingScheduledAt.localDateTime,
  );

  const isProfilingMeetingFuture = isFuture(profilingMeetingDate);

  const profilingMeetingDateDifference =
    useDateDifferenceL10n(profilingMeetingDate);

  const title = t('title', {
    context: isProfilingMeetingFuture ? 'future' : null,
    profilingMeetingDateDifference,
  });

  const prompts = [
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskJobPrepareForProfilingMeetingPromptMeetingsList />,
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskJobPrepareForProfilingMeetingPromptInviteSalesperson />,
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskJobPrepareForProfilingMeetingPromptMiroBoard />,
    },
  ];

  const suggestion = t('suggestion');

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={prompts}
      title={title}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
      suggestion={suggestion}
    >
      <Action onClick={completeTask} label={t('cta')} />
    </TaskDetails>
  );
};

const TaskJobPrepareForProfilingMeetingPromptMeetingsList = () => {
  const { jobLegacy, onModalClose } = useContext(TaskModalContext);

  const profilingMeetingDate = new Date(
    jobLegacy.profilingMeetingScheduledAt.localDateTime,
  );

  return (
    <p>
      <Trans
        i18nKey="task.detailsView.jobPrepareForProfilingMeeting.prompts.rescheduleProfilingMeeting"
        values={{
          date: profilingMeetingDate,
          time: profilingMeetingDate,
        }}
      >
        0
        <Link
          to={`/jobs/${jobLegacy.id}/${JOB_TABS.INTERVIEWS_AND_MEETINGS}`}
          onClick={onModalClose}
        >
          1
        </Link>
      </Trans>
    </p>
  );
};

const TaskJobPrepareForProfilingMeetingPromptInviteSalesperson = () => {
  const { t } = useTranslation();
  const { jobLegacy } = useContext(TaskModalContext);

  const salespersonFullName = t('common.formatting.fullName', {
    firstName: jobLegacy.salesperson.firstName,
    lastName: jobLegacy.salesperson.lastName,
  });

  return (
    <p>
      <Trans
        i18nKey="task.detailsView.jobPrepareForProfilingMeeting.prompts.inviteSalespersonToMeeting"
        values={{
          salespersonFullName,
          salespersonPhoneNumber: jobLegacy.salesperson.phoneNumber,
        }}
        components={{ a: <a /> }}
      />
    </p>
  );
};

const TaskJobPrepareForProfilingMeetingPromptMiroBoard = () => {
  const { jobLegacy } = useContext(TaskModalContext);

  return (
    <p>
      <Trans i18nKey="task.detailsView.jobPrepareForProfilingMeeting.prompts.assessmentBoard">
        0<ExternalLink href={jobLegacy.miro_url}>1</ExternalLink>
      </Trans>
    </p>
  );
};
