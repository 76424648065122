import React, { useState, useRef } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useTranslation } from 'react-i18next';

const Editor = ({ id, modelName, content, disabled, onChange = () => {} }) => {
  const { t } = useTranslation();
  const editorRef = useRef(null);

  // eslint-disable-next-line
  const [_, setEditor] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  const defaultConfiguration = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
  };

  const handleInit = editor => {
    setEditor(editor);
  };
  const handleOnChange = (e, editor) => {
    const htmlValue = editor.getData();
    onChange({ name: modelName, value: htmlValue, valid: !!htmlValue.trim() });
    const editorElt = editorRef.current.querySelector('.ck-editor__main');
    if (!htmlValue.trim()) {
      setIsEmpty(true);
      editorElt.classList.add('ck-editor__error');
    } else {
      setIsEmpty(false);
      editorElt.classList.remove('ck-editor__error');
    }
  };

  return (
    <div
      className={`c-form-control ${isEmpty ? 'c-form-control--error' : ''}`}
      ref={editorRef}
    >
      <CKEditor
        editor={ClassicEditor}
        data={content}
        disabled={disabled}
        config={defaultConfiguration}
        onInit={handleInit}
        onChange={handleOnChange}
      />
      {isEmpty && (
        <p
          role="alert"
          data-testid="editor-note"
          className={`c-form-control__note`}
        >
          {t('validation.notEmpty')}
        </p>
      )}
    </div>
  );
};

export default Editor;
