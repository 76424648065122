import { ROUTER_BASENAME } from '../../constants/app';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const AddNotePicture = ({ className }) => {
  const { t } = useTranslation();
  const wrapperClassNames = classNames('c-add-note-picture', className);

  return (
    <img
      className={wrapperClassNames}
      src={`${ROUTER_BASENAME}/assets/images/add-note.svg`}
      alt={t('note.label')}
    />
  );
};
