import React from 'react';
import ContentLoader from 'react-content-loader';

const TasksSkeleton = () => (
  <ContentLoader
    backgroundColor="rgb(231, 231, 232)"
    foregroundColor="rgb(207, 207, 208)"
    viewBox="0 0 400 600"
    preserveAspectRatio={'none'}
  >
    <rect x="0" y="0" rx="0" ry="0" width="20" height="10" />
    <rect x="30" y="0" rx="0" ry="0" width="40" height="10" />

    <rect x="0" y="20" rx="0" ry="0" width="90" height="70" />

    <rect x="105" y="20" rx="0" ry="0" width="90" height="90" />
    <rect x="210" y="20" rx="0" ry="0" width="90" height="70" />
    <rect x="315" y="20" rx="0" ry="0" width="90" height="80" />

    <rect x="0" y="120" rx="0" ry="0" width="20" height="10" />
    <rect x="30" y="120" rx="0" ry="0" width="40" height="10" />

    <rect x="0" y="140" rx="0" ry="0" width="90" height="70" />

    <rect x="105" y="140" rx="0" ry="0" width="90" height="90" />
    <rect x="210" y="140" rx="0" ry="0" width="90" height="70" />
    <rect x="315" y="140" rx="0" ry="0" width="90" height="80" />
  </ContentLoader>
);

export default TasksSkeleton;
