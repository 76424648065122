import type { FC } from 'react';
import { useContext, useState } from 'react';
import { TaskDetails } from '../TaskDetails';
import { Trans, useTranslation } from 'react-i18next';
import { useTaskCompleter } from '../../../hooks/use-task-completer';
import { Action } from '@reverse-hr/pattern-library';
import { TASK_PROMPT_TYPES } from '../../../constants/task';
import { TaskModalContext } from '../../TaskModal';

export const TaskJobHuntCandidates: FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'task.detailsView.jobHuntCandidates',
  });

  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const { completeTask } = useTaskCompleter(setIsErrorAlertVisible);

  const prompts = [
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskJobHuntCandidatesMustHaveAndNiceToHavePrompt />,
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskJobHuntCandidatesHoundListPrompt />,
    },
  ];

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={prompts}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
      suggestion={t('suggestion')}
      title={t('title')}
    >
      <Action label={t('cta')} onClick={completeTask} type="primary" />
    </TaskDetails>
  );
};

const TaskJobHuntCandidatesMustHaveAndNiceToHavePrompt: FC = () => {
  const { jobLegacy } = useContext(TaskModalContext);

  const jobSettingsBaseURL = jobLegacy
    ? `/dashboard/recruitment/jobs/${jobLegacy.uuid}/edit`
    : '';

  const mustHaveAndNiceToHaveURL = `${jobSettingsBaseURL}#skill-requirements`;
  const jobAdURL = `${jobSettingsBaseURL}#job-ad`;

  return (
    <p>
      <Trans i18nKey="task.detailsView.jobHuntCandidates.prompts.mustHaveAndNiceToHave">
        0
        <a href={mustHaveAndNiceToHaveURL} rel="noreferrer" target="_blank">
          1
        </a>
        <a href={jobAdURL} rel="noreferrer" target="_blank">
          2
        </a>
      </Trans>
    </p>
  );
};

const TaskJobHuntCandidatesHoundListPrompt: FC = () => {
  const { jobLegacy } = useContext(TaskModalContext);

  const houndListURL = jobLegacy
    ? `/hound/profiles/potential_matches/?jobId=${jobLegacy.uuid}`
    : '';

  return (
    <p>
      <Trans i18nKey="task.detailsView.jobHuntCandidates.prompts.houndList">
        0
        <a href={houndListURL} rel="noreferrer" target="_blank">
          1
        </a>
      </Trans>
    </p>
  );
};
