export const TIMEZONES = [
  {
    code: 'Africa/Abidjan',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Accra',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Addis_Ababa',
    utc: 'GMT +03:00',
  },
  {
    code: 'Africa/Algiers',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Asmara',
    utc: 'GMT +03:00',
  },
  {
    code: 'Africa/Bamako',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Bangui',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Banjul',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Bissau',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Blantyre',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Brazzaville',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Bujumbura',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Cairo',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Casablanca',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Ceuta',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Conakry',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Dakar',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Dar_es_Salaam',
    utc: 'GMT +03:00',
  },
  {
    code: 'Africa/Djibouti',
    utc: 'GMT +03:00',
  },
  {
    code: 'Africa/Douala',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/El_Aaiun',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Freetown',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Gaborone',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Harare',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Johannesburg',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Juba',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Kampala',
    utc: 'GMT +03:00',
  },
  {
    code: 'Africa/Khartoum',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Kigali',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Kinshasa',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Lagos',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Libreville',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Lome',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Luanda',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Lubumbashi',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Lusaka',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Malabo',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Maputo',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Maseru',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Mbabane',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Mogadishu',
    utc: 'GMT +03:00',
  },
  {
    code: 'Africa/Monrovia',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Nairobi',
    utc: 'GMT +03:00',
  },
  {
    code: 'Africa/Ndjamena',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Niamey',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Nouakchott',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Ouagadougou',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Porto-Novo',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Sao_Tome',
    utc: 'GMT +00:00',
  },
  {
    code: 'Africa/Tripoli',
    utc: 'GMT +02:00',
  },
  {
    code: 'Africa/Tunis',
    utc: 'GMT +01:00',
  },
  {
    code: 'Africa/Windhoek',
    utc: 'GMT +01:00',
  },
  {
    code: 'America/Adak',
    utc: 'GMT -10:00',
  },
  {
    code: 'America/Anchorage',
    utc: 'GMT -09:00',
  },
  {
    code: 'America/Anguilla',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Antigua',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Araguaina',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Buenos_Aires',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Catamarca',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Cordoba',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Jujuy',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/La_Rioja',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Mendoza',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Rio_Gallegos',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Salta',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/San_Juan',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/San_Luis',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Tucuman',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Argentina/Ushuaia',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Aruba',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Asuncion',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Atikokan',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Bahia',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Bahia_Banderas',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Barbados',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Belem',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Belize',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Blanc-Sablon',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Boa_Vista',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Bogota',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Boise',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Cambridge_Bay',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Campo_Grande',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Cancun',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Caracas',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Cayenne',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Cayman',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Chicago',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Chihuahua',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Costa_Rica',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Creston',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Cuiaba',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Curacao',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Danmarkshavn',
    utc: 'GMT +00:00',
  },
  {
    code: 'America/Dawson',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Dawson_Creek',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Denver',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Detroit',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Dominica',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Edmonton',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Eirunepe',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/El_Salvador',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Fort_Nelson',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Fortaleza',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Glace_Bay',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Goose_Bay',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Grand_Turk',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Grenada',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Guadeloupe',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Guatemala',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Guayaquil',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Guyana',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Halifax',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Havana',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Hermosillo',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Indiana/Indianapolis',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Indiana/Knox',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Indiana/Marengo',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Indiana/Petersburg',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Indiana/Tell_City',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Indiana/Vevay',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Indiana/Vincennes',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Indiana/Winamac',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Inuvik',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Iqaluit',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Jamaica',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Juneau',
    utc: 'GMT -09:00',
  },
  {
    code: 'America/Kentucky/Louisville',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Kentucky/Monticello',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Kralendijk',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/La_Paz',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Lima',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Los_Angeles',
    utc: 'GMT -08:00',
  },
  {
    code: 'America/Lower_Princes',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Maceio',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Managua',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Manaus',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Marigot',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Martinique',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Matamoros',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Mazatlan',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Menominee',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Merida',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Metlakatla',
    utc: 'GMT -09:00',
  },
  {
    code: 'America/Mexico_City',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Miquelon',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Moncton',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Monterrey',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Montevideo',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Montserrat',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Nassau',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/New_York',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Nipigon',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Nome',
    utc: 'GMT -09:00',
  },
  {
    code: 'America/Noronha',
    utc: 'GMT -02:00',
  },
  {
    code: 'America/North_Dakota/Beulah',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/North_Dakota/Center',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/North_Dakota/New_Salem',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Nuuk',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Ojinaga',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Panama',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Pangnirtung',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Paramaribo',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Phoenix',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Port-au-Prince',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Port_of_Spain',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Porto_Velho',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Puerto_Rico',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Punta_Arenas',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Rainy_River',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Rankin_Inlet',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Recife',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Regina',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Resolute',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Rio_Branco',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Santarem',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Santiago',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Santo_Domingo',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Sao_Paulo',
    utc: 'GMT -03:00',
  },
  {
    code: 'America/Scoresbysund',
    utc: 'GMT -01:00',
  },
  {
    code: 'America/Sitka',
    utc: 'GMT -09:00',
  },
  {
    code: 'America/St_Barthelemy',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/St_Johns',
    utc: 'GMT -03:30',
  },
  {
    code: 'America/St_Kitts',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/St_Lucia',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/St_Thomas',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/St_Vincent',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Swift_Current',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Tegucigalpa',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Thule',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Thunder_Bay',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Tijuana',
    utc: 'GMT -08:00',
  },
  {
    code: 'America/Toronto',
    utc: 'GMT -05:00',
  },
  {
    code: 'America/Tortola',
    utc: 'GMT -04:00',
  },
  {
    code: 'America/Vancouver',
    utc: 'GMT -08:00',
  },
  {
    code: 'America/Whitehorse',
    utc: 'GMT -07:00',
  },
  {
    code: 'America/Winnipeg',
    utc: 'GMT -06:00',
  },
  {
    code: 'America/Yakutat',
    utc: 'GMT -09:00',
  },
  {
    code: 'America/Yellowknife',
    utc: 'GMT -07:00',
  },
  {
    code: 'Antarctica/Casey',
    utc: 'GMT +11:00',
  },
  {
    code: 'Antarctica/Davis',
    utc: 'GMT +07:00',
  },
  {
    code: 'Antarctica/DumontDUrville',
    utc: 'GMT +10:00',
  },
  {
    code: 'Antarctica/Macquarie',
    utc: 'GMT +10:00',
  },
  {
    code: 'Antarctica/Mawson',
    utc: 'GMT +05:00',
  },
  {
    code: 'Antarctica/McMurdo',
    utc: 'GMT +12:00',
  },
  {
    code: 'Antarctica/Palmer',
    utc: 'GMT -03:00',
  },
  {
    code: 'Antarctica/Rothera',
    utc: 'GMT -03:00',
  },
  {
    code: 'Antarctica/Syowa',
    utc: 'GMT +03:00',
  },
  {
    code: 'Antarctica/Troll',
    utc: 'GMT +00:00',
  },
  {
    code: 'Antarctica/Vostok',
    utc: 'GMT +06:00',
  },
  {
    code: 'Arctic/Longyearbyen',
    utc: 'GMT +01:00',
  },
  {
    code: 'Asia/Aden',
    utc: 'GMT +03:00',
  },
  {
    code: 'Asia/Almaty',
    utc: 'GMT +06:00',
  },
  {
    code: 'Asia/Amman',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Anadyr',
    utc: 'GMT +12:00',
  },
  {
    code: 'Asia/Aqtau',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Aqtobe',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Ashgabat',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Atyrau',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Baghdad',
    utc: 'GMT +03:00',
  },
  {
    code: 'Asia/Bahrain',
    utc: 'GMT +03:00',
  },
  {
    code: 'Asia/Baku',
    utc: 'GMT +04:00',
  },
  {
    code: 'Asia/Bangkok',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Barnaul',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Beirut',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Bishkek',
    utc: 'GMT +06:00',
  },
  {
    code: 'Asia/Brunei',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Chita',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Choibalsan',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Colombo',
    utc: 'GMT +05:30',
  },
  {
    code: 'Asia/Damascus',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Dhaka',
    utc: 'GMT +06:00',
  },
  {
    code: 'Asia/Dili',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Dubai',
    utc: 'GMT +04:00',
  },
  {
    code: 'Asia/Dushanbe',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Famagusta',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Gaza',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Hebron',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Ho_Chi_Minh',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Hong_Kong',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Hovd',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Irkutsk',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Jakarta',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Jayapura',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Jerusalem',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Kabul',
    utc: 'GMT +04:30',
  },
  {
    code: 'Asia/Kamchatka',
    utc: 'GMT +12:00',
  },
  {
    code: 'Asia/Karachi',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Kathmandu',
    utc: 'GMT +05:45',
  },
  {
    code: 'Asia/Khandyga',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Kolkata',
    utc: 'GMT +05:30',
  },
  {
    code: 'Asia/Krasnoyarsk',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Kuala_Lumpur',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Kuching',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Kuwait',
    utc: 'GMT +03:00',
  },
  {
    code: 'Asia/Macau',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Magadan',
    utc: 'GMT +11:00',
  },
  {
    code: 'Asia/Makassar',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Manila',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Muscat',
    utc: 'GMT +04:00',
  },
  {
    code: 'Asia/Nicosia',
    utc: 'GMT +02:00',
  },
  {
    code: 'Asia/Novokuznetsk',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Novosibirsk',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Omsk',
    utc: 'GMT +06:00',
  },
  {
    code: 'Asia/Oral',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Phnom_Penh',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Pontianak',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Pyongyang',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Qatar',
    utc: 'GMT +03:00',
  },
  {
    code: 'Asia/Qostanay',
    utc: 'GMT +06:00',
  },
  {
    code: 'Asia/Qyzylorda',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Riyadh',
    utc: 'GMT +03:00',
  },
  {
    code: 'Asia/Sakhalin',
    utc: 'GMT +11:00',
  },
  {
    code: 'Asia/Samarkand',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Seoul',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Shanghai',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Singapore',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Srednekolymsk',
    utc: 'GMT +11:00',
  },
  {
    code: 'Asia/Taipei',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Tashkent',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Tbilisi',
    utc: 'GMT +04:00',
  },
  {
    code: 'Asia/Tehran',
    utc: 'GMT +03:30',
  },
  {
    code: 'Asia/Thimphu',
    utc: 'GMT +06:00',
  },
  {
    code: 'Asia/Tokyo',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Tomsk',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Ulaanbaatar',
    utc: 'GMT +08:00',
  },
  {
    code: 'Asia/Urumqi',
    utc: 'GMT +06:00',
  },
  {
    code: 'Asia/Ust-Nera',
    utc: 'GMT +10:00',
  },
  {
    code: 'Asia/Vientiane',
    utc: 'GMT +07:00',
  },
  {
    code: 'Asia/Vladivostok',
    utc: 'GMT +10:00',
  },
  {
    code: 'Asia/Yakutsk',
    utc: 'GMT +09:00',
  },
  {
    code: 'Asia/Yangon',
    utc: 'GMT +06:30',
  },
  {
    code: 'Asia/Yekaterinburg',
    utc: 'GMT +05:00',
  },
  {
    code: 'Asia/Yerevan',
    utc: 'GMT +04:00',
  },
  {
    code: 'Atlantic/Azores',
    utc: 'GMT -01:00',
  },
  {
    code: 'Atlantic/Bermuda',
    utc: 'GMT -04:00',
  },
  {
    code: 'Atlantic/Canary',
    utc: 'GMT +00:00',
  },
  {
    code: 'Atlantic/Cape_Verde',
    utc: 'GMT -01:00',
  },
  {
    code: 'Atlantic/Faroe',
    utc: 'GMT +00:00',
  },
  {
    code: 'Atlantic/Madeira',
    utc: 'GMT +00:00',
  },
  {
    code: 'Atlantic/Reykjavik',
    utc: 'GMT +00:00',
  },
  {
    code: 'Atlantic/South_Georgia',
    utc: 'GMT -02:00',
  },
  {
    code: 'Atlantic/St_Helena',
    utc: 'GMT +00:00',
  },
  {
    code: 'Atlantic/Stanley',
    utc: 'GMT -03:00',
  },
  {
    code: 'Australia/Adelaide',
    utc: 'GMT +09:30',
  },
  {
    code: 'Australia/Brisbane',
    utc: 'GMT +10:00',
  },
  {
    code: 'Australia/Broken_Hill',
    utc: 'GMT +09:30',
  },
  {
    code: 'Australia/Darwin',
    utc: 'GMT +09:30',
  },
  {
    code: 'Australia/Eucla',
    utc: 'GMT +08:45',
  },
  {
    code: 'Australia/Hobart',
    utc: 'GMT +10:00',
  },
  {
    code: 'Australia/Lindeman',
    utc: 'GMT +10:00',
  },
  {
    code: 'Australia/Lord_Howe',
    utc: 'GMT +10:30',
  },
  {
    code: 'Australia/Melbourne',
    utc: 'GMT +10:00',
  },
  {
    code: 'Australia/Perth',
    utc: 'GMT +08:00',
  },
  {
    code: 'Australia/Sydney',
    utc: 'GMT +10:00',
  },
  {
    code: 'Europe/Amsterdam',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Andorra',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Astrakhan',
    utc: 'GMT +04:00',
  },
  {
    code: 'Europe/Athens',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Belgrade',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Berlin',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Bratislava',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Brussels',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Bucharest',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Budapest',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Busingen',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Chisinau',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Copenhagen',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Dublin',
    utc: 'GMT +00:00',
  },
  {
    code: 'Europe/Gibraltar',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Guernsey',
    utc: 'GMT +00:00',
  },
  {
    code: 'Europe/Helsinki',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Isle_of_Man',
    utc: 'GMT +00:00',
  },
  {
    code: 'Europe/Istanbul',
    utc: 'GMT +03:00',
  },
  {
    code: 'Europe/Jersey',
    utc: 'GMT +00:00',
  },
  {
    code: 'Europe/Kaliningrad',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Kiev',
    utc: 'GMT +03:00',
  },
  {
    code: 'Europe/Kirov',
    utc: 'GMT +03:00',
  },
  {
    code: 'Europe/Lisbon',
    utc: 'GMT +00:00',
  },
  {
    code: 'Europe/Ljubljana',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/London',
    utc: 'GMT +00:00',
  },
  {
    code: 'Europe/Luxembourg',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Madrid',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Malta',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Mariehamn',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Minsk',
    utc: 'GMT +03:00',
  },
  {
    code: 'Europe/Monaco',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Moscow',
    utc: 'GMT +03:00',
  },
  {
    code: 'Europe/Oslo',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Paris',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Podgorica',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Prague',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Riga',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Rome',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Samara',
    utc: 'GMT +04:00',
  },
  {
    code: 'Europe/San_Marino',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Sarajevo',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Saratov',
    utc: 'GMT +04:00',
  },
  {
    code: 'Europe/Simferopol',
    utc: 'GMT +03:00',
  },
  {
    code: 'Europe/Skopje',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Sofia',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Stockholm',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Tallinn',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Tirane',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Ulyanovsk',
    utc: 'GMT +04:00',
  },
  {
    code: 'Europe/Uzhgorod',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Vaduz',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Vatican',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Vienna',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Vilnius',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Volgograd',
    utc: 'GMT +03:00',
  },
  {
    code: 'Europe/Warsaw',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Zagreb',
    utc: 'GMT +01:00',
  },
  {
    code: 'Europe/Zaporozhye',
    utc: 'GMT +02:00',
  },
  {
    code: 'Europe/Zurich',
    utc: 'GMT +01:00',
  },
  {
    code: 'Indian/Antananarivo',
    utc: 'GMT +03:00',
  },
  {
    code: 'Indian/Chagos',
    utc: 'GMT +06:00',
  },
  {
    code: 'Indian/Christmas',
    utc: 'GMT +07:00',
  },
  {
    code: 'Indian/Cocos',
    utc: 'GMT +06:30',
  },
  {
    code: 'Indian/Comoro',
    utc: 'GMT +03:00',
  },
  {
    code: 'Indian/Kerguelen',
    utc: 'GMT +05:00',
  },
  {
    code: 'Indian/Mahe',
    utc: 'GMT +04:00',
  },
  {
    code: 'Indian/Maldives',
    utc: 'GMT +05:00',
  },
  {
    code: 'Indian/Mauritius',
    utc: 'GMT +04:00',
  },
  {
    code: 'Indian/Mayotte',
    utc: 'GMT +03:00',
  },
  {
    code: 'Indian/Reunion',
    utc: 'GMT +04:00',
  },
  {
    code: 'Pacific/Apia',
    utc: 'GMT +13:00',
  },
  {
    code: 'Pacific/Auckland',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Bougainville',
    utc: 'GMT +11:00',
  },
  {
    code: 'Pacific/Chatham',
    utc: 'GMT +12:45',
  },
  {
    code: 'Pacific/Chuuk',
    utc: 'GMT +10:00',
  },
  {
    code: 'Pacific/Easter',
    utc: 'GMT -06:00',
  },
  {
    code: 'Pacific/Efate',
    utc: 'GMT +11:00',
  },
  {
    code: 'Pacific/Fakaofo',
    utc: 'GMT +13:00',
  },
  {
    code: 'Pacific/Fiji',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Funafuti',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Galapagos',
    utc: 'GMT -06:00',
  },
  {
    code: 'Pacific/Gambier',
    utc: 'GMT -09:00',
  },
  {
    code: 'Pacific/Guadalcanal',
    utc: 'GMT +11:00',
  },
  {
    code: 'Pacific/Guam',
    utc: 'GMT +10:00',
  },
  {
    code: 'Pacific/Honolulu',
    utc: 'GMT -10:00',
  },
  {
    code: 'Pacific/Kanton',
    utc: 'GMT +13:00',
  },
  {
    code: 'Pacific/Kiritimati',
    utc: 'GMT +14:00',
  },
  {
    code: 'Pacific/Kosrae',
    utc: 'GMT +11:00',
  },
  {
    code: 'Pacific/Kwajalein',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Majuro',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Marquesas',
    utc: 'GMT -09:30',
  },
  {
    code: 'Pacific/Midway',
    utc: 'GMT -11:00',
  },
  {
    code: 'Pacific/Nauru',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Niue',
    utc: 'GMT -11:00',
  },
  {
    code: 'Pacific/Norfolk',
    utc: 'GMT +11:00',
  },
  {
    code: 'Pacific/Noumea',
    utc: 'GMT +11:00',
  },
  {
    code: 'Pacific/Pago_Pago',
    utc: 'GMT -11:00',
  },
  {
    code: 'Pacific/Palau',
    utc: 'GMT +09:00',
  },
  {
    code: 'Pacific/Pitcairn',
    utc: 'GMT -08:00',
  },
  {
    code: 'Pacific/Pohnpei',
    utc: 'GMT +11:00',
  },
  {
    code: 'Pacific/Port_Moresby',
    utc: 'GMT +10:00',
  },
  {
    code: 'Pacific/Rarotonga',
    utc: 'GMT -10:00',
  },
  {
    code: 'Pacific/Saipan',
    utc: 'GMT +10:00',
  },
  {
    code: 'Pacific/Tahiti',
    utc: 'GMT -10:00',
  },
  {
    code: 'Pacific/Tarawa',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Tongatapu',
    utc: 'GMT +13:00',
  },
  {
    code: 'Pacific/Wake',
    utc: 'GMT +12:00',
  },
  {
    code: 'Pacific/Wallis',
    utc: 'GMT +12:00',
  },
];
