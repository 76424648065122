import he from 'he';
import i18next from 'i18next';
import { format } from 'date-fns';
import { it, enGB } from 'date-fns/locale';

export const getProgressIcon = progress =>
  `icn-${progress.toLowerCase().replace(/ /g, '-')}-24px`;

export const convert = input => {
  if (!input) return '';
  return he.decode(
    input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
      const charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    }),
  );
};

export const parseUrlParams = search => {
  const params = search || window.location.search;
  const arr = params.replace('?', '').split('&');
  let paramsObj = {};
  arr.forEach(param => {
    const pair = param.split('=');
    paramsObj[pair[0]] = pair[1];
  });
  return paramsObj;
};

export const formatDate = (date, formatto) => {
  const locale = window.jarvis_locale === 'it' ? it : enGB;
  return format(date, formatto, { locale });
};

export const getValidation = rules => {
  let validation = {};
  const obj = {
    emails: {
      validate: value => {
        const reg =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const msg = i18next.t('validation.email') || 'error';
        const arr = value.split(',');
        let error = false;
        if (arr.filter(v => !!v).length) {
          arr.forEach(mail => {
            if (!reg.test(mail.trim())) {
              error = true;
            }
          });
        }
        return error ? msg : true;
      },
    },
    email: {
      validate: value => {
        const reg =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const msg = i18next.t('validation.email') || 'error';

        return reg.test(value) || msg;
      },
    },
    required: {
      validate: value =>
        !!value.trim() || i18next.t('validation.required') || 'error',
    },
    postpone: {
      validate: value =>
        Date.parse(new Date(value)) > Date.parse(new Date()) || 'error',
    },
    url: {
      validate: value => {
        try {
          new URL(value);
          return true;
        } catch {
          return i18next.t('validation.url');
        }
      },
    },
  };

  rules.forEach(rule => {
    validation = {
      ...validation,
      [rule]: obj[rule],
    };
  });
  return validation;
};
