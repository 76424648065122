import React from 'react';
import { ActionMenu } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';

export const TaskNoteActionMenu = ({ onRemove, onEdit }) => {
  const { t } = useTranslation();

  const actionMenuItems = [
    {
      key: TASK_HANDLE_ACTIONS.ON_EDIT,
      label: t('note.update'),
      itemType: 'simple',
      shouldCloseMenuAfterClick: true,
    },
    {
      key: TASK_HANDLE_ACTIONS.ON_REMOVE,
      label: t('note.remove'),
      itemType: 'simple',
      shouldCloseMenuAfterClick: true,
    },
  ];

  const handleActionMenuItemClick = item => {
    if (item.key === TASK_HANDLE_ACTIONS.ON_EDIT) {
      return onEdit();
    }

    if (item.key === TASK_HANDLE_ACTIONS.ON_REMOVE) {
      return onRemove();
    }
  };

  return (
    <ActionMenu
      items={actionMenuItems}
      onItemClick={handleActionMenuItemClick}
      buttonProps={{
        icon: 'icn-more-24px',
        type: 'simple-text',
        size: 'small',
        iconOnly: true,
      }}
    />
  );
};

const TASK_HANDLE_ACTIONS = {
  ON_EDIT: 'onEdit',
  ON_REMOVE: 'onRemove',
};
