const IS_ENV_PRODUCTION =
  !!process.env && process.env.NODE_ENV === 'production';

const PRODUCTION_HOST = 'https://app.reverse.hr';

export const REACT_APP_HOST = IS_ENV_PRODUCTION
  ? PRODUCTION_HOST
  : 'http://localhost:3000';

export const APP_LOGIN_URL = IS_ENV_PRODUCTION
  ? `${PRODUCTION_HOST}/login`
  : 'http://localhost/login';

export const EMPLOYER_VIEW_URL = `${PRODUCTION_HOST}/employer_view`;
