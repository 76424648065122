import React, { useState } from 'react';
import { useMeetingList } from './use-meeting-list';
import { MeetingListSkeleton } from './MeetingListSkeleton';
import { useTranslation } from 'react-i18next';
import { MeetingListDateGroup } from './MeetingListDateGroup';
import { MeetingListDeleteModal } from './MeetingListDeleteModal';
import { MeetingListEditMeetingModal } from './MeetingListEditMeetingModal';
import { ClientInterviewEditModal } from '../ClientInterviewEditModal';

export const MeetingList = () => {
  const { t } = useTranslation();
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [editMeetingFormData, setEditMeetingFormData] = useState(null);

  const [editClientInterviewFormData, setEditClientInterviewFormData] =
    useState(null);

  const [editScoutInterviewFormData, setEditScoutInterviewFormData] =
    useState(null);

  const {
    futureMeetings,
    pastMeetings,
    isLoading,
    isFutureMeetingsSectionVisible,
    isPastMeetingsSectionVisible,
    isListEmpty,
    updateMeetingList,
  } = useMeetingList();

  const isEditMeetingModalVisible =
    !!editMeetingFormData || !!editScoutInterviewFormData;

  const onCloseModal = () => {
    setEditClientInterviewFormData(null);
    setEditScoutInterviewFormData(null);
    setEditMeetingFormData(null);
    setDeleteModalData(null);
  };

  const onMeetingsUpdate = async () => {
    onCloseModal();
    await updateMeetingList();
  };

  if (isLoading) {
    return <MeetingListSkeleton />;
  }

  if (isListEmpty) {
    return (
      <p className="typo-text-body u-px-space-24 u-py-space-32">
        {t('job.meetingList.emptyList')}
      </p>
    );
  }

  return (
    <div className="c-meeting-list">
      {isFutureMeetingsSectionVisible && (
        <div className="c-meeting-list__group">
          <h4 className="c-meeting-list__heading">
            {t('job.meetingList.heading', { context: 'future' })}
          </h4>

          <MeetingListDateGroup
            meetings={futureMeetings}
            onEditClientInterviewClick={setEditClientInterviewFormData}
            onEditScoutInterviewClick={setEditScoutInterviewFormData}
            onEditMeetingClick={setEditMeetingFormData}
            onDeleteMeetingClick={setDeleteModalData}
          />
        </div>
      )}

      {isPastMeetingsSectionVisible && (
        <div className="c-meeting-list__group">
          <h4 className="c-meeting-list__heading">
            {t('job.meetingList.heading')}
          </h4>

          {Object.keys(pastMeetings).map(dateKey => (
            <MeetingListDateGroup
              key={dateKey}
              dateKey={dateKey}
              meetings={pastMeetings[dateKey]}
              onEditClientInterviewClick={setEditClientInterviewFormData}
              onEditScoutInterviewClick={setEditScoutInterviewFormData}
              onEditMeetingClick={setEditMeetingFormData}
              onDeleteMeetingClick={setDeleteModalData}
            />
          ))}
        </div>
      )}

      {!!editClientInterviewFormData && (
        <ClientInterviewEditModal
          applicationId={editClientInterviewFormData.applicationId}
          interviewId={editClientInterviewFormData.interviewId}
          candidateFullName={editClientInterviewFormData.candidateFullName}
          onClose={onCloseModal}
          onUpdate={onMeetingsUpdate}
        />
      )}

      {isEditMeetingModalVisible && (
        <MeetingListEditMeetingModal
          editMeetingFormType={editMeetingFormData}
          editScoutInterviewFormData={editScoutInterviewFormData}
          onClose={onCloseModal}
          onUpdate={onMeetingsUpdate}
        />
      )}

      {!!deleteModalData && (
        <MeetingListDeleteModal
          applicationId={deleteModalData.applicationId}
          interviewId={deleteModalData.interviewId}
          onClose={onCloseModal}
          onDelete={onMeetingsUpdate}
        />
      )}
    </div>
  );
};
