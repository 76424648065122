import * as firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDhTlyz0m-arHLfHQhN1bv95YJdiNoSKpw',
  authDomain: 'jarvis-86024.firebaseapp.com',
  databaseURL: 'https://jarvis-86024.firebaseio.com',
  projectId: 'jarvis-86024',
  storageBucket: 'jarvis-86024.appspot.com',
  messagingSenderId: '178514388979',
  appId: '1:178514388979:web:4011e3dae4d213d684d486',
};

const messagingConfig = {
  vapidKey:
    'BNTD9YD8jW5u7a5-6AnDx8f27CZ_6GIdKa8oDAJ-onYpCLOaYbykPEBc_Vp7OIWaUelmkoQUdrDniy7TJXOz1as',
};

const Firebase = (() => {
  const initialize = async () => {
    firebase.initializeApp(firebaseConfig);
    let registration;
    try {
      if ('serviceWorker' in navigator) {
        registration = await navigator.serviceWorker.register(
          `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`,
        );
        if (registration) {
          console.debug(
            'Firebase - Registration successful, scope is:',
            registration.scope,
          );
        }
      }
    } catch (error) {
      console.debug(
        'Firebase - Service worker registration failed, error:',
        error,
      );
    }
    return {
      firebase,
      config: {
        ...messagingConfig,
        serviceWorkerRegistration: registration,
      },
    };
  };

  return {
    initialize,
  };
})();

export default Firebase;
