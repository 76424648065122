import React, { useContext } from 'react';
import { SwitchToggle } from '@reverse-hr/pattern-library';
import classNames from 'classnames';
import { OfferDetailsFormContext } from './OfferDetailsForm';

export const OfferDetailsFormBenefitsItem = ({
  children,
  details,
  fieldKey,
  id,
  toggleSupportText,
}) => {
  const { formData, onFormChange } = useContext(OfferDetailsFormContext);

  const onSwitchChange = ({ target }) =>
    onFormChange({ [fieldKey]: target.checked });

  const isEnabled = !!formData[fieldKey];

  const detailsWrapperClassNames = classNames(
    'c-offer-details-form-benefits-item__content',
    {
      'c-offer-details-form-benefits-item__content--visible': isEnabled,
    },
  );

  return (
    <div className="c-offer-details-form-benefits__field c-offer-details-form-benefits-item">
      <SwitchToggle
        id={id}
        supportText={toggleSupportText}
        checked={isEnabled}
        onChange={onSwitchChange}
        raw
      />

      <div className={detailsWrapperClassNames}>
        {!!details && (
          <div className="c-offer-details-form-benefits-item__details">
            <p className="c-offer-details-form-benefits-item__label">
              {details.label}
            </p>

            <p className="c-offer-details-form-benefits-item__value">
              {details.value}
            </p>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};
