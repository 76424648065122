import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import Sentry from '../sentry';
import { jobError } from '../helpers/default-values';
import { parseUrlParams } from '../helpers/utils';
import { getJobPhases, getHorserace } from '../helpers/job-utils';

import { JobHeader } from '../components/JobHeader';
import JobSummary from '../components/JobSummary';
import JobSkeleton from '../components/skeletons/Job';
import Tabs from '../components/Tabs';
import Modal from '../components/Modal';

import EmailLogModal from '../components/EmailLogModal';
import Notice from '../components/Notice';
import JobAssignationModal from '../components/JobAssignationModal';
import { JobTabTasks } from '../components/JobTabTasks';
import { JobTabNotes } from '../components/JobTabNotes';
import { JobTabActivities } from '../components/JobTabActivities';
import { JOB_TABS } from '../constants/job';
import { TaskModal } from '../components/TaskModal';
import { MeetingList } from '../components/MeetingList';
import { apiJobApplicationsFetch } from '../api/job.legacy';
import {
  CurrentJobContext,
  CurrentJobProvider,
} from '../components/Providers/CurrentJobProvider';

const PageJob = () => (
  <CurrentJobProvider>
    <PageJobContent />
  </CurrentJobProvider>
);

const PageJobContent = () => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const [taskId, setTaskId] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [phases, setPhases] = useState(null);
  const [horserace, setHorserace] = useState(null);
  const [applications, setApplications] = useState([]);
  const [applicationsLoading, setApplicationsLoading] = useState(false);

  const { currentJob, isCurrentJobLoading } = useContext(CurrentJobContext);

  // STORE ACTIONS
  const fetchJob = useStoreActions(actions => actions.job.fetchJob);
  // const fetchJobHorserace = useStoreActions((actions) => actions.job.fetchJobHorserace)
  const updateJob = useStoreActions(actions => actions.job.updateJob);
  const setJobActivitiesLoading = useStoreActions(
    actions => actions.job.setJobActivitiesLoading,
  );
  const resetJob = useStoreActions(actions => actions.job.resetJob);

  const updateNote = useStoreActions(actions => actions.notes.updateNote);
  const deleteNote = useStoreActions(actions => actions.notes.deleteNote);
  const updateJobNotes = useStoreActions(actions => actions.job.updateJobNotes);
  const updateJobTasksListWithUpdatedTaskItem = useStoreActions(
    actions => actions.job.updateJobTasksListWithUpdatedTaskItem,
  );
  const setModalOpened = useStoreActions(actions => actions.setModalOpened);
  const setModalId = useStoreActions(actions => actions.setModalId);
  const setNotifications = useStoreActions(actions => actions.setNotifications);

  // STORE STATE
  const job = useStoreState(state => state.job.job);
  const isModalOpened = useStoreState(state => state.isModalOpened);
  const modalId = useStoreState(state => state.modalId);
  const jobLoading = useStoreState(state => state.job.isLoading);
  const error = useStoreState(state => state.job.isError);

  const tabActivitiesUrl = `${url}/${JOB_TABS.ACTIVITIES}`;

  const classNameTabsWrapper = classNames('u-bg-light', {
    'u-pb-medium': location.pathname === tabActivitiesUrl,
  });

  const tabs = [
    {
      name: url,
      text: t('tabs.tasks'),
    },
    {
      name: `${url}/${JOB_TABS.NOTES}`,
      text: t('tabs.notes'),
    },
    {
      name: tabActivitiesUrl,
      text: t('tabs.activities'),
    },
    {
      name: `${url}/${JOB_TABS.INTERVIEWS_AND_MEETINGS}`,
      text: t('tabs.interviews'),
    },
  ];

  // CALLBACKS
  const onTabClick = useCallback(
    tabName => {
      history.push(tabName);

      if (isModalOpened) {
        setModalOpened(false);
        setModalId(null);
      }

      if (tabName !== tabActivitiesUrl) {
        setJobActivitiesLoading(true);
      }
    },
    [
      history,
      tabActivitiesUrl,
      setJobActivitiesLoading,
      isModalOpened,
      setModalOpened,
      setModalId,
    ],
  );
  const handleUpdateNote = useCallback(
    e => {
      return new Promise(async (resolve, reject) => {
        if (e.body && !e.body.trim()) {
          setNotifications({
            id: `update-note-${Date.parse(new Date())}`,
            visible: true,
            types: ['warning', 'raw'],
            message: t('note.notEmpty'),
          });
          reject();
          const noteError = new Error(
            'Note not updated because some values are empty or missing',
          );
          const extra = {
            jobId: id,
            title: e.title,
            body: e.body,
          };
          Sentry.captureException(noteError, { extra });
        }
        try {
          const response = await updateNote(e);
          resolve(response);
          updateJobNotes(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    [setNotifications, t, id, updateNote, updateJobNotes],
  );
  const handleRemoveNote = useCallback(
    e => {
      deleteNote(e);
    },
    [deleteNote],
  );
  const onTaskClickHandler = useCallback(
    async id => {
      setModalOpened(true);
      setModalId('task');
      setTaskId(id);
    },
    [setModalOpened, setModalId],
  );
  const handleEmailClick = useCallback(
    e => {
      const { threadId, messageId } = e;
      setThreadId(threadId);
      setMessageId(messageId);
      setModalId('email');
      setModalOpened(true);
    },
    [setThreadId, setMessageId, setModalId, setModalOpened],
  );

  const handleFreezeJob = useCallback(() => {
    updateJob({ id: job.id, frozen: !job.frozen });
  }, [job.id, job.frozen, updateJob]);

  const defrostJob = () => {
    updateJob({ id: job.id, frozen: false });
  };

  const handleJobAssegnation = useCallback(() => {
    setModalId('job-assignation');
    setModalOpened(true);
  }, [setModalId, setModalOpened]);

  const handleAssignation = useCallback(
    e => {
      updateJob({
        id: job.id,
        deliveryManager: e.value,
      });
      setModalId(null);
      setModalOpened(false);
    },
    [setModalOpened, setModalId, updateJob, job.id],
  );

  const onTaskModalClose = () => {
    setModalOpened(false);
    setModalId(null);
    setTaskId(null);
  };

  // USE EFFECT

  useEffect(() => {
    fetchJob({ id, shouldNotify: false });
    return () => {
      // plan.io issue 9790
      if (!/\/jarvis\/jobs\/(\d){4,}/gm.test(window.location.pathname)) {
        resetJob();
      }
    };
  }, [id, fetchJob, resetJob]);

  useEffect(() => {
    const params = parseUrlParams(location.search);
    if (params.task) {
      onTaskClickHandler(params.task);
    }
  }, [location, onTaskClickHandler]);

  useEffect(() => {
    const { horserace } = getHorserace(job.applications, job.phases);
    setHorserace(horserace);
  }, [job.phases, job.applications]);

  useEffect(() => {
    if (job.phases && job.phases.length) {
      const { phases } = getJobPhases(job.phases);
      setPhases(phases);
    }
  }, [job.phases]);

  const fetchApplications = async () => {
    if (!job.uuid) {
      setApplicationsLoading(false);
      setApplications([]);
      return;
    }

    setApplicationsLoading(true);

    try {
      const response = await apiJobApplicationsFetch({ jobId: job.uuid });
      setApplications(response.results);
    } catch (error) {
      setApplications([]);
    } finally {
      setApplicationsLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, [job.uuid]);

  const suitableCandidates = applications.filter(
    application =>
      !application.isNotifiedToClient &&
      !application.isSeenByClient &&
      !application.discard,
  );

  const loading = jobLoading || applicationsLoading || isCurrentJobLoading;

  return (
    <>
      {loading ? (
        <div className="c-tasks">
          <JobSkeleton />
        </div>
      ) : error ? (
        <div className="c-tasks">
          <Notice
            type="error-partial"
            title={jobError().title}
            text={jobError().text}
          />
        </div>
      ) : (
        <>
          <div className="o-wrapper o-wrapper--vert-base-mid">
            <JobHeader
              job={job}
              suitableCandidates={suitableCandidates}
              onFreezeJobClick={handleFreezeJob}
              onAssignJobClick={handleJobAssegnation}
              onRefreshCandidates={fetchApplications}
            />

            <JobSummary
              uuid={job.uuid}
              steps={phases}
              statistics={currentJob?.statistics || null}
              horserace={{
                applications: horserace,
                job: { publish_date: job.publish_date },
              }}
              frozen={job.frozen}
              onDefrost={defrostJob}
              closedAt={job.closed_at}
              publishDate={job.publish_date}
              presentationMeetingDate={
                job.presentationMeetingScheduledAt?.localDateTime
              }
              dealId={job.deal_id}
              // onHorseraceClick={handleFetchJobHorserace}
            />
          </div>
          <div className={classNameTabsWrapper}>
            <div className="o-wrapper u-mb-base">
              <Tabs
                tabs={tabs}
                activeTab={location.pathname}
                onTabClick={onTabClick}
              />
            </div>
            <Switch>
              <Route exact path={path}>
                <JobTabTasks
                  handleUpdateNote={handleUpdateNote}
                  handleRemoveNote={handleRemoveNote}
                  onTaskClickHandler={onTaskClickHandler}
                />
              </Route>
              <Route path={`${path}/${JOB_TABS.NOTES}`}>
                <JobTabNotes
                  handleUpdateNote={handleUpdateNote}
                  handleRemoveNote={handleRemoveNote}
                />
              </Route>
              <Route path={`${path}/${JOB_TABS.ACTIVITIES}`}>
                <JobTabActivities handleEmailClick={handleEmailClick} />
              </Route>
              <Route path={`${path}/${JOB_TABS.INTERVIEWS_AND_MEETINGS}`}>
                <MeetingList />
              </Route>
            </Switch>
          </div>
        </>
      )}
      <Modal
        visible={isModalOpened && modalId === 'task'}
        modifier="c-modal--task-modal"
      >
        <TaskModal
          taskId={taskId}
          onClose={onTaskModalClose}
          onTaskUpdate={updateJobTasksListWithUpdatedTaskItem}
        />
      </Modal>
      <Modal
        isOverlayButtonVisible
        modifier={'c-modal--log-email'}
        visible={isModalOpened && modalId === 'email'}
      >
        <EmailLogModal messageId={messageId} threadId={threadId} />
      </Modal>
      <Modal
        modifier={'c-modal--job-assignment c-modal--small c-modal--auto-height'}
        visible={isModalOpened && modalId === 'job-assignation'}
      >
        <JobAssignationModal
          title={job.title}
          customer={job.customer}
          deliveryManager={job.deliveryManager}
          onAssign={handleAssignation}
        />
      </Modal>
    </>
  );
};

export default PageJob;
