import { useEffect } from 'react';
import { format } from 'date-fns';
import { apiTaskUpdate } from '../../api/task';
import GTM from '../../gtm';

export const useOpenedTask = (setTask, task) => {
  const trackReopenedTask = () => {
    const rawStorageReopenedTasks = localStorage.getItem('reopened_tasks');

    const storageReopenedTasks = !!rawStorageReopenedTasks
      ? JSON.parse(rawStorageReopenedTasks)
      : {};

    const currentTaskReopenedTimes = storageReopenedTasks[task.id] ?? 0;

    localStorage.setItem(
      'reopened_tasks',
      JSON.stringify({
        ...storageReopenedTasks,
        [task.id]: currentTaskReopenedTimes + 1,
      }),
    );

    GTM.trackEvent({
      category: 'task',
      action: 'reopen_task',
      label: `${task.job_state_progress} - ${task.type} - ${
        currentTaskReopenedTimes + 1
      } time${currentTaskReopenedTimes > 0 ? '(s)' : ''}`,
    });
  };

  const updateTaskOpenedDate = async () => {
    try {
      const updatedTask = await apiTaskUpdate({
        taskId: task.id,
        payload: {
          viewed_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          timezone_type: 3,
          timezone: 'Europe/Rome',
        },
      });

      setTask(updatedTask);
    } catch (error) {}
  };

  useEffect(() => {
    const checkOpenStatus = async () => {
      if (task.viewed_at) {
        return trackReopenedTask();
      }

      await updateTaskOpenedDate();
    };

    if (!!task) {
      checkOpenStatus();
    }
  }, [task]);
};
