import type { ObjectValues } from '../utilities/types/ObjectValues';

export const USER_LOCALES = {
  EN: 'en',
  IT: 'it',
  DE: 'de',
  FR: 'fr',
  ES: 'es',
} as const;

export type TUserLocale = ObjectValues<typeof USER_LOCALES>;
export const USER_LOCALE_LIST = Object.values(USER_LOCALES);
