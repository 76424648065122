import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TaskNote from './TaskNote';
import { Icon } from '@reverse-hr/pattern-library';
import AddNote from './AddNote';
import Notice from './Notice';
import Empty from '../assets/images/empty-notes.svg';

const TaskNotes = ({
  notes,
  job,
  compact,
  modifier,
  header,
  canAddNote,
  context,
  onCreate,
  onUpdate,
  onRemove,
}) => {
  const { t } = useTranslation();
  const addNoteRef = useRef(null);
  const [areNotes, setAreNotes] = useState(true);
  const [mappedNotes, setMappedNotes] = useState(
    notes.map(n => ({ ...n, editing: false })),
  );

  const handleCreateNote = async e => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await onCreate({ ...e, context });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleUpdateNote = e => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await onUpdate(e);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleRemoveNote = e => {
    onRemove(e);
  };

  useEffect(() => {
    setAreNotes(!!notes && notes.length);
    setMappedNotes(notes.map(n => ({ ...n, editing: false })));
  }, [notes]);

  return (
    <div
      data-testid="task-notes-container"
      className={`c-tasks c-tasks--notes ${
        !areNotes ? `c-tasks--empty` : ''
      } ${modifier}`}
      style={{ backgroundImage: `url(${!areNotes ? Empty : ''})` }}
    >
      <div className="c-tasks__header">
        {header.icon && (
          <div className="c-tasks__header-icon">
            <Icon name={header.icon} />
          </div>
        )}
        <p className="c-tasks__header-title">{header.title}</p>
      </div>

      <div
        data-testid="task-notes-wrapper"
        className={`c-tasks__container o-tasks-layout o-tasks-layout--notes ${
          !areNotes ? `o-tasks-layout--empty` : ''
        }`}
      >
        {canAddNote && (
          <div
            data-testid="task-notes-item"
            ref={addNoteRef}
            className="o-tasks-layout__item"
          >
            <AddNote job={job} onSave={handleCreateNote} />
          </div>
        )}

        {areNotes ? (
          mappedNotes
            .sort((a, b) => Number(b.favorite) - Number(a.favorite))
            .map((note, i) => {
              return (
                <div
                  key={`${note.id}-${i}`}
                  data-testid="task-notes-item"
                  className="o-tasks-layout__item"
                >
                  <TaskNote
                    id={note.id}
                    compact={compact}
                    note={note}
                    job={job}
                    editing={note.editing}
                    onUpdate={handleUpdateNote}
                    onRemove={handleRemoveNote}
                  />
                </div>
              );
            })
        ) : (
          <div className="c-tasks__lonely_message">
            <Notice
              type="notes"
              title={t('note.empty.title')}
              text={t('note.empty.text')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TaskNotes.defaultProps = {
  modifier: '',
  compact: false,
  header: {
    title: '',
    icon: null,
  },
  canAddNote: false,
};

export default TaskNotes;
