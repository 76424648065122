import React, { useState } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { SetMeetingDateForm } from '../SetMeetingDateForm';
import { ActionWithLoader } from '../ActionWithLoader';
import { JOB_SET_DATE_MODAL_TYPES } from '../../constants/job';

export const SetMeetingDateModal = ({
  children,
  initialDateValue,
  initialTimeZoneValue,
  isEditing,
  isTimeZoneActive,
  modalType,
  onChange,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.setMeetingDate',
  });

  const [date, setDate] = useState(
    format(
      initialDateValue ? new Date(initialDateValue) : new Date(),
      'yyyy-MM-dd HH:mm',
    ),
  );

  const [timeZone, setTimeZone] = useState(initialTimeZoneValue ?? null);

  const isScoutInterview =
    modalType === JOB_SET_DATE_MODAL_TYPES.SCOUT_INTERVIEW;

  const ctaIcon = isScoutInterview ? 'icn-handshake-24' : undefined;

  const datePickerProps = {
    isRequired: true,
    defaultValue: date,
    label: {
      id: 'set-meeting-date',
      text: t('dateLabel'),
    },
  };

  const timeZoneProps = isTimeZoneActive
    ? {
        isRequired: true,
        defaultValue: timeZone,
        label: {
          id: 'set-meeting-time-zone',
          text: t('timeZoneLabel'),
        },
      }
    : undefined;

  const onFormChange = event => {
    setDate(event.date);

    if (isTimeZoneActive) {
      setTimeZone(event.timeZone);
    }

    if (onChange) {
      onChange(event);
    }
  };

  const onSubmitButtonClick = async () => await onSubmit({ date, timeZone });

  return (
    <div className="c-set-meeting-date-modal">
      <header className="c-set-meeting-date-modal__header">
        <Action
          iconOnly
          icon="icn-close-big-24"
          size="small"
          type="simple-text"
          onClick={onClose}
        />
      </header>

      <div className="c-set-meeting-date-modal__content">
        <SetMeetingDateForm
          datePickerProps={datePickerProps}
          timeZoneProps={timeZoneProps}
          title={t('title', { context: modalType })}
          onChange={onFormChange}
        >
          {children}
        </SetMeetingDateForm>
      </div>

      <footer className="c-set-meeting-date-modal__footer">
        <ActionWithLoader
          label={t(`${modalType}Cta`, {
            context: isEditing ? 'editing' : null,
          })}
          onClick={onSubmitButtonClick}
          icon={ctaIcon}
        />
      </footer>
    </div>
  );
};
