import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormField, RadioButton } from '@reverse-hr/pattern-library';
import { MISSED_REASONS } from '../../../constants/applications';
import { TASK_TYPES } from '../../../constants/task';
import classNames from 'classnames';

export const TaskMissedCandidateReason = ({
  candidate,
  missedReason,
  taskType,
  onChange,
  missedReasonNotes,
  onNotesChange,
  isActive,
}) => {
  const { t } = useTranslation();

  const isVisible =
    missedReason !== MISSED_REASONS.OFFER_DECLINED ||
    taskType === TASK_TYPES.APPLICATION_PRESENT_OFFER_TO_CANDIDATE;

  const wrapperClassName = classNames('c-task-missed-candidate__reason', {
    'c-task-missed-candidate__reason--active': isActive,
  });

  const handleTextareaChange = ({ value }) => onNotesChange(value);

  return (
    isVisible && (
      <div className={wrapperClassName}>
        <RadioButton
          label={
            <Trans
              i18nKey="modals.missedCandidate.reason"
              tOptions={{ context: missedReason }}
              values={{ candidate }}
            />
          }
          id={`missed-candidate-reason-${missedReason}`}
          name="missed-candidate-reason"
          value={missedReason}
          onChange={onChange}
          checked={isActive}
        />

        {isActive && (
          <FormField
            type="textarea"
            label={t('modals.missedCandidate.notesLabel')}
            placeholder={t('modals.missedCandidate.notesPlaceholder')}
            required
            inputProps={{
              value: missedReasonNotes,
            }}
            onChange={handleTextareaChange}
          />
        )}
      </div>
    )
  );
};
