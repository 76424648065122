import React, { useContext } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { TaskModalContext } from '../../TaskModal';
import { useTranslation } from 'react-i18next';
import { ActionWithLoader } from '../../ActionWithLoader';
import { useTaskPause } from './use-task-pause';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_TYPES,
} from '../../../constants/task';

export const TaskDetailsFooter = ({
  children,
  isPauseButtonHidden,
  setIsErrorAlertVisible,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.task.footer',
  });

  const { setAlternativeView, task } = useContext(TaskModalContext);

  const { onPauseClick, pauseButtonIcon } = useTaskPause({
    setIsErrorAlertVisible,
  });

  const onMissedCandidateClick = () => {
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.MISSED_CANDIDATE);
  };

  const isMissedCandidateButtonVisible =
    !!task.application &&
    !CANDIDATE_OUT_ACTION_EXCLUDED_TASK_TYPES.includes(task.type);

  return (
    <>
      {isPauseButtonHidden ? null : (
        <ActionWithLoader
          className="c-task-details__pause"
          onClick={onPauseClick}
          type="simple-text"
          icon={pauseButtonIcon}
          label={t('pauseButton', {
            context: task.paused_at ? 'resume' : '',
          })}
        />
      )}

      {isMissedCandidateButtonVisible && (
        <Action
          type="outline"
          onClick={onMissedCandidateClick}
          label={t('missedCandidateButton', {
            candidate: task.application.candidate_full_name,
          })}
        />
      )}

      {children}
    </>
  );
};

const CANDIDATE_OUT_ACTION_EXCLUDED_TASK_TYPES = [
  TASK_TYPES.APPLICATION_HANDLE_CLIENT_DISLIKE,
  TASK_TYPES.APPLICATION_NOTIFY_CLIENT_OF_OFFER_REFUSAL,
  TASK_TYPES.APPLICATION_SET_FIRST_DAY_ON_THE_JOB,
  TASK_TYPES.APPLICATION_CHECK_IN_DAY_BEFORE_NEW_JOB,
  TASK_TYPES.APPLICATION_CHECK_IN_AFTER_FIFTEEN_DAYS_ON_NEW_JOB,
  TASK_TYPES.APPLICATION_CHECK_IN_AFTER_FIFTY_DAYS_ON_NEW_JOB,
  TASK_TYPES.JOB_PREPARE_FOR_PRESENTATION_MEETING,
];
