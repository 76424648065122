import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { ActionWithLoader } from '../../ActionWithLoader';
import { SetMeetingDateForm } from '../../SetMeetingDateForm';
import { useUpdateJobMeetings } from '../../../hooks/use-update-job-meetings';
import { JOB_SET_DATE_MODAL_TYPES } from '../../../constants/job';
import { apiApplicationScoutInterviewUpdate } from '../../../api/applications';
import { format } from 'date-fns';
import { Alert } from '@reverse-hr/pattern-library';

export const TaskSetDate = ({ modalType }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.setMeetingDate',
  });

  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectedTimeZone, setSelectedTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const { application, jobLegacy, task, onModalClose, setAlternativeTitle } =
    useContext(TaskModalContext);

  const isScoutInterview =
    modalType === JOB_SET_DATE_MODAL_TYPES.SCOUT_INTERVIEW;

  const isScoutInterviewWaitingAcceptance =
    isScoutInterview &&
    application.scoutInterview &&
    !application.scoutInterview.confirmedAt;

  const candidateFullName = !!task.application
    ? task.application.candidate_full_name
    : null;

  const ctaIcon = isScoutInterview ? 'icn-handshake-24' : undefined;

  const {
    initialDateValue: initialMeetingDateValue,
    initialTimeZoneValue: initialMeetingTimeZoneValue,
    onSubmit: onMeetingSubmit,
  } = useUpdateJobMeetings({
    job: jobLegacy,
    meetingType: modalType,
    onUpdate: onModalClose,
    onError: () => setIsErrorAlertVisible(true),
  });

  const { initialScoutInterviewDateValue, initialScoutInterviewTimeZoneValue } =
    getInitialScoutInterviewDetails(application);

  const initialDateValue = isScoutInterview
    ? initialScoutInterviewDateValue
    : initialMeetingDateValue;

  const initialTimeZoneValue = isScoutInterview
    ? initialScoutInterviewTimeZoneValue
    : initialMeetingTimeZoneValue;

  const isEditing = isScoutInterview && !!application.scoutInterview;

  const datePickerProps = {
    isRequired: true,
    defaultValue: initialDateValue,
    label: {
      id: 'set-meeting-date',
      text: t('dateLabel'),
    },
  };

  const timeZoneProps = {
    isRequired: true,
    defaultValue: initialTimeZoneValue,
    label: {
      id: 'set-meeting-time-zone',
      text: t('timeZoneLabel'),
    },
  };

  const onChange = ({ date, timeZone }) => {
    setSelectedDate(date);
    setSelectedTimeZone(timeZone);
  };

  const onErrorAlertClose = () => setIsErrorAlertVisible(false);

  const onSubmitClick = async () => {
    if (isScoutInterview) {
      return await onScoutInterviewSubmit();
    }

    await onMeetingSubmit({ date: selectedDate, timeZone: selectedTimeZone });
  };

  const onScoutInterviewSubmit = async () => {
    try {
      await apiApplicationScoutInterviewUpdate({
        applicationId: task.application.uuid,
        localDateTime: format(new Date(selectedDate), 'yyyy-MM-dd HH:mm:ss'),
        timeZone: selectedTimeZone,
      });

      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  useEffect(() => {
    setAlternativeTitle(
      t('alternativeHeaderTitle', {
        context: modalType,
        customerFullName: jobLegacy.customer.referent_name,
        candidateFullName,
      }),
    );

    return () => {
      setAlternativeTitle(null);
    };
  }, []);

  return (
    <>
      <TaskModalBody className="c-task-set-date">
        <SetMeetingDateForm
          isErrorAlertVisible={isErrorAlertVisible}
          datePickerProps={datePickerProps}
          timeZoneProps={timeZoneProps}
          title={t('title', { context: modalType })}
          onChange={onChange}
          onErrorAlertClose={onErrorAlertClose}
        >
          {isScoutInterviewWaitingAcceptance ? (
            <Alert
              type="warning"
              icon="icn-error-outline-24px"
              closable={false}
              modifier="c-alert--fluid"
            >
              <Trans
                i18nKey="modals.setMeetingDate.alert.waitingForScoutInterviewAcceptanceAlert"
                values={{
                  candidateFullName: task.application.candidate_full_name,
                  date: new Date(initialScoutInterviewDateValue),
                  timeZone: initialScoutInterviewTimeZoneValue,
                }}
              />
            </Alert>
          ) : null}
        </SetMeetingDateForm>
      </TaskModalBody>

      <TaskModalFooter>
        <ActionWithLoader
          onClick={onSubmitClick}
          label={t(`${modalType}Cta`, {
            context: isEditing ? 'editing' : null,
          })}
          icon={ctaIcon}
        />
      </TaskModalFooter>
    </>
  );
};

const getInitialScoutInterviewDetails = application => {
  const defaultMeetingDate = new Date();

  const defaultMeetingTimeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!application || !application.scoutInterview) {
    return {
      initialScoutInterviewDateValue: format(
        defaultMeetingDate,
        'yyyy-MM-dd HH:mm',
      ),
      initialScoutInterviewTimeZoneValue: defaultMeetingTimeZone,
    };
  }

  return {
    initialScoutInterviewDateValue:
      application.scoutInterview.scheduledAt.localDateTime,
    initialScoutInterviewTimeZoneValue:
      application.scoutInterview.scheduledAt.timeZone,
  };
};
