import { useApplicationClientInterviews } from './use-application-client-interviews';
import { useFetchClientInterviewFormData } from './use-fetch-client-interview-form-data';
import { format } from 'date-fns';

export const useLastClientInterviewFormData = applicationId => {
  const {
    pastInterviews,
    isLoading: isApplicationClientInterviewsFetchLoading,
  } = useApplicationClientInterviews(applicationId);

  const { formInitialData, isLoading: isLastClientInterviewFetchLoading } =
    useFetchClientInterviewFormData({
      applicationId,
      interviewId: pastInterviews?.lastInterviewId,
    });

  const isLoading =
    isApplicationClientInterviewsFetchLoading ||
    isLastClientInterviewFetchLoading;

  return {
    isLoading,
    formInitialData: {
      ...formInitialData,
      scheduledAtUtc: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      notes: null,
    },
  };
};
