import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormValidation } from './use-form-validation';
import { OfferDetailsFormDateAndDocuments } from './OfferDetailsFormDateAndDocuments';
import { OfferDetailsFormOfferValues } from './OfferDetailsFormOfferValues';
import { OfferDetailsFormSuccessFeeRecap } from './OfferDetailsFormSuccessFeeRecap';
import { OfferDetailsFormContract } from './OfferDetailsFormContract';
import { OfferDetailsFormBenefits } from './OfferDetailsFormBenefits';

export const OfferDetailsFormContext = createContext({});

export const OfferDetailsForm = ({ job, offer, onChange }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails',
  });

  const { formData, formErrors, onFormChange } = useFormValidation({
    offer: !!offer ? offer : {},
    onChange,
  });

  return (
    <div className="c-offer-details-form">
      <h2 className="c-offer-details-form__title">{t('title')}</h2>

      <OfferDetailsFormContext.Provider
        value={{
          formData,
          formErrors,
          job,
          onFormChange,
        }}
      >
        <div className="c-offer-details-form__column">
          <OfferDetailsFormDateAndDocuments />
          <OfferDetailsFormOfferValues />
          <OfferDetailsFormSuccessFeeRecap />
        </div>

        <div className="c-offer-details-form__column">
          <div className="c-offer-details-form__box">
            <OfferDetailsFormContract />
            <OfferDetailsFormBenefits />
          </div>
        </div>
      </OfferDetailsFormContext.Provider>
    </div>
  );
};
