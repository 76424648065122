import React from 'react';
import { strToPascalCase } from '../helpers/job-utils';
import classNames from 'classnames';
import { utilityGetJobCurrentPhaseClassName } from '../utilities/get-job-current-phase-class-name';

const JobSteps = ({ modifier, phases }) => {
  const currentSegmentClassName = utilityGetJobCurrentPhaseClassName(phases);

  const getSegments = (phase, phaseIndex) => {
    const arrTotal = new Array(Number(phase.steps)).fill('e');
    const isWip = index => {
      const previousStep = phases[phaseIndex - 1];
      if (!!previousStep) {
        const curr = Number(previousStep.current_step);
        const isDone = !!curr && curr === Number(previousStep.steps);
        if (index === 0 && isDone) {
          return 'c-job-steps__segment--wip';
        }
      }
      if (index !== 0 && index === Number(phase.current_step)) {
        return 'c-job-steps__segment--wip';
      }
      return '';
    };
    return arrTotal.map((s, i) => {
      return (
        <div
          key={i}
          data-testid={`job-steps-${strToPascalCase(phase.name)}-segment-${i}`}
          className={`c-job-steps__segment c-job-steps__segment--${currentSegmentClassName} ${
            i < Number(phase.current_step)
              ? 'c-job-steps__segment--completed'
              : isWip(i)
          }`}
        />
      );
    });
  };
  return (
    <div
      data-testid="job-steps-container"
      className={`c-job-steps o-horserace o-horserace--${phases.length}-steps ${modifier}`}
    >
      {phases.map((phase, i) => {
        return (
          <JobStepsPhase phase={phase} key={phase.name}>
            {getSegments(phase, i)}
          </JobStepsPhase>
        );
      })}
    </div>
  );
};

JobSteps.defaultProps = {
  modifier: '',
  phases: [],
};

export default JobSteps;

const JobStepsPhase = ({ phase, children }) => {
  const { steps, current_step } = phase;
  const isPhaseToDo = current_step === 0;
  const isPhaseInProgress = !isPhaseToDo && current_step < steps;
  const isPhaseCompleted = current_step === steps;

  const wrapperClassNames = classNames(
    'c-job-steps__item c-job-steps__item--small',
    {
      'c-job-steps__item--completed': isPhaseCompleted,
      'c-job-steps__item--in-progress': isPhaseInProgress,
      'c-job-steps__item--to-do': isPhaseToDo,
    },
  );

  return (
    <div
      data-testid={`job-step-${strToPascalCase(phase.name)}`}
      className={wrapperClassNames}
    >
      <span
        data-testid={`job-step-${strToPascalCase(phase.name)}-name`}
        className="c-job-steps__name"
      >
        {phase.name}
      </span>
      <div
        data-testid={`job-step-${strToPascalCase(phase.name)}-segments`}
        className="c-job-steps__segmentation"
      >
        {children}
      </div>
    </div>
  );
};
