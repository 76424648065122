import { authFetch } from '../utilities/session.legacy';

export const apiApplicationFetch = async ({ applicationId }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}`,
  });

export const apiApplicationUpdate = async ({
  applicationId,
  expectedSuccessDate,
  isSuccessFeeRecapVisible,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}`,
    method: 'PATCH',
    body: JSON.stringify({
      expectedSuccessDate,
      isSuccessFeeRecapVisible,
    }),
  });

export const apiApplicationDiscardUpdate = async ({
  applicationId,
  reason,
  notes,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/discard`,
    method: 'PUT',
    body: JSON.stringify({
      reason,
      notes,
    }),
  });

export const apiClientFeedbackIntoConsiderationUpdate = async ({
  applicationId,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-feedbacks/take-into-consideration`,
    method: 'POST',
  });

export const apiClientFeedbackAdditionalInterviewsUpdate = async ({
  applicationId,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-feedbacks/consider-for-additional-interviews`,
    method: 'POST',
  });

export const apiClientFeedbackOfferUpdate = async ({ applicationId }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/client-feedbacks/consider-for-an-offer`,
    method: 'POST',
  });

export const apiApplicationOfferUpdate = async ({ applicationId, offer }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/offer`,
    method: 'PUT',
    body: JSON.stringify(offer),
  });

export const apiApplicationOfferAcceptPost = async ({ applicationId }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/offer/accept`,
    method: 'POST',
  });

export const apiApplicationCustomerInterestUpdate = async ({
  applicationId,
  isCustomerInterested,
  customerInterestNotes,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/customer-interest`,
    method: 'PUT',
    body: JSON.stringify({
      isCustomerInterested,
      customerInterestNotes,
    }),
  });

export const apiApplicationScoutInterviewUpdate = async ({
  applicationId,
  localDateTime,
  timeZone,
}) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/scout-interview`,
    method: 'PUT',
    body: JSON.stringify({
      scheduledAt: {
        localDateTime,
        timeZone,
        utcDateTime: null,
      },
    }),
  });

export const apiApplicationScoutInterviewDelete = async ({ applicationId }) =>
  await authFetch({
    endpoint: `api/v1/jarvis/applications/${applicationId}/scout-interview`,
    method: 'DELETE',
  });
