import React, { useContext, useEffect, useRef, useState } from 'react';
import { OfferDetailsForm } from '../../OfferDetailsForm';
import { SwitchToggle } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { TaskClosingContext } from './TaskClosing';

export const TaskClosingOffer = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'modals.closing.steps.offer',
  });

  const wrapperRef = useRef(null);

  const [isOfferDetailsFormVisible, setIsOfferDetailsFormVisible] =
    useState(false);

  const { application, isOfferFormFocused, job, onOfferDetailsFormChange } =
    useContext(TaskClosingContext);

  const onSwitchChange = ({ target }) =>
    setIsOfferDetailsFormVisible(target.checked);

  useEffect(() => {
    if (!isOfferDetailsFormVisible) {
      onOfferDetailsFormChange({ formData: null, isValid: true });
    }
  }, [isOfferDetailsFormVisible]);

  useEffect(() => {
    setIsOfferDetailsFormVisible(!!application.offer || isOfferFormFocused);
  }, [application]);

  useEffect(() => {
    if (isOfferDetailsFormVisible && !!wrapperRef.current) {
      wrapperRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [wrapperRef, isOfferDetailsFormVisible]);

  return (
    <div className="c-task-closing-offer" ref={wrapperRef}>
      <div className="c-task-closing-offer__toggle">
        <label
          className="c-task-closing-offer__toggle-label"
          htmlFor="task-closing-offer-toggle"
        >
          {t('toggleLabel')}
        </label>

        <SwitchToggle
          id="task-closing-offer-toggle"
          offText={t('toggleOverlayTextOff')}
          onText={t('toggleOverlayTextOn')}
          onChange={onSwitchChange}
          checked={isOfferDetailsFormVisible}
        />
      </div>

      {isOfferDetailsFormVisible && !!application && (
        <OfferDetailsForm
          job={job}
          offer={application.offer}
          onChange={onOfferDetailsFormChange}
        />
      )}
    </div>
  );
};
