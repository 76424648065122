import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from '@reverse-hr/pattern-library';

const PausedTasksEmptyState = (tasks = []) => {
  const { t } = useTranslation();

  if (!tasks.length) return null;

  return (
    <div
      className="c-tasks c-tasks--pause-empty"
      data-test="pause-tasks-section-empty-state"
    >
      <div className="c-tasks__header">
        <div className="c-tasks__header-icon">
          <Icon name="icn-pause-24px" />
        </div>
        <p className="c-tasks__header-title">{t('tasks.paused')}</p>
      </div>

      <div className="c-tasks__container">
        <div className="c-tasks__text">
          <h1 className="c-tasks__question">{t('tasks.pausedEmpty.title')}</h1>
          <p className="c-tasks__answer">
            <Trans i18nKey="tasks.pausedEmpty.description">
              Quando metti un Task “in pausa” comparirà qui e potrai riattivarlo
              in qualsiasi momento.
              <strong>Potrai sempre vederlo per non dimenticarlo</strong>
              ma non starà più tra le attività che invece devi svolgere.
              <br />
              Utile no?!
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PausedTasksEmptyState;
