import React from 'react';
import { Action, Alert } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import { differenceInDays, isPast } from 'date-fns';
import { ActionWithLoader } from '../ActionWithLoader';

export const SendSuitableCandidatesModal = ({
  job,
  suitableCandidates,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(null);

  const guestsFullNames = job.customer_guests
    ? job.customer_guests.map(guest => guest.full_name)
    : [];

  const customerFullNames = [
    job.customer.referent_name,
    ...guestsFullNames,
  ].join(', ');

  const numberOfSuitableCandidates = suitableCandidates.length;

  const candidatesFullNames = suitableCandidates
    .map(suitableCandidate =>
      t('common.formatting.fullName', suitableCandidate),
    )
    .join(', ');

  const daysAfterPublish = differenceInDays(
    new Date(),
    new Date(job.publish_date),
  );

  const isPresentationMeetingPassed = job.presentationMeetingScheduledAt
    ? isPast(new Date(job.presentationMeetingScheduledAt.localDateTime))
    : false;

  const isAlertVisible = daysAfterPublish < 10 || !isPresentationMeetingPassed;

  return (
    <div className="c-send-suitable-candidates-modal">
      <header className="c-send-suitable-candidates-modal__header">
        <Action
          onClick={onClose}
          size="small"
          icon="icn-close-24px"
          type="outline"
          iconOnly
        />
      </header>

      <div className="c-send-suitable-candidates-modal__content">
        <h3 className="c-send-suitable-candidates-modal__title">
          {t('modals.sendSuitableCandidates.title', {
            count: numberOfSuitableCandidates,
          })}
        </h3>

        <p>
          <Trans
            i18nKey="modals.sendSuitableCandidates.description"
            values={{
              customerFullNames,
              candidatesFullNames,
              count: numberOfSuitableCandidates,
            }}
          />
        </p>

        {isAlertVisible && (
          <Alert
            type="warning"
            icon="icn-error-outline-24px"
            closable={false}
            modifier="c-alert--fluid c-send-suitable-candidates-modal__alert"
          >
            <Trans i18nKey="modals.sendSuitableCandidates.alert" />
          </Alert>
        )}
      </div>

      <footer className="c-send-suitable-candidates-modal__footer">
        <ActionWithLoader
          className="c-modal__footer-cta"
          label={t('modals.sendSuitableCandidates.cta', {
            count: numberOfSuitableCandidates,
          })}
          onClick={onSubmit}
        />
      </footer>
    </div>
  );
};
