import React, { useEffect, useRef, useState } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { Loader } from '../Loader';
import classNames from 'classnames';

export const ActionWithLoader = ({ className, onClick, ...actionProps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useRef(true);

  const wrapperClassNames = classNames('c-action-with-loader', className, {
    'c-action-with-loader--loading': isLoading,
  });

  const actionClassNames = classNames(
    'c-action-with-loader__action',
    actionProps.modifier,
  );

  const handleActionClick = async () => {
    setIsLoading(true);

    try {
      await onClick();
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <div className={wrapperClassNames}>
      {isLoading && <Loader className="c-action-with-loader__loader" />}

      <Action
        {...actionProps}
        modifier={actionClassNames}
        disabled={actionProps.disabled || isLoading}
        onClick={handleActionClick}
      />
    </div>
  );
};
