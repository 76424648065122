import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Parser } from 'html-to-react';
import { formatDate } from '../helpers/utils';
import { differenceInCalendarDays } from 'date-fns';

import HorseraceSkeleton from './skeletons/Horserace';
import Notice from './Notice';
import { error as errorValues } from '../helpers/default-values';

const Horserace = ({ horserace }) => {
  const { t } = useTranslation();
  const htmlParser = new Parser();
  const [numOfSteps, setNumOfSteps] = useState(5);

  const { applications, job, loading, error } = horserace;

  useEffect(() => {
    if (!!applications && applications.length) {
      setNumOfSteps(applications[0].phases.length + 1);
    }
  }, [applications]);

  return loading ? (
    <div className="c-horserace">
      <HorseraceSkeleton />
    </div>
  ) : error ? (
    <div className="c-notice--error-horserace--wrapper">
      <Notice
        type="error-general"
        title={errorValues().title}
        text={errorValues().text}
      />
    </div>
  ) : (
    <div
      className={`c-horserace c-horserace--neutral c-horserace--${numOfSteps}-steps`}
    >
      <div className="c-horserace__info">
        <p className="c-horserace__date">
          {job.publish_date
            ? formatDate(new Date(job.publish_date), 'dd MMMM yyyy')
            : ''}
        </p>
        <p className="c-horserace__date-label">
          {t('job.horserace.job-opening-date')}
        </p>
      </div>

      {applications && applications.length
        ? applications.map((application, aIndex) => {
            return (
              <div
                key={`horse-${aIndex}`}
                className={`c-horserace__lane o-horserace ${
                  application.discarded ? 'c-horserace__lane--discarded' : ''
                }`}
              >
                <div className="c-horserace__horse-info">
                  <div className="c-horserace__starting-day">
                    {htmlParser.parse(t('job.horserace.starting-day'))}
                  </div>
                  <div className="c-horserace__horsename">
                    {application.candidate}
                  </div>
                </div>

                {application.phases.map((phase, pIndex) => {
                  /**
                   * ATTENZIONE
                   * Se la phase è quella in corso e dovrebbe avere altri task, vengono mostrati in grigio come quelli dopo
                   * Se la phase è precedente a quella in corso, anche se non ci sono task (completed: null) la riga è mostrata attiva
                   */
                  const lastCompleted = phase.events
                    .map(e => !!e.completed)
                    .lastIndexOf(true);
                  return (
                    <div
                      key={`horse-step-${pIndex}`}
                      id={`horse-step-${pIndex}`}
                      className="c-horserace__phase"
                      data-phase={phase.name.toLowerCase().replace(' ', '-')}
                      data-step={`horse-step-${pIndex}`}
                    >
                      {!!phase.events && (
                        <ul className="c-horserace__segmentation">
                          {phase.events.map(({ completed }, eIndex) => {
                            const active = pIndex <= application.active_step;
                            const lastEventsInActiveStep =
                              pIndex === application.active_step &&
                              eIndex > lastCompleted;
                            const isDiscarded =
                              pIndex === application.active_step &&
                              application.discarded &&
                              eIndex === lastCompleted;
                            return (
                              <li
                                key={`horse-step-event-${eIndex}`}
                                id={`horse-step-event-${eIndex}`}
                                className={`c-horserace__segment ${
                                  active &&
                                  !lastEventsInActiveStep &&
                                  !isDiscarded
                                    ? 'c-horserace__segment--neutral'
                                    : ''
                                } ${
                                  isDiscarded
                                    ? 'c-horserace__segment--discarded'
                                    : ''
                                }`}
                              >
                                {active ? (
                                  completed ? (
                                    <div
                                      aria-label={`${formatDate(
                                        new Date(completed.date),
                                        'dd MMMM yyyy',
                                      )}${
                                        isDiscarded
                                          ? ''
                                          : ` - ${completed.description}`
                                      }`}
                                      data-balloon-pos="down"
                                      data-balloon-length={
                                        isDiscarded ? 'medium' : 'large'
                                      }
                                    >
                                      <div className="c-horserace__event">
                                        <span className="c-horserace__event-day">
                                          {differenceInCalendarDays(
                                            new Date(completed.date),
                                            new Date(job.publish_date),
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : lastEventsInActiveStep ? (
                                    <div className="c-horserace__event"></div>
                                  ) : (
                                    <div></div>
                                  )
                                ) : (
                                  <div>
                                    {/** palla grigia */}
                                    <div className="c-horserace__event"></div>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })
        : null}
    </div>
  );
};

Horserace.defaultProps = {
  horserace: {},
  job: {},
  loading: false,
};

export default Horserace;
