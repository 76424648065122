import { useEffect } from 'react';

export const useClickOutside = (element, callback) => {
  const handleClick = event => {
    const targetElement = event.target;

    if (element && !element.contains(targetElement)) {
      callback(event);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', handleClick);
    }, 1);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [element, callback]);

  return { element, callback };
};
