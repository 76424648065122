import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Action, Alert } from '@reverse-hr/pattern-library';
import { apiJobShareDelete } from '../../api/share';
import { ActionWithLoader } from '../ActionWithLoader';
import type { TShareEmployerViewRecipientToRemove } from './ShareEmployerView';
import type { TJobLegacy } from '../../models/Job';

interface TShareEmployerViewRemoveRecipientProps {
  jobUuid: TJobLegacy['uuid'];
  onClose: () => void;
  onRecipientRemoved: () => void;
  recipient: TShareEmployerViewRecipientToRemove;
}

export const ShareEmployerViewRemoveRecipient: FC<
  TShareEmployerViewRemoveRecipientProps
> = ({ jobUuid, onClose, onRecipientRemoved, recipient }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'modals.shareEmployerView.removeRecipient',
  });

  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const removeRecipient = async () => {
    try {
      await apiJobShareDelete({ jobId: jobUuid, shareId: recipient.shareId });
      await onRecipientRemoved();
      onClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return (
    <div className="c-share-employer-view-remove-recipient">
      {isErrorAlertVisible && (
        <Alert
          modifier="c-alert--fixed"
          onClose={() => setIsErrorAlertVisible(false)}
          type="negative"
          icon="icn-error-outline-24px"
        >
          {t('error')}
        </Alert>
      )}

      <h2 className="c-share-employer-view-remove-recipient__title">
        {t('title')}
      </h2>

      <p className="c-share-employer-view-remove-recipient__content">
        {t('content', '', {
          recipientFullName: recipient.fullName,
        })}
      </p>

      <footer className="c-share-employer-view-remove-recipient__footer">
        <ActionWithLoader
          label={t('confirmCta')}
          className="c-share-employer-view-remove-recipient__action"
          onClick={removeRecipient}
          type="outline"
        />

        <Action
          label={t('cancelCta')}
          modifier="c-share-employer-view-remove-recipient__action"
          onClick={onClose}
        />
      </footer>
    </div>
  );
};
