import React from 'react';
import ContentLoader from 'react-content-loader';

const HorseraceSkeleton = () => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <ContentLoader
        viewBox="0 0 500 100"
        backgroundColor="rgb(231, 231, 232)"
        foregroundColor="rgb(207, 207, 208)"
      >
        <rect x="5" y="4" width="8" height="7" />
        <rect x="15" y="4" width="12" height="7" />
        <rect x="29" y="4" width="15" height="7" />

        <rect x="50" y="5" width="50" height="6" />

        <rect x="140" y="25" width="1" height="80" />
        <rect x="207" y="25" width="1" height="80" />
        <rect x="295" y="25" width="1" height="80" />
        <rect x="362" y="25" width="1" height="80" />

        {/**ROW */}
        <rect x="0" y="35" width="104" height="1" />
        <rect x="50" y="26" width="20" height="6" />
        <rect x="72" y="35" width="1" height="10" />
        <rect x="75" y="40" width="14" height="5" />
        <rect x="90" y="40" width="17" height="5" />

        <rect x="105" y="26" width="6" height="6" />
        <rect x="152" y="26" width="6" height="6" />
        <rect x="188" y="26" width="6" height="6" />
        <rect x="225" y="26" width="6" height="6" />
        <rect x="248" y="26" width="6" height="6" />
        <rect x="270" y="26" width="6" height="6" />
        <rect x="307" y="26" width="6" height="6" />
        <rect x="345" y="26" width="6" height="6" />

        <circle cx="108" cy="36" r="2" />
        <rect x="112" y="35" width="40" height="1" />
        <circle cx="155" cy="36" r="2" />
        <rect x="158" y="35" width="30" height="1" />
        <circle cx="191" cy="36" r="2" />
        <rect x="194" y="35" width="31" height="1" />
        <circle cx="228" cy="36" r="2" />
        <rect x="231" y="35" width="16" height="1" />
        <circle cx="251" cy="36" r="2" />
        <rect x="254" y="35" width="16" height="1" />
        <circle cx="273" cy="36" r="2" />
        <rect x="276" y="35" width="30" height="1" />
        <circle cx="310" cy="36" r="2" />
        <rect x="314" y="35" width="30" height="1" />
        <circle cx="348" cy="36" r="2" />
        <rect x="351" y="35" width="150" height="1" />

        {/**ROW */}
        <rect x="0" y="65" width="104" height="1" />
        <rect x="50" y="56" width="20" height="6" />
        <rect x="72" y="65" width="1" height="10" />
        <rect x="75" y="70" width="14" height="5" />
        <rect x="90" y="70" width="17" height="5" />

        <rect x="105" y="56" width="6" height="6" />
        <rect x="152" y="56" width="6" height="6" />
        <rect x="188" y="56" width="6" height="6" />
        <rect x="225" y="56" width="6" height="6" />
        <rect x="248" y="56" width="6" height="6" />
        <rect x="270" y="56" width="6" height="6" />
        <rect x="307" y="56" width="6" height="6" />
        <rect x="345" y="56" width="6" height="6" />

        <circle cx="108" cy="66" r="2" />
        <rect x="112" y="65" width="40" height="1" />
        <circle cx="155" cy="66" r="2" />
        <rect x="158" y="65" width="30" height="1" />
        <circle cx="191" cy="66" r="2" />
        <rect x="194" y="65" width="31" height="1" />
        <circle cx="228" cy="66" r="2" />
        <rect x="231" y="65" width="16" height="1" />
        <circle cx="251" cy="66" r="2" />
        <rect x="254" y="65" width="16" height="1" />
        <circle cx="273" cy="66" r="2" />
        <rect x="276" y="65" width="30" height="1" />
        <circle cx="310" cy="66" r="2" />
        <rect x="314" y="65" width="30" height="1" />
        <circle cx="348" cy="66" r="2" />
        <rect x="351" y="65" width="150" height="1" />
      </ContentLoader>
    </div>
  );
};

export default HorseraceSkeleton;
