import { useEffect, useState } from 'react';
import { apiApplicationOfferUpdate } from '../../../api/applications';
import { CLIENT_FEEDBACK_TYPES } from '../../../constants/applications';
import { useTranslation } from 'react-i18next';

export const useOfferDetails = (application, currentStep, setAlertError) => {
  const { t } = useTranslation();
  const [offerDetailsFormData, setOfferDetailsFormData] = useState(null);
  const [isOfferDetailsFormValid, setIsOfferDetailsFormValid] = useState(true);

  const onOfferDetailsFormChange = ({ formData, isValid }) => {
    setOfferDetailsFormData(formData);
    setIsOfferDetailsFormValid(isValid);
  };

  const onOfferDetailsFormSubmit = async () => {
    if (!isOfferDetailsFormValid || !offerDetailsFormData) {
      return;
    }

    try {
      await apiApplicationOfferUpdate({
        applicationId: application.id,
        offer: offerDetailsFormData,
      });
    } catch (error) {
      if (!error.errors || !error.errors[0].message) {
        return setAlertError(t('errors.generic'));
      }

      setAlertError(t(error.errors[0].message, t('errors.generic')));
    }
  };

  useEffect(() => {
    setOfferDetailsFormData(application.offer || {});
  }, [application]);

  useEffect(() => {
    if (currentStep !== CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_AN_OFFER) {
      setOfferDetailsFormData(null);
      setIsOfferDetailsFormValid(true);
    }
  }, [currentStep]);

  return {
    isOfferDetailsFormValid,
    onOfferDetailsFormChange,
    onOfferDetailsFormSubmit,
  };
};
