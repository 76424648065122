export const MISSED_REASONS = {
  REJECTED: 'rejected',
  WITHDRAWN: 'withdrawn',
  OFFER_DECLINED: 'offer_declined',
};

export const MISSED_REASON_LIST = Object.values(MISSED_REASONS);

export const CLIENT_FEEDBACK_TYPES = {
  TAKEN_INTO_CONSIDERATION: 'taken_into_consideration',
  CONSIDERED_FOR_ADDITIONAL_INTERVIEWS: 'considered_for_additional_interviews',
  CONSIDERED_FOR_AN_OFFER: 'considered_for_an_offer',
};

const CLOSING_STEPS_KEYS = {
  RECRUITING: 'recruiting',
  INTERVIEW: 'interview',
  OFFER: 'offer',
};

export const CLOSING_STEP_KEY_LIST = Object.values(CLOSING_STEPS_KEYS);

export const CLOSING_STEP_KEY_MAP_TO_CLIENT_FEEDBACK_TYPE = {
  [CLOSING_STEPS_KEYS.RECRUITING]:
    CLIENT_FEEDBACK_TYPES.TAKEN_INTO_CONSIDERATION,
  [CLOSING_STEPS_KEYS.INTERVIEW]:
    CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_ADDITIONAL_INTERVIEWS,
  [CLOSING_STEPS_KEYS.OFFER]: CLIENT_FEEDBACK_TYPES.CONSIDERED_FOR_AN_OFFER,
};

export const CLOSING_OFFER_DETAILS_KEYS = {
  MADE_ON: 'madeOn',
  RESIGNATION_LETTER: 'resignationLetter',
  COMMITMENT_LETTER: 'commitmentLetter',
  SALARY_VALUE: 'salaryValue',
  NUMBER_OF_SALARY_FRACTIONS: 'numberOfSalaryFractions',
  SUCCESS_FEE_PERCENTAGE_OF_COMPENSATION_PACKAGE:
    'successFeePercentageOfCompensationPackage',
  ALREADY_INVOICED_RETAINER_FEE: 'alreadyInvoicedRetainerFee',
  ALREADY_INVOICED_CONFIDENCE_FEE: 'alreadyInvoicedConfidenceFee',
  DISCOUNT_PERCENTAGE: 'discountPercentage',
  IS_SUCCESS_FEE_RECAP_VISIBLE: 'isSuccessFeeRecapVisible',
  INCLUDES_COMPANY_CAR: 'includesCompanyCar',
  INCLUDES_HEALTH_INSURANCE: 'includesHealthInsurance',
  INCLUDES_STOCKS: 'includesStocks',
  ACCOMMODATION_MONTHS: 'accommodationMonths',
  BONUS_DESCRIPTION: 'bonusDescription',
  BONUS_AMOUNT: 'bonusAmount',
};

export const CLOSING_OFFER_DETAIL_KEY_LIST = Object.values(
  CLOSING_OFFER_DETAILS_KEYS,
);
