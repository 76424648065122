import type { FC } from 'react';
import React, { forwardRef, useState } from 'react';
import { Action } from '@reverse-hr/pattern-library';
import { JraSlidesGenerationModalGeneration } from './JraSlidesGenerationModalGeneration';
import { JraSlidesGenerationModalRegenerate } from './JraSlidesGenerationModalRegenerate';

interface TJraSlidesGenerationModalProps {
  onClose: () => void;
  presentationMeetingSlidesGeneratedAt: string;
}

export const JraSlidesGenerationModal: FC<TJraSlidesGenerationModalProps> =
  forwardRef(({ onClose, presentationMeetingSlidesGeneratedAt }, _ref) => {
    const [isGenerateModalVisible, setIsGenerateModalVisible] = useState(
      !presentationMeetingSlidesGeneratedAt,
    );

    const onRegenerateClick = () => setIsGenerateModalVisible(true);

    return (
      <div className="c-jra-slides-generation-modal">
        <header className="c-jra-slides-generation-modal__header">
          <Action
            onClick={onClose}
            size="small"
            icon="icn-close-24px"
            type="outline"
            iconOnly
          />
        </header>

        {isGenerateModalVisible ? (
          <JraSlidesGenerationModalGeneration />
        ) : (
          <JraSlidesGenerationModalRegenerate
            onCancelClick={onClose}
            onRegenerateClick={onRegenerateClick}
            presentationMeetingSlidesGeneratedAt={
              presentationMeetingSlidesGeneratedAt
            }
          />
        )}
      </div>
    );
  });
