import type { FC } from 'react';
import React, { useContext, useState } from 'react';
import { JraSlidesGeneration } from '../../JraSlidesGeneration';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { Action, LinkWrapper } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import type { TJob } from '../../../models/Job';
import { Loader } from '../../Loader';

export const TaskJraSlidesGeneration: FC = () => {
  const { t } = useTranslation();
  const [isSlidesLinkDisabled, setIsSlidesLinkDisabled] = useState(true);

  const [isRegenerateButtonClicked, setIsIsRegenerateButtonClicked] =
    useState(false);

  const { job, setAlternativeView, setJob } = useContext(TaskModalContext);

  const onSlidesGenerated = (updatedJob: TJob) => {
    setJob(updatedJob);
    setIsSlidesLinkDisabled(false);
  };

  const isSlidesGenerationVisible =
    job &&
    (!job.presentationMeetingSlidesGeneratedAt || isRegenerateButtonClicked);

  const onCancelClick = () => setAlternativeView(null);
  const onRegenerateClick = () => setIsIsRegenerateButtonClicked(true);

  return job ? (
    <>
      <TaskModalBody className="c-task-jra-slides-generation">
        {isSlidesGenerationVisible ? (
          <JraSlidesGeneration
            jobId={job.id}
            onSlidesGenerated={onSlidesGenerated}
            presentationMeetingScheduledAt={job.presentationMeetingScheduledAt}
          />
        ) : (
          <div className="c-task-jra-slides-generation__regenerate">
            <h2 className="c-task-jra-slides-generation__title">
              {t('modals.jraSlidesGeneration.regenerate.title')}
            </h2>

            <p className="c-task-jra-slides-generation__subtitle">
              <Trans
                i18nKey="modals.jraSlidesGeneration.regenerate.content"
                values={{
                  date: new Date(
                    job.presentationMeetingSlidesGeneratedAt ?? '',
                  ),
                }}
              />
            </p>
          </div>
        )}
      </TaskModalBody>

      <TaskModalFooter>
        {isSlidesGenerationVisible ? (
          <>
            {isSlidesLinkDisabled ? (
              <div className="c-task-jra-slides-generation__loader">
                <Loader />
              </div>
            ) : (
              <LinkWrapper
                label={t('modals.jraSlidesGeneration.cta')}
                type="primary"
                external
                target="_blank"
                url={job.presentationMeetingSlidesUrl ?? ''}
              />
            )}
          </>
        ) : (
          <>
            <Action
              label={t('modals.jraSlidesGeneration.regenerate.ctaCancel')}
              onClick={onCancelClick}
              type="outline"
            />

            <Action
              label={t('modals.jraSlidesGeneration.regenerate.ctaRegenerate')}
              onClick={onRegenerateClick}
              type="primary"
            />
          </>
        )}
      </TaskModalFooter>
    </>
  ) : null;
};
