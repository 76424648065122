import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { apiApplicationUpdate } from '../../../api/applications';
import { useTranslation } from 'react-i18next';

export const useExpectedSuccessDate = (application, setAlertError) => {
  const { t } = useTranslation();
  const [expectedSuccessDate, setExpectedSuccessDate] = useState('');

  const onExpectedSuccessDateChange = selectedDate => {
    if (!selectedDate) {
      return setExpectedSuccessDate('');
    }

    setExpectedSuccessDate(format(new Date(selectedDate), 'yyyy-MM-dd'));
  };

  const onExpectedSuccessDateSubmit = async () => {
    try {
      await apiApplicationUpdate({
        applicationId: application.id,
        expectedSuccessDate: expectedSuccessDate
          ? format(new Date(expectedSuccessDate), 'yyyy-MM-dd')
          : null,
      });
    } catch (error) {
      setAlertError(t('errors.generic'));
    }
  };

  useEffect(() => {
    onExpectedSuccessDateChange(application.expectedSuccessDate);
  }, [application]);

  return {
    expectedSuccessDate,
    onExpectedSuccessDateChange,
    onExpectedSuccessDateSubmit,
  };
};
