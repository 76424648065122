import React from 'react';
import NavItem from './NavItem';

const Navigation = ({ items, loading, error, modifier }) => {
  return (
    <nav className={`c-navigation ${modifier}`}>
      <ul className="c-navigation__main-list">
        {items &&
          Object.keys(items).map((item, i) => {
            return (
              <li
                data-testid="navigation-nav-item"
                key={`item-${items[item].modifier}`}
                className={`c-navigation__list-item main`}
              >
                <NavItem {...items[item]} loading={loading} error={error} />
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

Navigation.defaultProps = {
  modifier: '',
};

export default Navigation;
