import React from 'react';
import { useTranslation } from 'react-i18next';
import { Action, Avatar, Icon } from '@reverse-hr/pattern-library';
import { selectorJobState } from '../../selectors/job/selectors';
import { useStoreState } from 'easy-peasy';
import { MEETING_TYPES } from '../../constants/job';
import classNames from 'classnames';

export const MeetingListItem = ({
  meeting,
  onEditClientInterviewClick,
  onEditScoutInterviewClick,
  onEditMeetingClick,
  onDeleteMeetingClick,
}) => {
  const { t } = useTranslation();
  const { job } = useStoreState(selectorJobState);

  const scheduleDate = new Date(meeting.scheduledAt.localDateTime);

  const isInterviewMeetingType = INTERVIEWS_MEETING_TYPES.includes(
    meeting.type,
  );

  const editInterviewClassNames = classNames('c-meeting-list__item-action', {
    'c-meeting-list__item-action--edit-meeting': !isInterviewMeetingType,
  });

  const handleOnEditMeetingClick = () => {
    if (meeting.type === MEETING_TYPES.CLIENT_INTERVIEW) {
      return onEditClientInterviewClick({
        applicationId: meeting.applicationId,
        interviewId: meeting.clientInterviewId,
        candidateFullName: meeting.candidateFullName,
      });
    }

    if (meeting.type === MEETING_TYPES.SCOUT_INTERVIEW) {
      return onEditScoutInterviewClick(meeting);
    }

    onEditMeetingClick(meeting.type);
  };

  const handleOnDeleteMeetingClick = () =>
    onDeleteMeetingClick({
      applicationId: meeting.applicationId,
      interviewId: meeting.clientInterviewId,
    });

  return (
    <li className="c-meeting-list__item">
      <div className="c-meeting-list__item-info">
        <div className="c-meeting-list__item-scheduled-at">
          <p className="c-meeting-list__item-time">
            {t('common.formatting.date.time', { time: scheduleDate })}
          </p>

          <p className="c-meeting-list__item-date">
            <strong>
              {t('common.formatting.date.date', { date: scheduleDate })}
            </strong>
          </p>
        </div>

        <Icon
          modifier="c-meeting-list__item-icon"
          name="icn-calendar-event-24"
          size={24}
        />

        <div className="c-meeting-list__item-description">
          {isInterviewMeetingType ? null : (
            <Avatar avatar={job.deliveryManager.icon} />
          )}

          <p>
            {t('job.meetingList.description', {
              candidateFullName: meeting.candidateFullName,
              context: meeting.type,
            })}
          </p>
        </div>
      </div>

      <div className="c-meeting-list__item-actions-list">
        {isInterviewMeetingType && (
          <Action
            modifier="c-meeting-list__item-action"
            label={t('job.meetingList.deleteItemAction')}
            size="small"
            type="simple-text"
            shadow={false}
            icon="icn-trash-full-24px"
            iconPosition="right"
            onClick={handleOnDeleteMeetingClick}
          />
        )}

        <Action
          modifier={editInterviewClassNames}
          label={t('job.meetingList.editItemAction')}
          size="small"
          type="outline"
          onClick={handleOnEditMeetingClick}
        />
      </div>
    </li>
  );
};

const INTERVIEWS_MEETING_TYPES = [
  MEETING_TYPES.CLIENT_INTERVIEW,
  MEETING_TYPES.SCOUT_INTERVIEW,
];
