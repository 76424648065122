import { action, computed, thunk } from 'easy-peasy';
import i18next from 'i18next';

export default {
  // state
  jobs: [],
  stats: null,
  jobsOrder: 'publish_date', // 'publish_date', 'job_state_progress', 'stale'
  isLoading: true,
  isError: false,
  teamsAndManagers: {},
  teamsAndManagersLoading: true,
  teamsAndManagersError: false,
  // actions
  setJobs: action((state, payload) => {
    state.jobs = payload;
  }),
  setStats: action((state, payload) => {
    state.stats = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setIsError: action((state, payload) => {
    state.isError = payload;
  }),
  setJobsOrder: action((state, payload) => {
    state.jobsOrder = payload;
  }),
  setTeamsAndManagers: action((state, payload) => {
    state.teamsAndManagers = payload;
  }),
  setTeamsAndManagersLoading: action((state, payload) => {
    state.teamsAndManagersLoading = payload;
  }),
  setTeamsAndManagersError: action((state, payload) => {
    state.teamsAndManagersError = payload;
  }),

  // thunks
  fetchJobs: thunk(
    async (
      actions,
      _payload,
      { getStoreState, getStoreActions, injections },
    ) => {
      const { jobsService } = injections;
      const {
        navigation: {
          navigation: {
            items: {
              jobs: { subitems },
            },
          },
        },
      } = getStoreState();

      try {
        const fetchedJobs = await jobsService.getAllJobs();
        if (fetchedJobs) {
          actions.setIsError(false);
          actions.setJobs(fetchedJobs.jobs);
          if (fetchedJobs.stats) {
            const stats = Object.entries(fetchedJobs.stats)
              .filter(([_key, value]) => !!value)
              .map(([key, value]) => {
                return {
                  value:
                    key === 'jarvis_usage'
                      ? `${value}%`
                      : value.toLocaleString(),
                  label: i18next.t(`all-jobs.stats.${key}`),
                };
              });

            actions.setStats(stats);
          }
        }
        if (!subitems.length) {
          getStoreActions().navigation.fetchJobs();
        }
      } catch (error) {
        console.warn(error);
        actions.setIsError(true);
      } finally {
        actions.setIsLoading(false);
      }
    },
  ),

  fetchManagersAndTeams: thunk(async (actions, _payload, { injections }) => {
    const { jobsService } = injections;
    return new Promise(async (resolve, reject) => {
      const managersPromise = jobsService.getManagers();
      const teamsPromise = jobsService.getTeams();
      try {
        const [managers, teams] = await Promise.all([
          managersPromise,
          teamsPromise,
        ]);
        actions.setTeamsAndManagers({ teams, managers });
        resolve('ok');
      } catch (error) {
        actions.setTeamsAndManagersError(true);
        console.warn(error);
        reject('ko');
      } finally {
        actions.setTeamsAndManagersLoading(false);
      }
    });
  }),
  // computed
  orderedJobs: computed(({ jobs, jobsOrder }) => {
    const jobsToOrder = [...jobs.filter(job => !job.frozen)];
    const frozenJobs = [...jobs.filter(job => job.frozen)];

    const ordering = {
      publish_date: 'publish_date',
      job_state_progress: 'job_state_progress_index',
      stale: 'days_of_stalling',
    };
    const defaultPublishDate = j => {
      return j.publish_date && j.publish_date ? Date.parse(j.publish_date) : 0;
    };
    const ordered = jobsToOrder.sort((a, b) => {
      if (jobsOrder === 'publish_date') {
        return defaultPublishDate(b) - defaultPublishDate(a);
      }
      return b[ordering[jobsOrder]] - a[ordering[jobsOrder]];
    });
    return [...ordered, ...frozenJobs];
  }),
};
