import React from 'react';
import { formatDate } from '../helpers/utils';
import { TaskNoteActionMenu } from './TaskNote/TaskNoteActionMenu';
import { Action, Avatar } from '@reverse-hr/pattern-library';
import classNames from 'classnames';

const TaskHandle = ({
  modifier,
  phase,
  date,
  manager,
  favorite,
  onEdit = () => {},
  onRemove = () => {},
  onFavorite,
}) => {
  const favouriteButtonClassNames = classNames(
    'c-icon-toggle c-icon-toggle--favorite',
    {
      checked: favorite,
    },
  );

  return (
    <div className={`c-task-handle ${modifier}`}>
      <div className="c-task-handle__caption">
        <p data-testid="task-handle-phase" className="c-task-handle__phase">
          {phase}
        </p>
        <span data-testid="task-handle-date" className="c-task-handle__date">
          {formatDate(new Date(date), 'dd/MM/yyyy HH:mm')}
        </span>
      </div>

      <div className="c-task-handle__actions">
        <Avatar avatar={manager.icon} size={24} modifier="action-item" />

        <div className="action-item">
          <Action
            modifier={favouriteButtonClassNames}
            size="small"
            icon="icn-favorite-24px"
            type="simple-text"
            onClick={() => onFavorite(!favorite)}
            iconOnly
          />
        </div>

        <div className="action-item">
          <TaskNoteActionMenu onRemove={onRemove} onEdit={onEdit} />
        </div>
      </div>
    </div>
  );
};

TaskHandle.defaultProps = {
  modifier: '',
};

export default TaskHandle;
