import { useContext, useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { selectorJobState } from '../../selectors/job/selectors';
import { useParams } from 'react-router-dom';
import { apiJobFetch, apiV1JobFetch } from '../../api/job.legacy';
import { CurrentJobContext } from '../Providers/CurrentJobProvider';

export const useFetchJob = ({ task }) => {
  const [isJobFetching, setIsJobFetching] = useState(false);
  const [isJobLegacyFetching, setIsJobLegacyFetching] = useState(false);
  const [jobLegacy, setJobLegacy] = useState(null);
  const [job, setJob] = useState(null);
  const { id: queryJobId } = useParams();
  const { job: storeJob } = useStoreState(selectorJobState);
  const { currentJob, isCurrentJobLoading } = useContext(CurrentJobContext);

  const isPageLoading = !task || !jobLegacy || !job;
  const isJobLoading = isJobFetching || isJobLegacyFetching || isPageLoading;

  useEffect(() => {
    const fetchJobLegacy = async () => {
      if (!task || !!jobLegacy || isJobLegacyFetching) {
        return;
      }

      if (!!queryJobId && queryJobId === task.job.id) {
        return;
      }

      if (!!storeJob.id && storeJob.id === task.job.id) {
        return;
      }

      try {
        setIsJobLegacyFetching(true);

        const fetchedJob = await apiJobFetch(task.job.id);

        setJobLegacy(fetchedJob);
      } finally {
        setIsJobLegacyFetching(false);
      }
    };

    fetchJobLegacy();
  }, [task, queryJobId, storeJob.id]);

  useEffect(() => {
    if (!!storeJob && !!task && storeJob.id === task.job.id) {
      setJobLegacy(storeJob);
      setIsJobLegacyFetching(false);
    }
  }, [task, storeJob]);

  useEffect(() => {
    const fetchJob = async () => {
      if (!jobLegacy) {
        return;
      }

      if (currentJob && jobLegacy.uuid === currentJob.id) {
        setJob(currentJob);
        setIsJobFetching(false);
        return;
      }

      try {
        setIsJobFetching(true);

        const fetchedJob = await apiV1JobFetch(jobLegacy.uuid);

        setJob(fetchedJob);
      } finally {
        setIsJobFetching(false);
      }
    };

    if (!!jobLegacy && !isCurrentJobLoading) {
      fetchJob();
    }
  }, [jobLegacy, isCurrentJobLoading]);

  return { isJobLoading, job, jobLegacy, setJob };
};
