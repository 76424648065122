import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskNoteForm } from './TaskNote/TaskNoteForm';
import { AddNotePicture } from './AddNote/AddNotePicture';

const AddNote = ({ modifier, job, focused, onSave = () => {} }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState(focused);

  const handleClick = e => {
    setActive(true);
  };
  const handleSave = async e => {
    try {
      const response = await onSave({
        ...e,
        job_id: job.id,
        context: 'job',
      });
      if (response) {
        setActive(false);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div
      data-testid="add-note"
      onClick={handleClick}
      className={`c-add-note ${modifier}`}
    >
      <div className="c-add-note__body">
        <p data-testid="add-note-label" className="c-add-note__label">
          {t('note.label')}
        </p>
        {!active && (
          <p
            data-testid="add-note-placeholder"
            className="c-add-note__placeholder"
          >
            {t('note.placeholder')}
          </p>
        )}

        {active && <TaskNoteForm handleSave={handleSave} />}
      </div>

      {active ? null : (
        <div className="c-add-note__footer">
          <AddNotePicture className="c-add-note__background" />
        </div>
      )}
    </div>
  );
};

AddNote.defaultProps = {
  modifier: '',
  focused: false,
  title: '',
};

export default AddNote;
