import React, { useRef } from 'react';
import { Icon } from '@reverse-hr/pattern-library';
import Task from './Task';
import TasksSkeleton from './skeletons/Tasks';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import TaskListType from '../constants/tasks/taskListType';
import PausedTasksEmptyState from './PausedTasksEmptyState';

const Tasks = ({
  header,
  scrollable,
  modifier,
  tasks,
  loading,
  error,
  onTaskClick,
  type,
  updateTasks,
  jobsDictionary,
}) => {
  const renderTask = () => {
    return tasks.map((t, i) => {
      return (
        <div
          key={`${t.title}-${i}`}
          className={scrollable ? 'o-slider__item' : 'o-tasks-layout__item'}
        >
          <Task
            taskParentType={type}
            updateTasks={updateTasks}
            onClick={onTaskClick}
            titleData={jobsDictionary[t.job.id]}
            {...t}
          />
        </div>
      );
    });
  };

  const renderPausedTaskListEmptyState = () =>
    type === TaskListType.PAUSED && <PausedTasksEmptyState tasks={tasks} />;

  const nodeRef = useRef(null);

  return (
    <>
      {loading ? (
        <div
          className={`c-tasks ${
            scrollable ? 'c-tasks--scrollable ' : ''
          }${modifier}`}
        >
          <TasksSkeleton />
        </div>
      ) : (
        <SwitchTransition mode="out-in">
          <CSSTransition
            nodeRef={nodeRef}
            key={loading}
            timeout={100}
            classNames="fade-slide"
          >
            {loading ? (
              <div ref={nodeRef}>
                <TasksSkeleton />
              </div>
            ) : error ? (
              <div ref={nodeRef}>
                Sorry there was an error loading the tasks
              </div>
            ) : !!tasks && tasks.length ? (
              <div
                ref={nodeRef}
                className={`c-tasks ${
                  scrollable ? 'c-tasks--scrollable ' : ''
                }${modifier}`}
                data-test={`${type}-tasks-wrapper`}
              >
                <div className="c-tasks__header">
                  <div className="c-tasks__header-icon">
                    <Icon name={header.icon} />
                  </div>
                  <p
                    data-testid="tasks-header-label"
                    className="c-tasks__header-title"
                  >
                    {header.title}
                  </p>
                </div>
                <div
                  className={`c-tasks__container ${
                    scrollable ? 'o-slider' : 'o-tasks-layout'
                  }`}
                  data-testid="tasks-container"
                >
                  {renderTask()}
                </div>
              </div>
            ) : (
              <>{renderPausedTaskListEmptyState()}</>
            )}
          </CSSTransition>
        </SwitchTransition>
      )}
    </>
  );
};

Tasks.defaultProps = {
  modifier: '',
  scrollable: false,
  header: {
    icon: 'icn-hot-24px',
    title: 'Hot',
  },
  onTaskClick: () => {},
  onTaskReactivate: () => {},
};

export default Tasks;
