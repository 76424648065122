import React, { useContext, useState } from 'react';
import { TaskModalContext } from '../../TaskModal';
import { TaskDetails } from '../TaskDetails';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_PROMPT_TYPES,
} from '../../../constants/task';
import { Trans, useTranslation } from 'react-i18next';
import { Action } from '@reverse-hr/pattern-library';

export const TaskApplicationPresentOfferToCandidate = () => {
  const { t } = useTranslation();
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const { setAlternativeView, task } = useContext(TaskModalContext);

  const prompts = [
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskApplicationPresentOfferToCandidateClosingPrompt />,
    },
    ...task.prompts,
  ];

  const onScheduleMeetingClick = () => {
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.BINGO);
  };

  return (
    <TaskDetails
      isErrorAlertVisible={isErrorAlertVisible}
      prompts={prompts}
      title={null}
      setIsErrorAlertVisible={setIsErrorAlertVisible}
    >
      <Action
        onClick={onScheduleMeetingClick}
        icon="icn-circle-check-24px"
        iconPosition="right"
        label={t('task.detailsView.applicationPresentOfferToCandidate.cta')}
      />
    </TaskDetails>
  );
};

const TaskApplicationPresentOfferToCandidateClosingPrompt = () => {
  const { setAlternativeView } = useContext(TaskModalContext);

  const onOpenClosingModalClick = event => {
    event.preventDefault();
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.CLOSING);
  };

  return (
    <p className="c-prompter__list-item-content--new">
      <Trans i18nKey="task.detailsView.applicationPresentOfferToCandidate.openClosingModalPrompt">
        1
        <a href="#" onClick={onOpenClosingModalClick}>
          2
        </a>
      </Trans>
    </p>
  );
};
