import { Trans, useTranslation } from 'react-i18next';
import type { FC } from 'react';
import React, { useContext } from 'react';
import { TaskDetails } from '../TaskDetails';
import { TaskModalContext } from '../../TaskModal';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import { TASK_PROMPT_TYPES } from '../../../constants/task';
import { useTaskCompleter } from '../../../hooks/use-task-completer';

export const TaskJobPrepareJobPost: FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'task.detailsView.jobPrepareJobPost',
  });

  const { jobLegacy, onModalClose } = useContext(TaskModalContext);
  const { trackCtaClick } = useTaskCompleter();

  const jobSettingsDashboardPageUrl = jobLegacy
    ? `/dashboard/recruitment/jobs/${jobLegacy.uuid}/edit`
    : '';

  const prompts = [
    {
      type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
      text: t('prompts.fillOutJobSettings'),
    },
    {
      type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
      text: t('prompts.mustHaveAndNiceToHave'),
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: (
        <Trans
          components={{ a: <a /> }}
          i18nKey="task.detailsView.jobPrepareJobPost.prompts.guidelines"
        />
      ),
    },
  ];

  const onCtaClick = () => {
    trackCtaClick();
    onModalClose();
  };

  return (
    <TaskDetails
      isPauseButtonHidden
      prompts={prompts}
      suggestion={t('suggestion')}
      title={t('title')}
    >
      <LinkWrapper
        external
        label={t('cta')}
        onClick={onCtaClick}
        target="_blank"
        type="primary"
        url={jobSettingsDashboardPageUrl}
      />
    </TaskDetails>
  );
};
