import React, { useContext } from 'react';
import { TaskModalContext } from './';
import { Action, Icon } from '@reverse-hr/pattern-library';
import { getProgressIcon } from '../../helpers/utils';
import { Parser } from 'html-to-react';
import { NotificationAlert } from '../NotificationAlert';
import { NOTIFICATION_ALERT_STATES } from '../../constants/notifications';

export const TaskModalHeader = () => {
  const {
    alertMessage,
    alternativeTitle,
    alternativeViewName,
    setAlertMessage,
    setAlternativeView,
    onModalClose,
    task,
  } = useContext(TaskModalContext);

  const taskTitle = htmlParser.parse(alternativeTitle || task.title);

  const onBackClick = () => setAlternativeView(null);
  const onNotificationAlertClose = () => setAlertMessage(null);

  return (
    <header className="c-task-modal-header">
      <div className="c-task-modal-header__caption">
        {!!alternativeViewName && (
          <Action
            modifier="c-task-modal-header__back"
            type="simple-text"
            size="small"
            icon="icn-back-24px"
            onClick={onBackClick}
          />
        )}

        <p className="c-task-modal-header__title">{taskTitle}</p>
      </div>

      <div className="c-task-modal-header__details">
        <p className="c-task-modal-header__phase">
          <Icon name={getProgressIcon(task.job_state_progress)} />
          {task.job_state_progress}
        </p>

        <div className="c-task-modal-header__info">
          <p className="c-task-modal-header__job-title">
            {!!task.magnet ? task.magnet.title : task.job.title}
          </p>

          <p className="c-task-modal-header__company">
            {task.customer.company_name}
          </p>
        </div>

        <Action
          iconOnly
          modifier="c-task-modal-header__close"
          icon="icn-close-big-24"
          size="small"
          type="raw"
          onClick={onModalClose}
        />
      </div>

      <NotificationAlert
        className="c-task-modal-header__alert"
        isVisible={!!alertMessage}
        onClose={onNotificationAlertClose}
        status={NOTIFICATION_ALERT_STATES.POSITIVE}
      >
        {alertMessage}
      </NotificationAlert>
    </header>
  );
};

const htmlParser = new Parser();
