import React from 'react';
import { Icon } from '@reverse-hr/pattern-library';
import Readmore from './Readmore';
import { useTranslation } from 'react-i18next';

const Suggestion = ({ modifier, suggestion }) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="suggestion-container"
      className={`c-suggestion ${modifier}`}
    >
      <div data-testid="suggestion-icon" className="c-suggestion__icon">
        <Icon name="icn-suggestion-24px" />
      </div>

      <p className="c-suggestion__title">{t('suggestion')}</p>

      <div data-testid="suggestion-content" className="c-suggestion__content">
        <Readmore modifier="c-readmore--two-lines" body={suggestion} />
      </div>
    </div>
  );
};

Suggestion.defaultProps = {
  modifier: '',
  suggestion: null,
};

export default Suggestion;
