import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchToggle } from '@reverse-hr/pattern-library';
import { OfferDetailsFormContext } from './OfferDetailsForm';

export const OfferDetailsFormSuccessFeeRecap = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.offerValues',
  });

  const { formData, onFormChange } = useContext(OfferDetailsFormContext);

  const onToggleChange = () => {
    onFormChange({
      isSuccessFeeRecapVisible: !formData.isSuccessFeeRecapVisible,
    });
  };

  return (
    <div className="c-offer-details-form__box">
      <SwitchToggle
        id="offer-details-offer-success-fee-recap"
        name="offer-details-offer-success-fee-recap"
        supportText={t('successFeeRecapToggleLabel')}
        onChange={onToggleChange}
        checked={formData.isSuccessFeeRecapVisible}
      />
    </div>
  );
};
