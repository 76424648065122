import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Activities from '../../components/Activities';
import { emptyActivities } from '../../helpers/default-values';

export const JobTabActivities = ({ handleEmailClick }) => {
  const fetchJobActivities = useStoreActions(
    actions => actions.job.fetchJobActivities,
  );

  const activities = useStoreState(state => state.job.jobActivities);
  const activitiesLoading = useStoreState(
    state => state.job.jobActivitiesLoading,
  );
  const activitiesError = useStoreState(state => state.job.jobActivitiesError);

  useEffect(() => {
    fetchJobActivities();
  }, [fetchJobActivities]);

  return (
    <Activities
      activities={activities}
      loading={activitiesLoading}
      error={activitiesError}
      empty={emptyActivities()}
      onEmailClick={handleEmailClick}
    />
  );
};
