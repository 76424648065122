import type { FC } from 'react';
import React from 'react';
import type { TJobLegacy } from '../../models/Job';
import { Action } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { EMPLOYER_VIEW_URL } from '../../constants/endpoints';

interface TShareEmployerViewCopyProps {
  dealId: TJobLegacy['deal_id'];
  onCopySuccess: () => void;
}

export const ShareEmployerViewCopy: FC<TShareEmployerViewCopyProps> = ({
  dealId,
  onCopySuccess,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'modals.shareEmployerView.copyLink',
  });

  const onCopyClick = () => {
    const inputElement = document.createElement('input');

    inputElement.value = `${EMPLOYER_VIEW_URL}/${dealId}`;
    inputElement.select();
    navigator.clipboard.writeText(inputElement.value);

    onCopySuccess();
  };

  return (
    <section className="c-share-employer-view__section">
      <div className="c-share-employer-view__section-intro">
        <h3 className="c-share-employer-view__section-title">{t('title')}</h3>

        <p className="c-share-employer-view__section-description">
          {t('description')}
        </p>
      </div>

      <div className="c-share-employer-view__copy-cta">
        <Action
          icon="icn-share-outline-24px"
          label={t('copyCta')}
          onClick={onCopyClick}
          type="primary"
        />
      </div>
    </section>
  );
};
