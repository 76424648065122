import { TaskModalContext } from '../../TaskModal';
import { apiTaskPauseUpdate, apiTaskUnpauseUpdate } from '../../../api/task';
import { useContext } from 'react';

export const useTaskPause = ({ setIsErrorAlertVisible }) => {
  const { onModalClose, onTaskUpdate, task } = useContext(TaskModalContext);

  const pauseButtonIcon = !!task.paused_at
    ? 'icn-resume-24px'
    : 'icn-pause-24px';

  const onPauseClick = async () => {
    setIsErrorAlertVisible(false);

    const requestMethod = !!task.paused_at
      ? apiTaskUnpauseUpdate
      : apiTaskPauseUpdate;

    try {
      const updatedTask = await requestMethod(task.id);

      onTaskUpdate(updatedTask);
      onModalClose();
    } catch (error) {
      setIsErrorAlertVisible(true);
    }
  };

  return { pauseButtonIcon, onPauseClick };
};
