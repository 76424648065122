import { action, thunk } from 'easy-peasy';
import isEqual from 'lodash.isequal';

export default {
  // state
  navigation: {
    items: {
      tasks: {
        type: 'task',
        label: 'Tasks',
        path: '/',
        modifier: 'main',
        has_updates: true,
      },
      jobs: {
        type: 'job',
        label: 'All jobs',
        path: '/jobs',
        modifier: 'job',
        subitems: [],
      },
    },
  },
  isLoading: true,
  isError: false,

  // actions
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setIsError: action((state, payload) => {
    state.isError = payload;
  }),
  setSubitems: action((state, payload) => {
    state.navigation.items[payload.item].subitems = payload.value;
  }),
  setHasUpdates: action((state, payload) => {
    state.navigation.items[payload.item].has_updates = payload.value;
  }),

  // thunks
  fetchJobs: thunk(
    async (actions, payload, { getStoreState, getState, injections }) => {
      const { user, forcedUserId } = getStoreState();
      const {
        navigation: {
          items: { jobs },
        },
      } = getState();
      const { navigationService } = injections;

      try {
        const fetchedJobs = await navigationService.getJobs(
          forcedUserId ? forcedUserId : user.user.id,
        );
        if (typeof fetchedJobs !== 'object') {
          throw new Error('Jobs is not an array', fetchedJobs);
        }
        fetchedJobs.map(job => {
          job.path = `/jobs/${job.id}`;
          return job;
        });
        if (!!fetchedJobs) {
          actions.setIsError(false);
          const shouldRerender = jobs.subitems.length
            ? !isEqual(fetchedJobs, jobs.subitems)
            : true;
          // console.log('Should rerender jobs: ', shouldRerender)
          if (shouldRerender) {
            actions.setSubitems({ item: 'jobs', value: fetchedJobs });
          }
        }
      } catch (error) {
        actions.setIsError(true);
      } finally {
        actions.setIsLoading(false);
      }
    },
  ),
};
