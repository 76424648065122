import React, { useContext, useEffect, useState } from 'react';
import {
  TaskModalBody,
  TaskModalContext,
  TaskModalFooter,
} from '../../TaskModal';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTER_BASENAME } from '../../../constants/app';
import { OfferDetailsForm } from '../../OfferDetailsForm';
import { ActionWithLoader } from '../../ActionWithLoader';
import { useOfferDetails } from './use-offer-details';
import { Alert } from '@reverse-hr/pattern-library';

export const TaskBingo = () => {
  const { t } = useTranslation();

  const { application, jobLegacy, setAlternativeTitle } =
    useContext(TaskModalContext);

  const [alertError, setAlertError] = useState(null);

  const {
    isOfferDetailsFormValid,
    onOfferDetailsFormChange,
    onOfferDetailsFormSubmit,
  } = useOfferDetails(application, jobLegacy, setAlertError);

  const onAlertErrorClose = () => setAlertError(null);

  useEffect(() => {
    setAlternativeTitle(t('modals.bingo.title'));
  }, []);

  return (
    <>
      <TaskModalBody className="c-task-bingo">
        {!!alertError && (
          <Alert
            type="warning"
            icon="icn-warning-outline-24"
            modifier="c-alert--fixed"
            onClose={onAlertErrorClose}
          >
            {alertError}
          </Alert>
        )}

        <h2 className="c-task-bingo__title">
          {t('modals.bingo.title')}

          <img
            className="c-task-bingo__image"
            src={`${ROUTER_BASENAME}/assets/images/closing/bingo.png`}
            alt={t('modals.bingo.title')}
          />
        </h2>

        <div className="c-task-bingo__content">
          <p className="c-task-bingo__subtitle">
            <Trans i18nKey="modals.bingo.subtitle" />
          </p>

          <OfferDetailsForm
            offer={application.offer}
            job={jobLegacy}
            onChange={onOfferDetailsFormChange}
          />
        </div>
      </TaskModalBody>

      <TaskModalFooter>
        <ActionWithLoader
          label={t('modals.bingo.cta')}
          onClick={onOfferDetailsFormSubmit}
          disabled={!isOfferDetailsFormValid}
        />
      </TaskModalFooter>
    </>
  );
};
