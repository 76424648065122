import React, { useState, useEffect } from 'react';
import { differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../helpers/utils';

import Activity from './Activity';

const Log = ({ modifier, date, activities, onEmailClick }) => {
  const { t } = useTranslation();
  const [distanceInDays, setDistanceInDays] = useState(0);
  const [isCurrentYear, setIsCurrentYear] = useState(true);

  useEffect(() => {
    const distance = differenceInDays(new Date(), new Date(date));
    const isCurrentYear =
      new Date().getFullYear() === new Date(date).getFullYear();
    setDistanceInDays(distance);
    setIsCurrentYear(isCurrentYear);
  }, [date]);

  return (
    <div className={`c-log ${modifier}`}>
      {distanceInDays > 6 ? (
        <div className="c-log__date">
          <p className="day">{formatDate(new Date(date), 'dd')}</p>
          <p className="month">{formatDate(new Date(date), 'MMM')}</p>
          {!isCurrentYear ? (
            <p className="month">{new Date(date).getFullYear()}</p>
          ) : null}
        </div>
      ) : (
        <p className="c-log__title">
          {distanceInDays === 1
            ? t('activity.yesterday')
            : distanceInDays < 1
            ? t('activity.today')
            : t(`activity.days`, { days: distanceInDays })}
        </p>
      )}

      <div className="c-log__list">
        {activities.map((activity, index) => {
          return (
            <div key={`${activity.type}-${index}`} className="c-log__list-item">
              <Activity onEmailClick={onEmailClick} {...activity} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

Log.defaultProps = {
  onEmailClick: () => {},
};

export default Log;
