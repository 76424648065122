import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@sentry/react';
import { StoreProvider, createStore } from 'easy-peasy';
import './assets/stylesheets/main.css';
import {
  getJobs,
  getTasks,
  getUser,
  updateUser,
  getAllJobs,
  getJob,
  getJobActivities,
  getCustomerViewShortLink,
  getJobHorserace,
  getJobEmails,
  getTask,
  getManagers,
  getTeams,
  updateSingleTask,
  updateJob,
  sendEmail,
  createNote,
  updateNote,
  deleteNote,
} from './helpers';
import model from './model';
import './i18n';
import GTM from './gtm';
import Hotjar from './hotjar';
import Sentry from './sentry';
import { pdfjs } from 'react-pdf';

// import * as serviceWorker from './serviceWorker'
import App from './App';
import ErrorComponent from './components/Error';

pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

const store = createStore(model, {
  injections: {
    userService: { getUser, updateUser },
    tasksService: { getTasks },
    taskService: { getTask, updateSingleTask, sendEmail },
    navigationService: { getJobs },
    notesService: { createNote, updateNote, deleteNote },
    jobsService: {
      getAllJobs,
      getJob,
      getJobActivities,
      getJobHorserace,
      getJobEmails,
      updateJob,
      getManagers,
      getTeams,
      getCustomerViewShortLink,
    },
  },
});

GTM.initialize();
Sentry.initialize();
setTimeout(() => {
  Hotjar.initialize();
}, 3000);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ErrorBoundary fallback={ErrorComponent}>
        <App />
      </ErrorBoundary>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
