import { apiApplicationFetch } from '../../api/applications';
import { useEffect, useState } from 'react';

export const useFetchApplication = ({ task }) => {
  const [application, setApplication] = useState(null);
  const [isApplicationLoading, setIsApplicationLoading] = useState(true);

  useEffect(() => {
    const fetchApplication = async () => {
      if (!task) {
        return;
      }

      if (!task.application) {
        return setIsApplicationLoading(false);
      }

      try {
        const fetchedApplication = await apiApplicationFetch({
          applicationId: task.application.uuid,
        });

        setApplication(fetchedApplication);
      } finally {
        setIsApplicationLoading(false);
      }
    };

    fetchApplication();
  }, [task]);

  return { application, isApplicationLoading };
};
