import { Action, FormField } from '@reverse-hr/pattern-library';
import React, { useState } from 'react';
import { Loader } from '../Loader';
import { useTranslation } from 'react-i18next';
import { AddNotePicture } from '../AddNote/AddNotePicture';

export const TaskNoteForm = ({
  initialTitle = '',
  initialBody = '',
  handleSave,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [body, setBody] = useState(initialBody);

  const isEditingMode = !!initialTitle || !!initialBody;
  const saveButtonType = isEditingMode ? 'secondary' : 'outline';
  const isSaveButtonDisabled = !body || !body.trim();

  const onTitleChange = event => setTitle(event.value);
  const onBodyChange = event => setBody(event.value);

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await handleSave({ title, body });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="c-task-note-form">
      <div className="c-task-note-form__body">
        {isEditingMode ? null : (
          <AddNotePicture className="c-task-note-form__background" />
        )}

        <div className="c-task-note-form__field">
          <FormField
            id="task-note-title"
            type="input"
            onChange={onTitleChange}
            label={t('note.title')}
            hint={t('note.title-note')}
            inputProps={{
              autoFocus: true,
              value: title,
            }}
          />
        </div>

        <div data-testid="textarea-input" className="c-task-note-form__field">
          <FormField
            id="task-note-body"
            type="textarea"
            onChange={onBodyChange}
            placeholder={t('note.placeholder')}
            inputProps={{
              rows: 4,
              value: body,
            }}
          />
        </div>
      </div>

      <div data-test="note-footer" className="c-task-note-form__footer">
        {isLoading ? (
          <Loader text={t('sending')} />
        ) : (
          <Action
            buttonType="submit"
            type={saveButtonType}
            disabled={isSaveButtonDisabled || isLoading}
            onClick={onSubmit}
            label={t('note.save')}
            modifier="c-task-note-form__save"
          />
        )}
      </div>
    </div>
  );
};
