const stepsOrder = [
  'profiling',
  'hunting',
  'presenting',
  'scout_interviewing',
  'client_interviewing',
  'dealing',
  'onboarding',
];

export const strToPascalCase = str =>
  str.trim().replace(' ', '_').toLowerCase();

export const getHorserace = (applications, phases) => {
  const horserace = [];

  if (!applications || !applications.length || !phases || !phases.length) {
    return horserace;
  }

  // get most advanced application step by looping through applications
  applications.forEach((application, index) => {
    // HORSERACE
    const { candidate, tasks, ...appl } = application;
    let active_step = 0;
    const mappedPhases = phases
      .filter(phase => strToPascalCase(phase.name) !== 'profiling')
      .map((phase, pIndex) => {
        const phaseTasks = tasks
          ? tasks.filter(
              t => strToPascalCase(t.phase) === strToPascalCase(phase.name),
            )
          : [];
        const events = new Array(phase.steps.total).fill({ completed: null });
        phaseTasks.forEach((task, tIndex) => {
          const { step, date, description } = task;
          events[step - 1] = {
            completed: {
              date,
              description,
            },
          };
        });
        if (events.some(event => !!event.completed) && pIndex > active_step) {
          active_step = pIndex;
        }
        return {
          name: phase.name,
          events,
        };
      });
    const mappedApplication = {
      ...appl,
      candidate,
      active_step,
      phases: mappedPhases,
    };
    horserace.push(mappedApplication);
  });

  const _horserace = horserace.sort(
    (a, b) => b.most_advanced_index - a.most_advanced_index,
  );

  return {
    horserace: _horserace,
  };
};

export const getJobPhases = phases => {
  const mappedPhases = phases.map(phase => {
    return {
      ...phase,
      steps: phase.steps.total,
      current_step: phase.steps.completed,
    };
  });

  return {
    phases: mappedPhases,
  };
};
