import { apiTaskFetch } from '../../api/task';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useFetchTask = ({ onModalClose, taskId }) => {
  const [isTaskLoading, setIsTaskLoading] = useState(true);
  const [task, setTask] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchTask = async () => {
      try {
        history.push({ search: `?task=${taskId}` });

        const fetchedTask = await apiTaskFetch(taskId);

        setTask(fetchedTask);
        setIsTaskLoading(false);
      } catch (error) {
        onModalClose();
      }
    };

    if (taskId) {
      fetchTask();
    }
  }, [taskId]);

  return { isTaskLoading, setTask, task };
};
