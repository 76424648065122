import { LinkWrapper } from '@reverse-hr/pattern-library';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentJobContext } from '../Providers/CurrentJobProvider';
import { JraSlidesGenerationModal } from '../JraSlidesGenerationModal';
import Modal from '../Modal';

export const JobHeaderJraGeneration: FC = () => {
  const { t } = useTranslation();
  const { currentJob } = useContext(CurrentJobContext);
  const [isGenerateJraModalOpen, setIsGenerateJraModalOpen] = useState(false);

  const presentationMeetingSlidesGeneratedAt =
    !!currentJob && !!currentJob.presentationMeetingSlidesGeneratedAt
      ? currentJob.presentationMeetingSlidesGeneratedAt
      : '';

  const presentationMeetingSlidesUrl =
    !!currentJob && !!currentJob.presentationMeetingSlidesUrl
      ? currentJob.presentationMeetingSlidesUrl
      : '';

  const isJraGenerated =
    !!presentationMeetingSlidesGeneratedAt && !!presentationMeetingSlidesUrl;

  const onGenerateJraModalOpen = () => {
    setIsGenerateJraModalOpen(true);
  };

  const onGenerateJraModalClose = () => {
    setIsGenerateJraModalOpen(false);
  };

  return (
    <div className="c-job-header__summary c-job-header__summary--jra">
      <p className="c-job-header__summary-item">
        <strong>{t('job.header.jra.title')}</strong>
      </p>

      <p className="c-job-header__summary-item">
        {isJraGenerated ? (
          <LinkWrapper
            modifier="u-underline"
            target="_blank"
            type="simple-text"
            external
            url={presentationMeetingSlidesUrl}
          >
            {t('job.header.jra.generatedOn', {
              date: new Date(presentationMeetingSlidesGeneratedAt),
            })}
          </LinkWrapper>
        ) : (
          t('job.header.jra.generatedOn', { context: 'notGenerated' })
        )}
        &nbsp;-&nbsp;
        <LinkWrapper
          modifier="u-underline"
          type="simple-text"
          onClick={onGenerateJraModalOpen}
        >
          {t('job.header.jra.generateCta', {
            context: isJraGenerated ? null : 'notGenerated',
          })}
        </LinkWrapper>
      </p>

      <Modal
        isOverlayButtonVisible={false}
        modifier="c-modal--auto-height c-modal--jra-slides-generation-modal"
        visible={isGenerateJraModalOpen}
      >
        <JraSlidesGenerationModal
          onClose={onGenerateJraModalClose}
          presentationMeetingSlidesGeneratedAt={
            presentationMeetingSlidesGeneratedAt || ''
          }
        />
      </Modal>
    </div>
  );
};
