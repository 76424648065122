import type { FC } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TaskDetails } from '../TaskDetails';
import { Action } from '@reverse-hr/pattern-library';
import { TaskModalContext } from '../../TaskModal';
import { differenceInDays } from 'date-fns';
import {
  TASK_MODAL_ALTERNATIVE_VIEWS,
  TASK_PROMPT_TYPES,
} from '../../../constants/task';
import { ExternalLink } from '../../ExternalLink';

export const TaskJobPrepareForPresentationMeeting: FC = () => {
  const { t } = useTranslation('');
  const { job, task } = useContext(TaskModalContext);

  const daysUntilPresentationMeeting = job?.presentationMeetingScheduledAt
    ?.localDateTime
    ? differenceInDays(
        new Date(job.presentationMeetingScheduledAt.localDateTime),
        new Date(),
      )
    : 0;

  const salespersonFullName =
    job && job.salesperson
      ? t('common.formatting.fullName', job.salesperson)
      : '';

  const prompts = [
    {
      type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
      text: t(
        'task.detailsView.jobPrepareForPresentationMeeting.prompts.bestCandidates',
      ),
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskJobPrepareForPresentationMeetingCandidatesInformationPrompt />,
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: (
        <Trans i18nKey="task.detailsView.jobPrepareForPresentationMeeting.prompts.useShowcase" />
      ),
    },
    {
      type: TASK_PROMPT_TYPES.SIMPLE_TEXT,
      text: t(
        'task.detailsView.jobPrepareForPresentationMeeting.prompts.shareWithSales',
        { salesperson: salespersonFullName },
      ),
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: <TaskJobPrepareForPresentationMeetingGenerateJraPrompt />,
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: (
        <Trans i18nKey="task.detailsView.jobPrepareForPresentationMeeting.prompts.notifyTheClient" />
      ),
    },
    {
      type: TASK_PROMPT_TYPES.CUSTOM_CONTENT,
      text: (
        <Trans i18nKey="task.detailsView.jobPrepareForPresentationMeeting.prompts.weeklyMeetings" />
      ),
    },
  ];

  return task ? (
    <TaskDetails
      title={t('task.detailsView.jobPrepareForPresentationMeeting.title', {
        days: daysUntilPresentationMeeting,
      })}
      prompts={prompts}
      suggestion={t(
        'task.detailsView.jobPrepareForPresentationMeeting.suggestion',
      )}
    >
      <Action
        disabled
        label={t('task.detailsView.jobPrepareForPresentationMeeting.cta')}
      />
    </TaskDetails>
  ) : null;
};

const TaskJobPrepareForPresentationMeetingCandidatesInformationPrompt: FC =
  () => {
    const { job, task } = useContext(TaskModalContext);

    const houndApplicationPage =
      job && task
        ? `https://app.reverse.hr/hound/profiles/potential_matches/?jobId=${job.id}&tab=feedback`
        : '';

    return (
      <Trans i18nKey="task.detailsView.jobPrepareForPresentationMeeting.prompts.candidatesInformation">
        0<ExternalLink href={houndApplicationPage}>1</ExternalLink>
      </Trans>
    );
  };

const TaskJobPrepareForPresentationMeetingGenerateJraPrompt: FC = () => {
  const { job, setAlternativeView } = useContext(TaskModalContext);

  const isJraGenerated =
    !!job &&
    !!job.presentationMeetingSlidesGeneratedAt &&
    !!job.presentationMeetingSlidesUrl;

  const jraGeneratedAt =
    job && job.presentationMeetingSlidesGeneratedAt
      ? new Date(job.presentationMeetingSlidesGeneratedAt)
      : null;

  const jraSlidesUrl =
    job && job.presentationMeetingSlidesUrl
      ? job.presentationMeetingSlidesUrl
      : '';

  const onGenerateJraSlides = () =>
    setAlternativeView(TASK_MODAL_ALTERNATIVE_VIEWS.GENERATE_JRA_SLIDES);

  return isJraGenerated ? (
    <Trans
      i18nKey="task.detailsView.jobPrepareForPresentationMeeting.prompts.generateJraSlides"
      values={{
        date: jraGeneratedAt,
      }}
    >
      0<a href={jraSlidesUrl}>1</a>2
      <a
        className="u-underline"
        tabIndex={0}
        role="button"
        onClick={onGenerateJraSlides}
      >
        3
      </a>
    </Trans>
  ) : (
    <Trans i18nKey="task.detailsView.jobPrepareForPresentationMeeting.prompts.generateJraSlides_notGenerated">
      0
      <a
        className="u-underline"
        tabIndex={0}
        role="button"
        onClick={onGenerateJraSlides}
      >
        1
      </a>
    </Trans>
  );
};
