import React, { useState, useRef, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from 'react-router-dom';
import useInterval from '../helpers/hooks/useInterval';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import SplashPage from '../pages/SplashPage';

const MainLayout = ({ modifier, children }) => {
  const [navigationLoading, setNavigationLoading] = useState(true);
  const sidebarRef = useRef(null);
  const location = useLocation();

  // STATE
  const navigation = useStoreState(state => state.navigation.navigation);
  const isJobsListLoading = useStoreState(state => state.navigation.isLoading);
  const user = useStoreState(state => state.user.user);
  const jobsError = useStoreState(state => state.navigation.isError);
  const jobsLoading = useStoreState(state => state.navigation.isLoading);
  const isSplashVisible = useStoreState(state => state.isSplashVisible);
  const firebaseInitialized = useStoreState(state => state.firebaseInitialized);

  // ACTIONS
  const updateFirebaseNotifications = useStoreActions(
    actions => actions.updateFirebaseNotifications,
  );

  useEffect(() => {
    let mounted = true;
    if (sessionStorage.getItem('scroll-navigation')) {
      setNavigationLoading(true);
      setTimeout(() => {
        if (mounted) {
          setNavigationLoading(false);
          setTimeout(() => {
            if (sidebarRef.current) {
              sidebarRef.current.scrollTop =
                sessionStorage.getItem('scroll-navigation');
            }
          });
        }
      }, 200);
    } else {
      setNavigationLoading(false);
    }
    return () => {
      if (sidebarRef.current) {
        sessionStorage.setItem(
          'scroll-navigation',
          sidebarRef.current.scrollTop,
        );
      }

      mounted = false;
    };
  }, [location]);

  useInterval(() => {
    if (firebaseInitialized) {
      updateFirebaseNotifications();
    }
  }, 2000);

  return (
    <div className={'o-layout'}>
      <div
        ref={sidebarRef}
        className={`o-layout__sidebar ${navigationLoading ? 'loading' : ''}`}
      >
        <Sidebar
          navigation={navigation}
          loading={jobsLoading}
          error={jobsError}
        />
      </div>
      <div className={'o-layout__core'} data-test="main-layout">
        <Header
          navigation={navigation}
          user={user}
          loading={jobsLoading}
          error={jobsError}
        />
        {children}
      </div>
      <SplashPage visible={isSplashVisible || isJobsListLoading} />
    </div>
  );
};

MainLayout.defaultProps = {
  modifier: '',
};

export default MainLayout;
