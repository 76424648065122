import { Label, PdfFileField } from '@reverse-hr/pattern-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OfferDetailsFormDocument = ({ id, letter, label, onChange }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.dateAndDocuments',
  });

  const [letterName, setLetterName] = useState();
  const [letterFile, setLetterFile] = useState();

  const token = localStorage.getItem('token');

  const onLetterChange = async uploadedFile => {
    const convertedUploadedFile = await getFileAsBase64(uploadedFile);
    const blobData = new Blob([uploadedFile], { type: uploadedFile.type });

    setLetterName(uploadedFile.name);
    setLetterFile(URL.createObjectURL(blobData));
    onChange(convertedUploadedFile);
  };

  useEffect(() => {
    if (!!letter && !!letter.name) {
      setLetterName(letter.name);

      setLetterFile({
        url: letter.downloadUrl,
        httpHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, [letter]);

  return (
    <div className="c-offer-details-form__field">
      <Label id={id} modifier="c-offer-details-form__label" label={label} />

      <PdfFileField
        downloadCtaText={t('downloadCta')}
        displayedFileName={letterName}
        downloadFileName={letterName}
        id={id}
        name={name}
        onChange={onLetterChange}
        previewModalTitle={label}
        replaceCtaLabel={t('replace')}
        uploadCtaLabel={t('upload')}
        file={letterFile}
        isPreviewEnabled
        isDownloadEnabled
      />
    </div>
  );
};

const getFileAsBase64 = uploadedFile => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onloadend = function onLoadEnd() {
      const content = this.result.split(',', 2)[1];
      resolve(content);
    };

    reader.readAsDataURL(uploadedFile);
  });
};
