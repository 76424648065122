import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useStoreActions } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import { error } from '../helpers/default-values';
import Notice from '../components/Notice';

const Modal = ({ visible, modifier, isOverlayButtonVisible, children }) => {
  const setModalOpened = useStoreActions(actions => actions.setModalOpened);
  const { t } = useTranslation();

  const ModalWrapper = ({ children }) => {
    const handleOutsideClick = e => {
      const arr = Array.from(e.target.classList);
      if (
        arr.includes('c-modal__overlay') ||
        arr.includes('o-thread--emails')
      ) {
        setModalOpened(false);
        if (childRef && childRef.current && childRef.current.cleanModal) {
          childRef.current.cleanModal();
        }
      }
    };
    const handleCloseClick = () => {
      setModalOpened(false);
      if (childRef && childRef.current && childRef.current.cleanModal) {
        childRef.current.cleanModal();
      }
    };
    // console.log('RENDER - ModalWrapper', visible)
    const childRef = useRef(null);
    const childrenWithProps = React.Children.map(children, child => {
      return React.cloneElement(child, { ref: childRef });
    });
    return visible ? (
      <div aria-hidden data-testid="modal" className={`c-modal ${modifier}`}>
        <div
          onClick={handleOutsideClick}
          tabIndex="-1"
          data-testid="modal-overlay"
          className="c-modal__overlay"
        >
          {isOverlayButtonVisible ? (
            <button onClick={handleCloseClick} className="c-modal__close">
              {t('close')}
            </button>
          ) : null}
          <div
            role="dialog"
            aria-modal
            data-testid="modal-container"
            className={`c-modal__container`}
          >
            <ErrorBoundary
              beforeCapture={scope =>
                scope.setTag('context', 'TaskDetailModal')
              }
              fallback={() => (
                <div className="c-notice--error-modal--wrapper">
                  <Notice
                    type="error-general"
                    title={error().title}
                    text={error().text}
                  />
                </div>
              )}
            >
              {childrenWithProps}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    ) : null;
  };

  const elRef = useRef(null);
  if (!elRef.current) {
    const div = document.createElement('div');
    div.dataset.testid = 'modal-root';
    elRef.current = div;
  }

  useEffect(() => {
    let modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.setAttribute('id', 'modal-root');
      document.body.appendChild(modalRoot);
    }
    modalRoot.appendChild(elRef.current);

    return () => modalRoot.removeChild(elRef.current);
  }, []);

  return createPortal(<ModalWrapper>{children}</ModalWrapper>, elRef.current);
};

Modal.defaultProps = {
  modifier: '',
  isOverlayButtonVisible: false,
};

const MemoizedModal = React.memo(Modal, (props, nextProps) => {
  if (props.visible === nextProps.visible) {
    return true;
  }
});

export default MemoizedModal;
