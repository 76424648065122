import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { FormField } from '@reverse-hr/pattern-library';
import { TUserLocale, USER_LOCALE_LIST } from '../../definitions/user';
import { useFormValidation } from './use-form-validation';
import { ActionWithLoader } from '../ActionWithLoader';
import { apiJobSharePost } from '../../api/share';
import type { TShare, TShareRequest } from '../../models/Share';
import type { TFormData } from './ShareEmployerView';
import type { TJobLegacy } from '../../models/Job';

interface TOnRecipientAddedPayload {
  addedRecipient: string;
  updatedRecipients: TShare[];
}

export interface TShareEmployerViewFormProps {
  jobUuid: TJobLegacy['uuid'];
  onError: (message: string) => void;
  onRecipientAdded: (payload: TOnRecipientAddedPayload) => void;
}

export const ShareEmployerViewForm: FC<TShareEmployerViewFormProps> = ({
  jobUuid,
  onError,
  onRecipientAdded,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'modals.shareEmployerView.form',
  });

  const { t: commonT } = useTranslation();

  const { formData, formErrors, isFormValid, onFieldChange, resetFormData } =
    useFormValidation();

  const languageSelectOptions = USER_LOCALE_LIST.map(language => ({
    label: commonT(`common.languages.${language}`),
    value: language,
  }));

  const onSubmit = async () => {
    if (!isValidShareDetails(formData)) {
      return;
    }

    try {
      const response = await apiJobSharePost({
        jobId: jobUuid,
        shareDetails: formData,
      });

      if (!response || !response.shares) {
        return;
      }

      onRecipientAdded({
        addedRecipient: formData.email,
        updatedRecipients: response.shares,
      });

      resetFormData();
    } catch (error) {
      onError(t('submitError'));
    }
  };

  return (
    <section className="c-share-employer-view__section">
      <div className="c-share-employer-view__section-intro">
        <h3 className="c-share-employer-view__section-title">{t('title')}</h3>

        <p className="c-share-employer-view__section-description">
          {t('description')}
        </p>
      </div>

      <form className="c-share-employer-view-form">
        <FormField
          externalError={formErrors.firstName}
          id="firstName"
          inputProps={{
            value: formData.firstName,
          }}
          label={t('firstNameLabel')}
          modifier="c-share-employer-view-form__field"
          onChange={onFieldChange}
          placeholder={t('firstNamePlaceholder')}
          required
          type="input"
        />

        <FormField
          externalError={formErrors.lastName}
          id="lastName"
          inputProps={{
            value: formData.lastName,
          }}
          label={t('lastNameLabel')}
          modifier="c-share-employer-view-form__field"
          onChange={onFieldChange}
          placeholder={t('lastNamePlaceholder')}
          required
          type="input"
        />

        <FormField
          externalError={formErrors.email}
          id="email"
          inputProps={{
            value: formData.email,
          }}
          label={t('emailLabel')}
          modifier="c-share-employer-view-form__field"
          onChange={onFieldChange}
          placeholder={t('emailPlaceholder')}
          required
          type="input"
        />

        <FormField
          id="language"
          inputProps={{
            value: formData.language,
            options: languageSelectOptions,
            placeholder: t('languagePlaceholder'),
          }}
          label={t('languageLabel')}
          modifier="c-share-employer-view-form__field"
          onChange={onFieldChange}
          options={languageSelectOptions}
          placeholder={t('languagePlaceholder')}
          required
          type="select"
        />
      </form>

      <footer className="c-share-employer-view-form__footer">
        <ActionWithLoader
          className="c-share-employer-view-form__submit"
          disabled={!isFormValid}
          icon="icn-mail-24px"
          label={t('cta')}
          onClick={onSubmit}
          supportIcon="icn-chevron-right-24px"
        />
      </footer>
    </section>
  );
};

const isValidShareDetails = (formData: TFormData): formData is TShareRequest =>
  'language' in formData &&
  USER_LOCALE_LIST.includes(formData.language as TUserLocale);
