import React, { useEffect, useState } from 'react';
import { ClientInterviewForm } from '../ClientInterviewForm';
import {
  apiClientInterviewDelete,
  apiClientInterviewPost,
  apiClientInterviewUpdate,
} from '../../api/client-interviews';
import { Action, Alert } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { USER_LOCALES } from '../../constants/user';
import { AppModal } from '../AppModal';
import { useHandleError } from '../../helpers/hooks/use-handle-error';
import { ActionWithLoader } from '../ActionWithLoader';
import { useStoreState } from 'easy-peasy';
import { selectorJobState } from '../../selectors/job/selectors';
import { useFetchClientInterviewFormData } from '../../hooks/use-fetch-client-interview-form-data';

export const ClientInterviewEditModal = ({
  applicationId,
  interviewId,
  candidateFullName,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState(null);

  const { formInitialData } = useFetchClientInterviewFormData({
    applicationId,
    interviewId,
  });

  const {
    job: { customer: jobCustomer, customer_guests: jobCustomerGuests },
  } = useStoreState(selectorJobState);

  const { handledError, setHandledError, handleError } = useHandleError({
    i18nKeyPrefix: 'clientInterviews',
    genericError: t('clientInterviews.errors.generic'),
  });

  const onFormChange = ({ formData, isFormValid }) => {
    setIsFormValid(isFormValid);
    setFormData(formData);
  };

  const onSubmit = async () => {
    try {
      if (formData.interviewType !== formInitialData.interviewType) {
        await apiClientInterviewDelete({ applicationId, interviewId });
        await apiClientInterviewPost({ applicationId, formData });
      } else {
        await apiClientInterviewUpdate({
          applicationId,
          interviewId,
          formData,
        });
      }

      onUpdate();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => setFormData(formInitialData), [formInitialData]);

  return (
    <AppModal
      isVisible={!!formInitialData}
      className="c-client-interview-edit-modal"
    >
      <div className="c-modal__wrapper">
        <header className="c-modal__header c-client-interview-edit-modal__header">
          <Action
            iconOnly
            modifier="c-modal__close"
            icon="icn-close-big-24"
            size="small"
            type="simple-text"
            onClick={onClose}
          />
        </header>

        <div className="c-modal__content">
          <ClientInterviewForm
            onChange={onFormChange}
            initialData={formInitialData}
            candidateFullName={candidateFullName}
            jobCustomer={jobCustomer}
            jobCustomerGuests={jobCustomerGuests}
          />
        </div>

        <footer className="c-modal__footer">
          <div className="c-modal__other-actions"></div>
          <div className="c-modal__footer-cta">
            <ActionWithLoader
              label={t('clientInterviews.form.footerCta', { context: 'edit' })}
              disabled={!isFormValid}
              onClick={onSubmit}
            />
          </div>
        </footer>

        {!!handledError && (
          <Alert
            modifier="c-alert--fixed"
            closable
            icon="icn-warning-outline-24"
            type="negative"
            onClose={() => setHandledError(null)}
          >
            {handledError}
          </Alert>
        )}
      </div>
    </AppModal>
  );
};

export const getLocationAddress = async location => {
  if (!location) {
    return null;
  }

  try {
    return await fetchLocationAddress(location);
  } catch (_error) {
    const locationTranslation = location.translations.find(
      translation => translation.language === USER_LOCALES.EN,
    );

    return locationTranslation.address;
  }
};

export const fetchLocationAddress = async location =>
  new Promise(async (resolve, reject) => {
    const { google } = window;

    if (!google) {
      return reject('Google Maps not initialized');
    }

    const placeService = new google.maps.places.PlacesService(
      document.createElement('div'),
    );

    await placeService.getDetails({ placeId: location.googleMapsId }, place =>
      !!place ? resolve(place.formatted_address) : reject(),
    );
  });
