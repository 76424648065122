import React from 'react';

const Tabs = ({ tabs, activeTab, modifier, onTabClick }) => {
  /**
   * {
   *  name: 'task',
   *  text: 'Tasks'
   * }
   */

  const handleTabClick = tab => {
    onTabClick(tab.name);
  };

  return (
    <div className={`c-tabs ${modifier}`}>
      <ul className="c-tabs__list">
        {tabs.map((tab, i) => {
          return (
            <li
              key={i}
              onClick={() => handleTabClick(tab)}
              className="c-tabs__label"
            >
              <span
                className={`c-tabs__anchor ${
                  activeTab === tab.name ? 'c-tabs__anchor--active' : ''
                }`}
              >
                {tab.text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Tabs.defaultProps = {
  activeTab: false,
  modifier: '',
  onTabClick: () => {},
};

export default Tabs;
